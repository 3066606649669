import React, { Fragment } from "react";
import { Button, Col, Input, Label, Row } from "reactstrap";

const UserListFilterRadio = ({
  filters,
  onClick,
  setFilters,
  fetchRestaurantUsers,
  onClickFilter,
  setSidebaron,
}) => {
  const categories = [
    {
      key: "is_staff",
      title: "Сотрудник",
    },
  ];

  const clickCategory = (result, event) => {
    var elems = document.querySelectorAll("div.checkbox-animated");
    [].forEach.call(elems, function (el) {
      el.classList.remove("active");
    });
    event.target.classList.add("active");
    setFilters((prev) => ({ ...prev, is_staff: true }));
  };

  const onRemoveCategory = () => {
    var elems = document.querySelectorAll(".form-check-input");
    [].forEach.call(elems, function (el) {
      el.classList.remove("active");
      el.checked = false;
    });
  };

  if (!categories) return null;

  return (
    <Fragment>
      <div className="product-filter">
        <div className="checkbox-animated mt-0 mb-0">
          {categories.map((item, i) => {
            return (
              <Label className="d-block" key={i}>
                <Input
                  className="radio_animated"
                  type="radio"
                  name={item.key}
                  value={true}
                  onClick={(e) => clickCategory(item, e)}
                />
                {item.title}
              </Label>
            );
          })}
        </div>
      </div>
      <Row className={"justify-content-end mt-5"}>
        {Object.keys(filters).length === 0 ? null : (
          <Col xs={12}>
            <Button
              className={"w-100"}
              outline
              color={"info"}
              onClick={onClick}
            >
              Отфильтровать
            </Button>
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={12} className={"mt-2"}>
          <Button
            outline
            color={"danger"}
            className={"w-100"}
            onClick={() => {
              setFilters({});
              setSidebaron(true);
              onClickFilter();
              fetchRestaurantUsers(1, {});
              onRemoveCategory();
            }}
          >
            Сбросить
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default UserListFilterRadio;
