import React, { Fragment, useEffect, useState } from "react";
import { Btn, H4, H6, Image, P } from "../../../../AbstractElements";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Link, useLocation } from "react-router-dom";

import logoWhite from "../../../../assets/images/logo/logo.png";
import logoDark from "../../../../assets/images/logo/logo_dark.png";
import { useUserSelector } from "../../../../store/useSelectors";
import { useUserActions } from "../../../../store/useActions";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { coreUserLogin } from "../../../../api/core/users";
import telegramIconSrc from "../../../../assets/images/telegram-icon.png";

const CAFEBOTUM_BOT_LINK = "https://t.me/cafebotum_bot";

const LoginForm = ({ logoClassMain }) => {
  const [togglePassword, setTogglePassword] = useState(false);
  const { setIsAuthenticated, setToken } = useUserActions();
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    // Костыль, который отключает тёмную тему
    document.querySelector("body").classList.remove("dark-only");
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    coreUserLogin({
      phoneNumber: event.target.phone.value,
      password: event.target.password.value,
    }).then((response) => {
      if (response.status !== 200) {
        toast("Неверный логин или пароль");
      }

      localStorage.setItem("token", response.data.key);
      setIsAuthenticated(true);
      setToken(response.data.key);
      redirectOnLogin();
    });
  };

  const redirectOnLogin = () => {
    const queryParameters = new URLSearchParams(search);
    const path = queryParameters.get("next")
      ? queryParameters.get("next")
      : "/";
    navigate(`${process.env.PUBLIC_URL}${path}`);
  };

  return (
    <Fragment>
      <div className="login-card">
        <div>
          <div>
            <Link
              className={`logo ${logoClassMain ? logoClassMain : ""}`}
              to={process.env.PUBLIC_URL}
            >
              <Image
                attrImage={{
                  className: "img-fluid for-light",
                  src: logoWhite,
                  alt: "looginpage",
                  style: {
                    maxWidth: "220px",
                  },
                }}
              />
              <Image
                attrImage={{
                  className: "img-fluid for-dark",
                  src: logoDark,
                  alt: "looginpage",
                  style: {
                    maxWidth: "220px",
                  },
                }}
              />
            </Link>
          </div>

          <div className="login-main">
            <Form className="theme-form login-form" onSubmit={handleSubmit}>
              <H4>Вход</H4>
              <P>Введите ваш номер телефона и пароль</P>
              <FormGroup>
                <Label className="col-form-label m-0">Номер телефона</Label>
                <Input
                  name="phone"
                  className="form-control"
                  type="tel"
                  required
                  placeholder="+7 921 999 12 22"
                />
              </FormGroup>
              <FormGroup className="position-relative">
                <Label className="col-form-label m-0">Пароль</Label>
                <div className="position-relative">
                  <Input
                    name="password"
                    className="form-control"
                    type={togglePassword ? "text" : "password"}
                    required
                    placeholder="*********"
                  />
                  <div
                    className="show-hide"
                    onClick={() => setTogglePassword(!togglePassword)}
                  >
                    <span className={togglePassword ? "" : "show"}>
                      {togglePassword ? "скрыть" : "показать"}
                    </span>
                  </div>
                </div>
              </FormGroup>
              <a
                className="link"
                href={CAFEBOTUM_BOT_LINK + "?start=forgot_password"}
              >
                Забыли пароль?
              </a>

              <FormGroup>
                <Btn
                  attrBtn={{
                    className: "d-block w-100 mt-2",
                    color: "primary",
                    type: "submit",
                  }}
                >
                  Войти
                </Btn>
              </FormGroup>
              <div className="login-social-title">
                <H6 attrH6={{ className: "text-muted mt-4 or" }}>
                  Или зарегистрируйтесь
                </H6>
              </div>

              <div className="social my-4 ">
                <div className="btn-showcase">
                  <a
                    className="btn btn-light w-100"
                    href={CAFEBOTUM_BOT_LINK}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Image
                      attrImage={{
                        src: telegramIconSrc,
                        alt: "telegram-logo",
                        style: { width: "24px" },
                      }}
                    />{" "}
                    Telegram
                  </a>
                </div>
              </div>

              <P attrPara={{ className: "text-center mb-0 " }}>
                Можете зарегистрироваться через Telegram за пару кликов
              </P>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginForm;
