import React, { useEffect, useState } from "react";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import {
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Media,
  Row,
} from "reactstrap";
import { useForm } from "react-hook-form";
import SweetAlert from "sweetalert2";

import RestrictedCard from "../../../Common/Card/RestrictedCard";
import {
  coreGetDetailRestaurantSettings,
  coreUpdateRestaurantSettings,
} from "../../../../api/core/restaurants";
import { useUserSelector } from "../../../../store/useSelectors";
import { TTariffOption } from "../../../../Constant/tariffs";
import Loader from "../../../../Layout/Loader";
import ButtonLoading from "../../../Common/ButtonLoading/ButtonLoading";

const bonusesMap = {
  bonus_enabled: true,
  bonus_receive_max: true,
  bonus_receive_percent: true,
  bonus_spend_max_percent: true,
};

const BonusesSettings = () => {
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const { selectedRestaurant } = useUserSelector();
  const isRestricted =
    !selectedRestaurant?.tariff.options[TTariffOption.BONUSES];

  const isBonusEnabled = watch("bonus_enabled");

  const onSubmit = (data) => {
    const currentData = {};

    for (let key in data) {
      if (typeof data[key] === "string") {
        data[key] = Number(data[key]);
      }
    }

    Object.keys(data).forEach((key) => {
      if (data[key] !== settings[key]) {
        currentData[key] = data[key];
      }
    });

    if (Object.keys(currentData).length === 0) return;

    setLoading(true);
    coreUpdateRestaurantSettings(currentData).then((response) => {
      if (response.status === 200) {
        setSettingsData(response.data);
        setLoading(false);
        SweetAlert.fire("Настройки успешно обновлёны!", "", "success");
      }
    });
  };

  const setSettingsData = (data) => {
    const obj = {};
    for (let key in data) {
      if (bonusesMap[key]) {
        obj[key] = data[key];
        setValue(key, data[key]);
      }
    }
    setSettings(obj);
  };

  useEffect(() => {
    setLoading(true);
    coreGetDetailRestaurantSettings().then((response) => {
      if (response.status === 200) {
        setSettingsData(response.data);
        setLoading(false);
      }
    });
  }, []);

  if (!settings) return <Loader />;

  return (
    <>
      <Breadcrumbs
        mainTitle="Настройки бонусной системы"
        title="Бонусная система"
        parent="Настройки"
      />
      <Container fluid={true}>
        <Row>
          <Col xs={12}>
            <RestrictedCard isRestricted={isRestricted}>
              <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Row className="justify-content-between gap-2">
                    <Col xs={12} className={"mb-5"}>
                      <Media>
                        <Label className="col-form-label m-r-10">
                          <h6>Включить бонусную систему</h6>
                        </Label>
                        <Media body className="text-end switch-lg icon-state">
                          <Label className="switch">
                            <input
                              type="checkbox"
                              name="bonus_enabled"
                              {...register("bonus_enabled")}
                            />
                            <span className="switch-state" />
                          </Label>
                        </Media>
                      </Media>
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col xs={12}>
                          <FormGroup>
                            <Label>
                              Максимальное количество бонусов, которое можно
                              получить с 1 заказа *
                            </Label>
                            <input
                              className={`form-control ${
                                errors.bonus_receive_max ? "is-invalid" : ""
                              }`}
                              type="number"
                              name="bonus_receive_max"
                              disabled={!isBonusEnabled}
                              {...register("bonus_receive_max", {
                                required: true,
                              })}
                            />
                            <span style={{ color: "red" }}>
                              {errors.bonus_receive_max &&
                                "Необходимо заполнить"}
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FormGroup>
                            <Label>
                              Процент от суммы заказа, который клиент получит в
                              виде бонусов *
                            </Label>
                            <input
                              className={`form-control ${
                                errors.bonus_receive_percent ? "is-invalid" : ""
                              }`}
                              type="number"
                              name="bonus_receive_percent"
                              disabled={!isBonusEnabled}
                              {...register("bonus_receive_percent", {
                                required: true,
                              })}
                            />
                            <span style={{ color: "red" }}>
                              {errors.bonus_receive_percent &&
                                "Необходимо заполнить"}
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FormGroup>
                            <Label>
                              Максимальный процент от суммы заказа, который
                              можно оплатить бонусами *
                            </Label>
                            <input
                              className={`form-control ${
                                errors.bonus_spend_max_percent
                                  ? "is-invalid"
                                  : ""
                              }`}
                              type="number"
                              name="bonus_spend_max_percent"
                              disabled={!isBonusEnabled}
                              {...register("bonus_spend_max_percent", {
                                required: true,
                              })}
                            />
                            <span style={{ color: "red" }}>
                              {errors.bonus_spend_max_percent &&
                                "Необходимо заполнить"}
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <div className="text-end">
                    <ButtonLoading loading={loading}>Сохранить</ButtonLoading>
                  </div>
                </CardFooter>
              </Form>
            </RestrictedCard>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BonusesSettings;
