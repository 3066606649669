export const getBlockEndDate = (value) => {
  if (value === null) return "Навсегда";

  return `до ${value}`;
};

export const getBlockReason = (value) => {
  if (!value) return "Не указана";

  return value;
};
