import { Media } from "reactstrap";
import {
  orderDeliveryTypeMap,
  orderPaymentMethodMap,
  orderStateToTextMap,
} from "../../../Constant/orders";
import { convertCoreApiDateToLocalString } from "../../../Services/utils";
import React from "react";
import UserAvatar from "../../Common/UserAvatar/UserAvatar";

const tableColumns = [
  {
    name: "Клиент",
    selector: (row) => (
      <Media className="d-flex gap-3">
        <UserAvatar
          size={30}
          id={row.restaurant_user.user.id}
          src={row.restaurant_user.user.photo}
        />
        <Media body className="align-self-center">
          <div>{row.restaurant_user.user.first_name}</div>
        </Media>
      </Media>
    ),
    sortable: false,
    center: false,
  },
  {
    name: "Статус",
    selector: (row) => `${orderStateToTextMap[row.state]}`,
    sortable: false,
    center: true,
  },
  {
    name: "Дата создания",
    selector: (row) => `${convertCoreApiDateToLocalString(row.created_at)}`,
    sortable: false,
    center: true,
  },
  {
    name: "Вид доставки",
    selector: (row) => `${orderDeliveryTypeMap[row.delivery_type]}`,
    sortable: false,
    center: true,
  },
  {
    name: "Способ оплаты",
    selector: (row) => `${orderPaymentMethodMap[row.payment_method]}`,
    sortable: false,
    center: true,
  },
];

export default tableColumns;
