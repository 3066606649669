import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Breadcrumbs from "../../../Common/Breadcrumbs";
import {
  coreDeleteMailing,
  coreGetMailing,
  corePatchMailing,
} from "../../../../api/core/mailings";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Media,
  Row,
} from "reactstrap";
import { useForm } from "react-hook-form";
import SweetAlert from "sweetalert2";
import { H6, P } from "../../../../AbstractElements";
import RestrictedCard from "../../../Common/Card/RestrictedCard";
import { TTariffOption } from "../../../../Constant/tariffs";
import Loader from "../../../../Layout/Loader";
import { TMailingMaps, TMailingType } from "../../../../Constant/mailings";
import {
  convertCoreApiDateToInputDate,
  isValidJsonObject,
} from "../../../../Services/utils";
import { Link } from "react-router-dom";
import { DOCS_URLS } from "../../../../Constant/docs";
import ButtonLoading from "../../../Common/ButtonLoading/ButtonLoading";

const MailingDetailPage = () => {
  const [loading, setLoading] = useState(false);
  const { mailingsId } = useParams();
  const [data, setData] = useState(null);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    getValues,
    setValue,
  } = useForm();

  useEffect(() => {}, [watch()]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data) {
      Object.keys(data).forEach((key) => {
        if (key === "created_at") {
          setValue(key, convertCoreApiDateToInputDate(data[key]));
        } else if (key === "start_at") {
          setValue(key, convertCoreApiDateToInputDate(data[key]));
        } else if (key === "state") {
          setValue(key, TMailingMaps.textContent[data[key]]);
        } else if (key === "data") {
          if (data.type === TMailingType.BASE) {
            setValue(key, data[key].text);
          } else {
            setValue(key, JSON.stringify(data[key], null, 4));
          }
        } else {
          setValue(key, data[key]);
        }
      });
    }
  }, [data]);

  const fetchData = () => {
    coreGetMailing(mailingsId).then((response) => {
      setData(response.data);
    });
  };

  const deleteMailing = () => {
    SweetAlert.fire({
      title: `Вы уверены?`,
      text: "После отмены рассылку нельзя будет восстановить",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Уверен",
      cancelButtonText: "Назад",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        coreDeleteMailing(mailingsId).then((response) => {
          if (response.status === 200) {
            SweetAlert.fire("Рассылка успешно отменена", "", "success");
            setData(response.data);
          }
        });
      }
    });
  };

  const onSubmit = (dataForm) => {
    const currentData = {};

    for (const key in data) {
      if (key === "created_at" || key === "state") continue;

      if (data.hasOwnProperty(key)) {
        if (data[key] !== dataForm[key]) {
          if (key === "data") {
            if (data[key].text !== dataForm[key]) {
              currentData[key] = dataForm[key];
            }
          } else if (key === "start_at") {
            const formData = new Date(dataForm.start_at)
              .toISOString()
              .replace("Z", "")
              .slice(0, 19);
            if (data[key] !== formData) {
              currentData[key] = formData;
            }
          } else {
            currentData[key] = dataForm[key];
          }
        }
      }
    }

    if (currentData.type || currentData.data) {
      if (dataForm.type === TMailingType.BASE) {
        currentData.data = { text: dataForm.data };
      } else {
        if (isValidJsonObject(dataForm.data)) {
          currentData.data = JSON.parse(dataForm.data);
        } else {
          setError("data", {
            type: "custom",
          });
          return;
        }
      }
    }

    if (JSON.stringify(currentData.data) === JSON.stringify(data.data)) {
      delete currentData.data;
    }

    if (Object.keys(currentData).length === 0) return;

    setLoading(true);
    corePatchMailing(mailingsId, currentData)
      .then((response) => {
        if (response.status === 200) {
          SweetAlert.fire("Рассылка успешно обновлена!", "", "success");
          setData(response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!data) return <Loader />;

  return (
    <>
      <Breadcrumbs
        mainTitle={`Редактирование рассылки`}
        parent="Рассылки"
        title={`Редактирование рассылки`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Row>
                    <Col sm="6">
                      <Card>
                        <Media
                          className={`p-20 ${
                            !data.is_editable ? "disabled" : ""
                          }`}
                        >
                          <div className="radio radio-primary me-3">
                            <input
                              id="radio14"
                              type="radio"
                              name="type"
                              disabled={!data.is_editable}
                              value={TMailingType.BASE}
                              defaultChecked={true}
                              {...register("type", { required: true })}
                            />
                            <Label for="radio14"></Label>
                          </div>
                          <Media body>
                            <H6 attrH6={{ className: "mt-0 mega-title-badge" }}>
                              {"Обычная"}
                            </H6>
                            <P>
                              {
                                "Обычную рассылку очень легко создать, но отправлять можно только текстовые сообщения"
                              }
                            </P>
                          </Media>
                        </Media>
                      </Card>
                    </Col>
                    <Col sm="6">
                      <RestrictedCard
                        optionName={TTariffOption.MAILINGS_TELEGRAM_TYPE}
                      >
                        <Media
                          className={`p-20 ${
                            !data.is_editable ? "disabled" : ""
                          }`}
                        >
                          <div className="radio radio-secondary me-3">
                            <input
                              id="radio13"
                              type="radio"
                              disabled={!data.is_editable}
                              name="type"
                              value={TMailingType.TELEGRAM}
                              {...register("type", { required: true })}
                            />
                            <Label for="radio13"></Label>
                          </div>
                          <Media body>
                            <H6 className="mt-0 mega-title-badge">
                              {"Расширенная"}
                            </H6>
                            <P>
                              {
                                "В рассширенной рассылке можно отправлять любые сообщения, поддерживаемые Телеграмом (фото, видео, голосовые, кругляши и тд)"
                              }
                            </P>
                          </Media>
                        </Media>
                      </RestrictedCard>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} xl={12}>
                      <FormGroup>
                        <Label>Дата рассылки</Label>
                        <input
                          className={`form-control ${
                            errors.title ? "is-invalid" : ""
                          }`}
                          type="datetime-local"
                          name="start_at"
                          disabled={!data.is_editable}
                          {...register("start_at", { required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.title && "Необходимо заполнить дату"}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup>
                        <Label>
                          {getValues("type") === TMailingType.TELEGRAM
                            ? "Данные"
                            : "Текст сообщения"}
                        </Label>
                        <textarea
                          className={`form-control ${
                            errors.data ? "is-invalid" : ""
                          }`}
                          name="data"
                          type={"text"}
                          rows={9}
                          disabled={!data.is_editable}
                          {...register("data", { required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.data && "Введите корректные данные"}
                        </span>
                        {getValues("type") === TMailingType.TELEGRAM && (
                          <small className="form-text text-muted">
                            <Link
                              to={DOCS_URLS.MAILINGS_ADVANCED}
                              target={"_blank"}
                            >
                              Где взять данные?
                            </Link>
                          </small>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} xl={6}>
                      <FormGroup>
                        <Label>Дата Создания</Label>
                        <input
                          className={`form-control ${
                            errors.title ? "is-invalid" : ""
                          }`}
                          type="datetime-local"
                          disabled
                          name="created_at"
                          {...register("created_at", { required: true })}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} xl={6}>
                      <FormGroup>
                        <Label>Статус</Label>
                        <input
                          className={`form-control ${
                            errors.title ? "is-invalid" : ""
                          }`}
                          type="text"
                          disabled
                          name="state"
                          {...register("state", { required: true })}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                {data.is_editable && (
                  <CardFooter>
                    <div className="text-end">
                      <ButtonLoading
                        onClick={deleteMailing}
                        color="danger"
                        loading={loading}
                      >
                        Отменить
                      </ButtonLoading>
                      <ButtonLoading loading={loading}>Сохранить</ButtonLoading>
                    </div>
                  </CardFooter>
                )}
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MailingDetailPage;
