import React from "react";
import { Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import WorkTimeRangeSlider from "./WorkTimeRange";
import HeaderCard from "../../../Common/Component/HeaderCard";
import { coreUpdateRestaurantSettings } from "../../../../api/core/restaurants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useUserSelector } from "../../../../store/useSelectors";
import ButtonLoading from "../../../Common/ButtonLoading/ButtonLoading";

const WorkTime = ({ workTime, setWorkTime, onSubmit, loading }) => {
  const navigate = useNavigate();
  const { selectedRestaurant } = useUserSelector();

  const onClick = () => {
    onSubmit({ work_time: workTime });
  };

  return (
    <Card>
      <HeaderCard
        title={"График работы"}
        span1={
          "Вы можете выбрать дни и часы работы вашего заведения. Только в это время у клиентов будет возможность оформить заказ."
        }
      />
      <CardBody>
        <Row>
          <Col xs={12}>
            <WorkTimeRangeSlider
              field="mon"
              workTime={workTime}
              setWorkTime={setWorkTime}
              title={"Понедельник"}
            />
          </Col>
          <Col xs={12}>
            <WorkTimeRangeSlider
              field="tue"
              workTime={workTime}
              setWorkTime={setWorkTime}
              title={"Вторник"}
            />
          </Col>
          <Col xs={12}>
            <WorkTimeRangeSlider
              field="wed"
              workTime={workTime}
              setWorkTime={setWorkTime}
              title={"Среда"}
            />
          </Col>
          <Col xs={12}>
            <WorkTimeRangeSlider
              field="thu"
              workTime={workTime}
              setWorkTime={setWorkTime}
              title={"Четверг"}
            />
          </Col>
          <Col xs={12}>
            <WorkTimeRangeSlider
              field="fri"
              workTime={workTime}
              setWorkTime={setWorkTime}
              title={"Пятница"}
            />
          </Col>
          <Col xs={12}>
            <WorkTimeRangeSlider
              field="sat"
              workTime={workTime}
              setWorkTime={setWorkTime}
              title={"Суббота"}
            />
          </Col>
          <Col xs={12}>
            <WorkTimeRangeSlider
              field="sun"
              workTime={workTime}
              setWorkTime={setWorkTime}
              title={"Воскресенье"}
            />
          </Col>
        </Row>
      </CardBody>
      <CardFooter>
        <div className="text-end">
          <ButtonLoading loading={loading} onClick={onClick}>
            Сохранить
          </ButtonLoading>
        </div>
      </CardFooter>
    </Card>
  );
};

export default WorkTime;
