import React from "react";
import { Image } from "../../../AbstractElements";
import { getPhotoThumbnail } from "../../../Services/media";
import Avatar from "boring-avatars";

const UserAvatar = ({ id, src, size }) => {
  return (
    <div
      className="user-avatar"
      style={{
        borderRadius: "50%",
        minHeight: `${size}px`,
        height: `${size}px`,
        overflow: "hidden",
        minWidth: `${size}px`,
        width: `${size}px`,
      }}
    >
      {src ? (
        <Image
          attrImage={{
            className: `img-${size} w-100`,
            src: getPhotoThumbnail(src),
            alt: "avatar",
          }}
        />
      ) : (
        <Avatar
          style={{ borderRadius: "50%", height: `${size}px !important` }}
          square={true}
          size={size}
          name={`${id}`}
          variant="beam"
          colors={["#6610f2", "#6f42c1", "#0d6efd", "#6c757d", "#C20D90"]}
        />
      )}
    </div>
  );
};

export default UserAvatar;
