import { coreClient, processResponseError } from "./index";
import { getFileAsBase64 } from "../../Services/media";

export const coreGetProducts = async (filters) => {
  const filterQuery = new URLSearchParams(filters);

  try {
    return await coreClient.get(`/products/?${filterQuery}`);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreGetDetailProduct = async (productId) => {
  try {
    return await coreClient.get(`/products/${productId}`);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreUpdateProduct = async (productId, data) => {
  if (data["photo"]) {
    data["photo"] = await getFileAsBase64(data["photo"][0]);
  }
  try {
    return await coreClient.patch(`/products/${productId}`, data);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreDeleteProduct = async (productId) => {
  try {
    return await coreClient.delete(`/products/${productId}/`);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreCreateProduct = async (data) => {
  if (data["photo"]) {
    data["photo"] = await getFileAsBase64(data["photo"][0]);
  }
  try {
    return await coreClient.post("/products/", data);
  } catch (error) {
    processResponseError(error);
  }
};
