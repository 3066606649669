import React, { useState } from "react";
import { Button, Card, CardBody, Col, Media, Row } from "reactstrap";
import {
  convertCoreApiDateToLocalString,
  formatPhoneNumber,
} from "../../../../Services/utils";
import UserModal from "./UserModal";
import {
  coreDeleteUserBlocks,
  coreCreateUserBlock,
} from "../../../../api/core/users";
import SweetAlert from "sweetalert2";
import { FaLock, FaSpinner, FaUnlock } from "react-icons/fa";
import UserAvatar from "../../../Common/UserAvatar/UserAvatar";
import RestrictedElement from "../../../Common/Card/RestrictedElement";
import { TTariffOption } from "../../../../Constant/tariffs";
import {
  coreRestaurantUserStaffDelete,
  coreRestaurantUserStaffCreate,
} from "../../../../api/core/staff";
import { useUserSelector } from "../../../../store/useSelectors";

const UserInfoCard = ({
  restaurantUser,
  restaurantUserId,
  isBlocked,
  setIsBlocked,
  getDetailUser,
}) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [stateButton, setStateButton] = useState(restaurantUser.is_blocked);
  const [stateStaffButton, setStateStaffButton] = useState(
    !!restaurantUser?.staff?.created_by
  );
  const { userData } = useUserSelector();
  const { selectedRestaurant } = useUserSelector();

  const onChangeBloked = () => {
    const currentUser = restaurantUser;
    currentUser.is_blocked = !currentUser.is_blocked;
    setIsBlocked(!isBlocked);
  };

  const onBlock = (e) => {
    e.preventDefault();
    const data = {};

    if (e.target.elements["reason"].value) {
      data.reason = e.target.elements["reason"].value;
    }

    if (e.target.elements["date"].value) {
      data.end_date = convertCoreApiDateToLocalString(
        e.target.elements["date"].value
      );
    }

    setStateButton("loading");

    coreCreateUserBlock(restaurantUserId, data).then((response) => {
      if (response.status === 200) {
        SweetAlert.fire("Клиент заблокирован!", "", "success");
        setStateButton(true);
        toggle(false);
        onChangeBloked();
      } else {
        SweetAlert.fire("Клиента не удалось заблокировать", "", "warning");
        setStateButton(false);
      }
    });
  };

  const onUnBlock = () => {
    SweetAlert.fire({
      title: `Вы уверены?`,
      text: "Клиент будет разблокирован",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Уверен",
      cancelButtonText: "Назад",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        setStateButton("loading");
        coreDeleteUserBlocks(restaurantUserId).then((response) => {
          if (response.status === 200) {
            SweetAlert.fire("Клиент успешно разблокирован!", "", "success");
            setStateButton(false);
            onChangeBloked();
          } else {
            SweetAlert.fire(
              "Не удалось разблокировать клиента!",
              "",
              "warning"
            );
            setStateButton(true);
          }
        });
      }
    });
  };

  const onPostStaff = () => {
    SweetAlert.fire({
      title: `Вы уверены?`,
      text: "Клиент станет сотрудником",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Уверен",
      cancelButtonText: "Назад",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        setStateStaffButton("loading");
        coreRestaurantUserStaffCreate(restaurantUserId).then((response) => {
          if (response && response.status === 200) {
            SweetAlert.fire("Клиент назначен сотрудником!", "", "success");
            getDetailUser();
            setStateStaffButton(true);
          } else {
            setStateStaffButton(false);
          }
        });
      }
    });
  };

  const onDeleteStaff = () => {
    SweetAlert.fire({
      title: `Вы уверены?`,
      text: "Клиент потеряет статус сотрудника",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Уверен",
      cancelButtonText: "Назад",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        setStateStaffButton("loading");
        coreRestaurantUserStaffDelete(restaurantUserId).then((response) => {
          if (response.status === 200) {
            SweetAlert.fire("Сотрудник успешно удален!", "", "success");
            getDetailUser();
            setStateStaffButton(false);
          } else {
            setStateStaffButton(true);
          }
        });
      }
    });
  };

  const ButtonBlockState = {
    false: (
      <Button color="outline-danger" className="w-100" onClick={() => toggle()}>
        <FaLock /> Заблокировать
      </Button>
    ),
    true: (
      <Button
        color="outline-primary"
        className="w-100"
        onClick={() => onUnBlock()}
      >
        <FaUnlock /> Разблокировать
      </Button>
    ),
    loading: (
      <Button color="primary" className="w-100">
        <FaSpinner className={"m-r-5 inherit spinner-animation"} />
      </Button>
    ),
  };

  const ButtonStaffState = {
    false: (
      <Button
        color="outline-primary"
        className="w-100"
        onClick={() => onPostStaff()}
      >
        Сделать сотрудником
      </Button>
    ),
    true: (
      <Button
        color="outline-danger"
        className="w-100"
        onClick={() => onDeleteStaff()}
      >
        Убрать статус сотрудника
      </Button>
    ),
    loading: (
      <Button color="primary" className="w-100">
        <FaSpinner className={"m-r-5 inherit spinner-animation"} />
      </Button>
    ),
  };

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col xs={12}>
              <h6>{`Имя и фамилия`}</h6>
              <p className={"mt-4"}>
                <Media className="d-flex gap-3">
                  <UserAvatar
                    src={restaurantUser.user.photo}
                    id={restaurantUser.user.id}
                    size={40}
                  />
                  <Media body className="align-self-center">
                    <div>
                      {restaurantUser.user.first_name}{" "}
                      {restaurantUser.user.last_name || ""}
                    </div>
                  </Media>
                </Media>
              </p>
            </Col>
            <hr className={"mt-4 mb-4"} />
            <Col xs={12}>
              <h6>{`Номер телефона`}</h6>
              <p>{formatPhoneNumber(restaurantUser.user.phone_number)}</p>
            </Col>
            <hr className={"mt-4 mb-4"} />
            <Col xs={12}>
              <h6>{`Дата регистрации`}</h6>
              <p>
                {convertCoreApiDateToLocalString(restaurantUser.created_at)}
              </p>
            </Col>

            {restaurantUser.telegram_user.username ? (
              <>
                <hr className={"mt-4 mb-4"} />
                <Col xs={12}>
                  <h6>{`Юзернейм (Telegram)`}</h6>
                  <p>{"@" + restaurantUser.telegram_user.username}</p>
                </Col>
              </>
            ) : null}

            <hr className={"mt-4 mb-4"} />
            <RestrictedElement
              isRestricted={
                !selectedRestaurant?.tariff.options[TTariffOption.BONUSES]
              }
            >
              <Col xs={12}>
                <h6>{`Бонусы`}</h6>
                <p> {restaurantUser.bonuses}</p>
              </Col>
            </RestrictedElement>

            <hr className={"mt-4 mb-4"} />

            <Col className={"mb-3"} xs={12}>
              {ButtonBlockState[stateButton]}
            </Col>
            {userData.id === selectedRestaurant?.creator?.id && (
              <Col className={"mb-3"} xs={12}>
                {ButtonStaffState[stateStaffButton]}
              </Col>
            )}
          </Row>
        </CardBody>
      </Card>
      <UserModal onBlock={onBlock} modal={modal} toggle={toggle} />
    </>
  );
};

export default UserInfoCard;
