import { H6 } from "../../../../AbstractElements";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import React, { useState } from "react";
import UserListFilterRadio from "./UserListFilterRadio";

const UserListPageSidebar = ({ filters, setFilters, fetchRestaurantUsers }) => {
  const [sidebaron, setSidebaron] = useState(false);

  const onClickFilter = () => {
    if (sidebaron) {
      setSidebaron(false);
      document.getElementById("userlist-wrapper").classList.add("sidebaron");
    } else {
      setSidebaron(true);
      document.getElementById("userlist-wrapper").classList.remove("sidebaron");
    }
  };
  const handleSearchKeyword = (keyword) => {
    setFilters((prev) => ({ ...prev, text: keyword }));
  };

  const onClick = () => {
    fetchRestaurantUsers(1);
  };

  return (
    <div className="product-wrapper" id="userlist-wrapper">
      <Row className={"justify-content-end"}>
        {Object.keys(filters).length === 0 ? null : (
          <Col xs={12} className="mb-4">
            <Button
              className={"w-100"}
              outline
              color={"info"}
              onClick={onClick}
            >
              Отфильтровать
            </Button>
          </Col>
        )}
      </Row>
      <Row className="feature-products">
        <Col xl="3" md="6">
          <div className={`product-sidebar`}>
            <div className="filter-section">
              <Card>
                <CardHeader>
                  <H6 attrH6={{ className: "mb-0 f-w-600" }}>
                    Фильтры
                    <span className="pull-right">
                      <i
                        className="fa fa-chevron-down toggle-data"
                        onClick={onClickFilter}
                      ></i>
                    </span>
                  </H6>
                </CardHeader>
                <div className={`left-filter`}>
                  <CardBody className="filter-cards-view animate-chk">
                    <UserListFilterRadio
                      onClick={onClick}
                      filters={filters}
                      onClickFilter={onClickFilter}
                      setSidebaron={setSidebaron}
                      setFilters={setFilters}
                      fetchRestaurantUsers={fetchRestaurantUsers}
                    />
                  </CardBody>
                </div>
              </Card>
            </div>
          </div>
        </Col>
        <Col xl="9" md="6">
          <div className="product-search">
            <Form>
              <FormGroup className="m-0 form-group search-product">
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Поиск..."
                  defaultValue={""}
                  onChange={(e) => handleSearchKeyword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      fetchRestaurantUsers();
                    }
                  }}
                />
                <i className="fa fa-search"></i>
              </FormGroup>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default UserListPageSidebar;
