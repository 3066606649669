import React from "react";
import { Card, CardBody, Col, Media, Row } from "reactstrap";
import { Btn, H6, Image, P } from "../../../../AbstractElements";
import HeaderCard from "../../../Common/Component/HeaderCard";
import { getPhotoThumbnail } from "../../../../Services/media";
import { sortOptions } from "../utils";
import { MoneyFormat } from "../../../../Services/utils";

const OrderItemsCard = ({ order }) => {
  return (
    <Card>
      <HeaderCard title={`Товары`} />
      <CardBody>
        <Row className="g-sm-4 g-3">
          {order.items.map((item, i) => (
            <Col xs={12} key={i}>
              <div className="prooduct-details-box">
                <Media className="order-items__card">
                  <Image
                    attrImage={{
                      className: "img-fluid img-100 rounded m-l-5",
                      src: getPhotoThumbnail(item.product.photo),
                      alt: "",
                    }}
                  ></Image>
                  <div className="media-body ms-3 overflow-hidden">
                    <div className="product-name">
                      <H6>{item.product.title}</H6>
                    </div>
                    <div className="price d-flex">
                      <div className="text-muted me-2">{`Стоимость:`}</div>{" "}
                      {MoneyFormat.format(item.price)}
                    </div>
                    <div className="price d-flex">
                      <div className="text-muted me-2">{`Количество:`}</div>{" "}
                      {item.quantity}
                    </div>

                    {item?.options?.length > 0 && (
                      <div className="mt-2">
                        <b className="">{`Модификаторы:`}</b>
                        <div>
                          {sortOptions(item.options).map((optionGroup) => {
                            return (
                              <div
                                style={{ paddingLeft: "10px" }}
                                key={optionGroup.optionGroupTitle}
                              >
                                <b className="text-muted">
                                  {optionGroup.optionGroupTitle}:
                                </b>
                                <ul
                                  style={{
                                    paddingLeft: "20px",
                                    listStyle: "disc",
                                  }}
                                >
                                  {optionGroup.options.map((option) => (
                                    <li className="price" key={option.title}>
                                      {option.title}: {option.price}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}

                    <Btn attrBtn={{ color: "primary", size: "xs" }}>
                      x{item.quantity}
                    </Btn>
                  </div>
                </Media>
              </div>
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
  );
};

export default OrderItemsCard;
