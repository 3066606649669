export const TOrderPaymentMethod = {
  CASH: "CASH",
  CARD: "CARD",
  ONLINE: "ONLINE",
};

export const orderPaymentMethodMap = {
  [TOrderPaymentMethod.CASH]: "💸 Оплата наличными",
  [TOrderPaymentMethod.CARD]: "💳 Оплата по карте",
  [TOrderPaymentMethod.ONLINE]: "🌐 Онлайн-оплата",
};

export const TOrderDeliveryType = {
  PICKUP: "PICKUP",
  DELIVERY: "DELIVERY",
  TABLE: "TABLE",
};

export const orderDeliveryTypeMap = {
  [TOrderDeliveryType.PICKUP]: "Клиент заберёт сам",
  [TOrderDeliveryType.DELIVERY]: "Доставка",
  [TOrderDeliveryType.TABLE]: "К столику",
};

export const TOrderState = {
  CREATED: "CREATED",
  COOKING: "COOKING",
  DELIVERING: "DELIVERING",
  FINISHED: "FINISHED",
  CANCELLED: "CANCELLED",
};

export const TOrderButtonState = {
  textButton: {
    [TOrderState.CREATED]: "Взять в работу",
    [TOrderState.COOKING]: "Взять в доставку",
    [TOrderState.DELIVERING]: "Завершить",
  },
  colorButton: {
    [TOrderState.CREATED]: "outline-info",
    [TOrderState.COOKING]: "outline-primary",
    [TOrderState.DELIVERING]: "outline-success",
  },
  nextState: {
    [TOrderState.CREATED]: "COOKING",
    [TOrderState.COOKING]: "DELIVERING",
    [TOrderState.DELIVERING]: "FINISHED",
  },
};

export const orderStateToTextMap = {
  [TOrderState.CREATED]: "Заказ создан",
  [TOrderState.COOKING]: "Готовится...",
  [TOrderState.DELIVERING]: "Доставляется...",
  [TOrderState.FINISHED]: "Заказ доставлен",
  [TOrderState.CANCELLED]: "Заказ отменён",
};

export const stateToBadge = {
  [TOrderState.CREATED]: "badge-info",
  [TOrderState.COOKING]: "badge-primary",
  [TOrderState.DELIVERING]: "badge-success",
};
