import { getRandomInt } from "./utils";
import { TOrderDeliveryType, TOrderPaymentMethod } from "../Constant/orders";

import fakeUserAvatar1 from "../assets/images/fake_stats/avatars/1.png";
import fakeUserAvatar2 from "../assets/images/fake_stats/avatars/2.jpg";
import fakeUserAvatar3 from "../assets/images/fake_stats/avatars/3.jpg";
import fakeUserAvatar4 from "../assets/images/fake_stats/avatars/4.jpg";
import fakeUserAvatar5 from "../assets/images/fake_stats/avatars/5.jpg";

import fakeProductPhoto1 from "../assets/images/fake_stats/product_photo/1.jpg";
import fakeProductPhoto2 from "../assets/images/fake_stats/product_photo/2.jpg";
import fakeProductPhoto3 from "../assets/images/fake_stats/product_photo/3.jpg";
import fakeProductPhoto4 from "../assets/images/fake_stats/product_photo/4.jpg";
import fakeProductPhoto5 from "../assets/images/fake_stats/product_photo/5.jpg";

export const getFakeUsersCountStats = () => {
  const now = new Date();
  const dayDelta = 1000 * 60 * 60 * 24;
  const gross = [];
  for (let i = 0; i < 10; i++) {
    gross.push({
      date_key: new Date(now - dayDelta * (10 - i)).toISOString(),
      count: getRandomInt(1, 15),
    });
  }
  return {
    count_month: gross.reduce((prev, current) => prev + current.count, 0),
    gross: gross,
  };
};

export const getFakeOrdersStats = () => {
  const now = new Date();
  const dayDelta = 1000 * 60 * 60 * 24;
  const gross = [];
  for (let i = 0; i < 10; i++) {
    const randomInt = getRandomInt(30, 45);
    gross.push({
      date_key: new Date(now - dayDelta * (10 - i)).toISOString(),
      count: randomInt,
      sum: randomInt * getRandomInt(300, 320),
    });
  }
  return {
    data_month: {
      count: gross.reduce((prev, current) => prev + current.count, 0),
      sum: gross.reduce((prev, current) => prev + current.sum, 0),
    },
    gross: gross,
  };
};

export const getFakeCountByPaymentMethodStats = () => {
  return [
    {
      count: getRandomInt(20, 40),
      payment_method: TOrderPaymentMethod.CASH,
    },
    {
      count: getRandomInt(20, 40),
      payment_method: TOrderPaymentMethod.CARD,
    },
  ];
};

export const getFakeCountByDeliveryTypeStats = () => {
  return [
    {
      count: getRandomInt(20, 40),
      delivery_type: TOrderDeliveryType.PICKUP,
    },
    {
      count: getRandomInt(20, 40),
      delivery_type: TOrderDeliveryType.DELIVERY,
    },
    {
      count: getRandomInt(20, 40),
      delivery_type: TOrderDeliveryType.TABLE,
    },
  ];
};

export const getFakeTopUsersStats = () => {
  return [
    {
      id: null,
      user: {
        id: null,
        first_name: "Anton Ivanov",
        phone_number: null,
        last_name: null,
        photo: fakeUserAvatar1,
      },
      created_at: "2023-10-29T10:50:04.607259",
      total_orders: 119,
    },
    {
      id: null,
      user: {
        id: null,
        first_name: "Anna Plotnikova",
        phone_number: null,
        last_name: null,
        photo: fakeUserAvatar2,
      },
      created_at: "2023-10-29T10:50:04.607259",
      total_orders: 78,
    },
    {
      id: null,
      user: {
        id: null,
        first_name: "Alisa <3",
        phone_number: null,
        last_name: null,
        photo: fakeUserAvatar3,
      },
      created_at: "2023-10-29T10:50:04.607259",
      total_orders: 75,
    },
    {
      id: null,
      user: {
        id: null,
        first_name: "Victor M.",
        phone_number: null,
        last_name: null,
        photo: fakeUserAvatar4,
      },
      created_at: "2023-10-29T10:50:04.607259",
      total_orders: 35,
    },
    {
      id: null,
      user: {
        id: null,
        first_name: "Dima Petrov",
        phone_number: null,
        last_name: null,
        photo: fakeUserAvatar5,
      },
      created_at: "2023-10-29T10:50:04.607259",
      total_orders: 29,
    },
  ];
};

export const getFakeTopOrdersStats = () => {
  return [
    {
      id: null,
      title: "Кофе «Американо»",
      photo: fakeProductPhoto1,
      price: 99,
      total_quantity: 214,
    },
    {
      id: null,
      title: "Картофель фри",
      photo: fakeProductPhoto3,
      price: 129,
      total_quantity: 116,
    },
    {
      id: null,
      title: "Домашний бургер",
      photo: fakeProductPhoto2,
      price: 449,
      total_quantity: 44,
    },
    {
      id: null,
      title: "Сырный Джо",
      photo: fakeProductPhoto4,
      price: 489,
      total_quantity: 33,
    },
    {
      id: null,
      title: "Домашний морс",
      photo: fakeProductPhoto5,
      price: 139,
      total_quantity: 19,
    },
  ];
};
