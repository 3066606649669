import React, { Fragment, useEffect, useState } from "react";
import { Col, Label, Media, Row } from "reactstrap";
import { getTrackBackground, Range } from "react-range";

const WorkTimeRangeSlider = ({ title, field, workTime, setWorkTime }) => {
  const STEP = 1;
  const MIN = 0;
  const MAX = 1440;

  const [values, setValues] = useState(workTime[field]);
  const [switchValue, setSwitchValue] = useState(!!values);

  const onChange = (newValues) => {
    const wt = workTime;
    wt[field] = newValues;
    setWorkTime(wt);
    setValues(newValues);
  };

  useEffect(() => {
    if (switchValue && values === null) {
      onChange([600, 1320]);
    }
  }, [switchValue]);

  function toHoursAndMinutes(totalMinutes) {
    let hours = Math.floor(totalMinutes / 60).toString();
    let minutes = (totalMinutes % 60).toString();

    if (hours.length === 1) {
      hours = `0` + hours;
    }
    if (minutes.length === 1) {
      minutes = "0" + minutes;
    }

    if (hours === "24") {
      hours = "00";
    }

    return `${hours}:${minutes}`;
  }

  return (
    <Fragment>
      <Row className={"mb-4"}>
        <Col md="2">
          <Label>
            <Media>
              <Label className="col-form-label m-r-10">{title}</Label>
              <Media body className={"switch-sm icon-state"}>
                <Label className="switch">
                  <input
                    type="checkbox"
                    checked={switchValue}
                    onChange={() => {
                      setSwitchValue(!switchValue);
                      onChange(null);
                    }}
                  />
                  <span className={"switch-state"}></span>
                </Label>
              </Media>
            </Media>
          </Label>
          <Col xs={12}>
            {values !== null
              ? `с ${toHoursAndMinutes(values[0])} по ${toHoursAndMinutes(
                  values[1]
                )}`
              : `Не работает`}
          </Col>
        </Col>
        {values !== null ? (
          <Col md="10">
            <Range
              values={values}
              step={STEP}
              min={MIN}
              max={MAX}
              onChange={(values) => {
                onChange(values);
              }}
              renderTrack={({ props, children }) => (
                <div
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  style={{
                    ...props.style,
                    height: "36px",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <div
                    ref={props.ref}
                    style={{
                      height: "5px",
                      width: "100%",
                      borderRadius: "4px",
                      background: getTrackBackground({
                        values,
                        colors: ["#ccc", "#7366ff", "#ccc"],
                        min: MIN,
                        max: MAX,
                      }),
                      alignSelf: "center",
                    }}
                  >
                    {children}
                  </div>
                </div>
              )}
              renderThumb={({ props, isDragged }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "35px",
                    width: "35px",
                    borderRadius: "30px",
                    backgroundColor: "#FFF",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 2px 6px #AAA",
                  }}
                ></div>
              )}
            />
          </Col>
        ) : null}
      </Row>
    </Fragment>
  );
};

export default WorkTimeRangeSlider;
