import {
  convertCoreApiDateToLocalString,
  limitString,
} from "../../../../Services/utils";
import { getBlockEndDate, getBlockReason } from "../utils";

const tableColumns = [
  {
    name: "Причина блокировки",
    selector: (row) => limitString(getBlockReason(row.reason), 30),
    sortable: false,
    center: false,
  },
  {
    name: "Дата блокировки",
    selector: (row) =>
      `${
        row.created_at ? convertCoreApiDateToLocalString(row.created_at) : ""
      }`,
    sortable: false,
    center: true,
  },
  {
    name: "Конец блокировки",
    selector: (row) =>
      `${
        row.end_date == null
          ? getBlockEndDate(row.end_date)
          : convertCoreApiDateToLocalString(row.end_date)
      }`,
    sortable: false,
    center: true,
  },

  {
    name: "Блокировка активна",
    selector: (row) => `${row.is_active ? "✅ Активна" : "❌ Неактивна"}`,
    sortable: false,
    center: true,
  },
];

export default tableColumns;
