import { coreClient, processResponseError } from "./index";

export const coreGetCategories = async () => {
  try {
    return await coreClient.get("/categories/");
  } catch (error) {
    processResponseError(error);
  }
};

export const coreCreateCategory = async (data) => {
  try {
    return await coreClient.post("/categories/", data);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreUpdateCategory = async (categoryId, data) => {
  try {
    return await coreClient.patch(`/categories/${categoryId}/`, data);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreGetDetailCategory = async (categoryId) => {
  try {
    return await coreClient.get(`/categories/${categoryId}`);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreDeleteCategory = async (categoryId) => {
  try {
    return await coreClient.delete(`/categories/${categoryId}`);
  } catch (error) {
    processResponseError(error);
  }
};
