import React, { useEffect, useState } from "react";
import { Breadcrumbs } from "../../../../AbstractElements";
import { Col, Container, Row } from "reactstrap";
import { useParams } from "react-router";
import Loader from "../../../../Layout/Loader";
import { coreGetDetailOrder } from "../../../../api/core/orders";
import OrderInfoCard from "./OrderInfoCard";
import OrderDeliveryInfoCard from "./OrderDeliveryInfoCard";
import OrderItemsCard from "./OrderItemsCard";
import OrderAdditionalInfoCard from "./OrderAdditionalInfoCard";

const OrderDetailPage = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);

  useEffect(() => {
    updateDetailPage();
  }, []);

  const updateDetailPage = () => {
    coreGetDetailOrder(orderId).then((response) => {
      setOrder(response.data);
    });
  };

  if (!order) return <Loader />;
  const title = `Заказ #${order.id}`;

  return (
    <>
      <Breadcrumbs mainTitle={title} parent="Заказы" title={title} />
      <Container fluid>
        <Row>
          <Col xs={12} xl={4}>
            <OrderInfoCard order={order} setOrder={setOrder} />
          </Col>
          <Col xs={12} xl={8}>
            <OrderItemsCard order={order} />
          </Col>
          {order.delivery_type === "DELIVERY" ? (
            <Col xs={12}>
              <OrderDeliveryInfoCard order={order} />
            </Col>
          ) : null}
        </Row>
        <Row>
          <Col xs={12}>
            <OrderAdditionalInfoCard order={order} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OrderDetailPage;
