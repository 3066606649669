import React from "react";
import { Link } from "react-router-dom";
import { CardBody, CardHeader, Table } from "reactstrap";
import { H5, Image, P } from "../../../AbstractElements";
import { getPhotoThumbnail } from "../../../Services/media";
import { useUserSelector } from "../../../store/useSelectors";
import RestrictedCard from "../../Common/Card/RestrictedCard";
import { TTariffOption } from "../../../Constant/tariffs";
import { getFakeTopUsersStats } from "../../../Services/fakeStats";
import UserAvatar from "../../Common/UserAvatar/UserAvatar";

const UserRatingItem = ({ restaurantUser, selectedRestaurant, ...props }) => {
  return (
    <tr {...props}>
      <td>
        <UserAvatar
          src={restaurantUser.user.photo}
          id={restaurantUser.user.id}
          size={40}
        />
      </td>
      <td className="img-content-box">
        {/* Опциональный айдишник - для fakeStats, с бэка всегда айдишник приходит */}
        <Link
          className="d-block f-w-500"
          to={
            restaurantUser.id
              ? `${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/users/${restaurantUser.id}`
              : null
          }
        >
          {restaurantUser.user.first_name} {restaurantUser.user.last_name}
        </Link>
      </td>
      <td className="text-end">
        <P attrPara={{ className: "m-0 font-success" }}>
          {restaurantUser.total_orders} заказов
        </P>
      </td>
    </tr>
  );
};

const TopUsersCard = ({ stats }) => {
  const { selectedRestaurant } = useUserSelector();
  const isRestricted =
    !selectedRestaurant.tariff.options[TTariffOption.ADVANCED_STATS];

  const usersRating = isRestricted
    ? getFakeTopUsersStats()
    : stats.users.rating.filter((e) => e.total_orders > 0);

  if (usersRating.length === 0) return null;

  return (
    <div className={"appointment-sec"}>
      <div className="appointment">
        <RestrictedCard optionName={TTariffOption.ADVANCED_STATS}>
          <CardHeader className="card-no-border">
            <div className="header-top">
              <H5 attrH5={{ className: "m-0" }}>Топ клиентов</H5>
            </div>
          </CardHeader>
          <CardBody className="pt-0">
            <div className="appointment-table">
              <Table borderless responsive>
                <tbody>
                  {usersRating.map((restaurantUser, i) => (
                    <UserRatingItem
                      restaurantUser={restaurantUser}
                      selectedRestaurant={selectedRestaurant}
                      key={i}
                    />
                  ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </RestrictedCard>
      </div>
    </div>
  );
};

export default TopUsersCard;
