import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import { H5, Image, P } from "../../../AbstractElements";
import { getPhotoThumbnail } from "../../../Services/media";
import { MoneyFormat } from "../../../Services/utils";
import { useUserSelector } from "../../../store/useSelectors";
import RestrictedCard from "../../Common/Card/RestrictedCard";
import { TTariffOption } from "../../../Constant/tariffs";
import { getFakeTopOrdersStats } from "../../../Services/fakeStats";

const ProductRatingItem = ({ product, selectedRestaurant, ...props }) => {
  return (
    <tr {...props}>
      <td>
        <Image
          attrImage={{
            className: "img-fluid img-40 rounded-circle",
            src: getPhotoThumbnail(product.photo),
            alt: "user",
          }}
        />
      </td>
      <td className="img-content-box">
        {/* Опциональный айдишник - для fakeStats, с бэка всегда айдишник приходит */}
        <Link
          className="d-block f-w-500"
          to={
            product.id
              ? `${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/products/${product.id}`
              : "#"
          }
        >
          {product.title}
        </Link>
        <span className="f-light">{MoneyFormat.format(product.price)}</span>
      </td>
      <td className="text-end">
        <P attrPara={{ className: "m-0 font-success" }}>
          {product.total_quantity} шт
        </P>
      </td>
    </tr>
  );
};

const TopOrdersCard = ({ stats }) => {
  const { selectedRestaurant } = useUserSelector();
  const isRestricted =
    !selectedRestaurant.tariff.options[TTariffOption.ADVANCED_STATS];

  const productsRating = isRestricted
    ? getFakeTopOrdersStats()
    : stats.products.rating.filter((e) => e.total_quantity > 0);

  if (productsRating.length === 0) return null;

  return (
    <div className={"appointment-sec"}>
      <div className="appointment">
        <RestrictedCard optionName={TTariffOption.ADVANCED_STATS}>
          <CardHeader className="card-no-border">
            <div className="header-top">
              <H5 attrH5={{ className: "m-0" }}>Топ товаров</H5>
            </div>
          </CardHeader>
          <CardBody className="pt-0">
            <div className="appointment-table">
              <Table borderless responsive>
                <tbody>
                  {productsRating.map((product, i) => (
                    <ProductRatingItem
                      product={product}
                      selectedRestaurant={selectedRestaurant}
                      key={i}
                    />
                  ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </RestrictedCard>
      </div>
    </div>
  );
};

export default TopOrdersCard;
