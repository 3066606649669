import { coreClient, processResponseError } from "./";

export const coreCreateRestaurant = async (data) => {
  try {
    return await coreClient.post("/restaurants/", data);
  } catch (error) {
    processResponseError(error);
  }
};
export const coreGetRestaurants = async () => {
  try {
    return await coreClient.get("/restaurants/");
  } catch (error) {
    processResponseError(error);
  }
};

export const coreGetDetailRestaurant = async (restaurantId) => {
  try {
    return await coreClient.get(`/restaurants/${restaurantId}`);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreGetDetailRestaurantSettings = async () => {
  try {
    return await coreClient.get("/restaurants/settings/");
  } catch (error) {
    processResponseError(error);
  }
};

export const coreUpdateRestaurantSettings = async (data) => {
  try {
    return await coreClient.patch("/restaurants/settings/", data);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreGetRestaurantBot = async () => {
  try {
    return await coreClient.get("/restaurants/bot/");
  } catch (error) {
    processResponseError(error);
  }
};

export const coreUpdateRestaurantBot = async (data) => {
  try {
    return await coreClient.patch("/restaurants/bot/", data);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreGetRestaurantDeliveryAreas = async () => {
  try {
    return await coreClient.get("/restaurants/settings/delivery-areas");
  } catch (error) {
    processResponseError(error);
  }
};

export const coreUpdateRestaurantDeliveryAreas = async (data) => {
  try {
    return await coreClient.patch("/restaurants/settings/delivery-areas", data);
  } catch (error) {
    processResponseError(error);
  }
};
