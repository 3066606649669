import React from "react";
import { Col, Row } from "reactstrap";
import { H4, H6 } from "../../../AbstractElements";
import { MoneyFormat } from "../../../Services/utils";
import DashboardChart1 from "./DashboardChart1";
import { TTariffOption } from "../../../Constant/tariffs";
import RestrictedCard from "../../Common/Card/RestrictedCard";
import { useUserSelector } from "../../../store/useSelectors";
import { getFakeOrdersStats } from "../../../Services/fakeStats";

const OrdersTotalSumCard = ({ stats }) => {
  const { selectedRestaurant } = useUserSelector();
  const isRestricted =
    !selectedRestaurant.tariff.options[TTariffOption.ADVANCED_STATS];
  const displayStats = isRestricted ? getFakeOrdersStats() : stats.orders;

  return (
    <RestrictedCard
      optionName={TTariffOption.ADVANCED_STATS}
      className="o-hidden"
    >
      <div className="chart-widget-top">
        <Row className="card-body pb-0 m-0">
          <Col xl="9" lg="8" xs="9" className="p-0">
            <H6 attrH6={{ className: "mb-2" }}>{"Выручка"}</H6>
            <H4>{MoneyFormat.format(displayStats.data_month.sum)}</H4>
            <span>За последние 30 дней</span>
          </Col>
        </Row>
        <div id="chart-widget2">
          <DashboardChart1
            color={"#51bb25"}
            x={displayStats.gross.map((e) => e.date_key + "Z")}
            y={displayStats.gross.map((e) => e.sum)}
            yName={"Сумма"}
          />
        </div>
      </div>
    </RestrictedCard>
  );
};

export default OrdersTotalSumCard;
