import React, { Fragment, useContext, useState } from "react";
import { Button, Col, Input, Label, Row } from "reactstrap";
import { getTrackBackground, Range } from "react-range";
import { H6 } from "../../../../../AbstractElements";
import ProductsContext from "../ProductsContext";

const ProductFilterRadio = () => {
  const { categories, setFilters, fetchProducts } = useContext(ProductsContext);

  const clickCategory = (result, event) => {
    var elems = document.querySelectorAll("div.checkbox-animated");
    [].forEach.call(elems, function (el) {
      el.classList.remove("active");
    });
    event.target.classList.add("active");
    setFilters((prev) => ({ ...prev, category_id: result.id }));
  };

  if (!categories) return null;

  return (
    <Fragment>
      <div className="product-filter">
        <H6 className="f-w-600">{"Категория"}</H6>
        <div className="checkbox-animated mt-0 mb-0">
          {categories.map((item, i) => {
            return (
              <Label className="d-block" key={i}>
                <Input
                  className="radio_animated"
                  type="radio"
                  name="name"
                  value={item}
                  onClick={(e) => clickCategory(item, e)}
                />
                {item.title}
              </Label>
            );
          })}
        </div>
      </div>

      <Row>
        <Col xs={12} className={"mt-5"}>
          <Button
            outline
            color={"danger"}
            className={"w-100"}
            onClick={() => {
              setFilters({});
              fetchProducts();
            }}
          >
            Сбросить
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ProductFilterRadio;
