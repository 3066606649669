import React from "react";
import { Card, CardBody } from "reactstrap";
import { H4 } from "../../../AbstractElements";
import SvgIcon from "../../Common/Component/SvgIcon";

const DashboardWidget = ({ data }) => {
  return (
    <Card className={`small-widget`}>
      <CardBody className={data.color}>
        <span className="f-light">{data.title}</span>
        <div className="d-flex align-items-end gap-1">
          <H4>
            <div>{data.total}</div>
          </H4>
          <span className={`font-${data.color} f-12 f-w-500`}></span>
        </div>
        <div className="bg-gradient">
          <SvgIcon iconId={data.icon} className="stroke-icon svg-fill" />
        </div>
      </CardBody>
    </Card>
  );
};

export default DashboardWidget;
