import { coreClient, processResponseError } from "./index";

export const coreGetPaymentLink = async (amount) => {
  try {
    return await coreClient.post(`/payments/create-link`, {
      amount: amount,
    });
  } catch (error) {
    processResponseError(error);
  }
};

export const coreGetTransactions = async (page, filters, size) => {
  const filterQuery = new URLSearchParams({
    page: page || 1,
    size: size || 1,
    ...filters,
  });
  try {
    return await coreClient.get(`payments/transactions/?${filterQuery}`);
  } catch (error) {
    processResponseError(error);
  }
};
