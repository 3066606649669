import React, { Fragment, useEffect, useState } from "react";
import { Container } from "reactstrap";
import DataTable from "react-data-table-component";
import tableColumns from "./table";
import { coreGetRestaurantUsers } from "../../../../api/core/users";
import Breadcrumbs from "../../../Common/Breadcrumbs";
import Loader from "../../../../Layout/Loader";
import { useNavigate } from "react-router";
import { useUserSelector } from "../../../../store/useSelectors";
import UserListPageSidebar from "./UserListPageSidebar";

const UsersListPage = () => {
  const [filters, setFilters] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const navigate = useNavigate();
  const { selectedRestaurant } = useUserSelector();

  const fetchRestaurantUsers = (page, currentFilters) => {
    setLoading(true);
    coreGetRestaurantUsers(
      page,
      currentFilters || filters,
      perPage,
      false
    ).then((response) => {
      setData(response?.data.items);
      setTotalRows(response?.data.total);
      setLoading(false);
    });
  };

  const handlePageChange = (page) => {
    fetchRestaurantUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    coreGetRestaurantUsers(page, filters, newPerPage).then((response) => {
      setData(response?.data.items);
      setTotalRows(response?.data.total);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchRestaurantUsers(1);
  }, []);

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Клиенты" title="Клиенты" />

      <Container fluid={true}>
        <UserListPageSidebar
          filters={filters}
          setFilters={setFilters}
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          fetchRestaurantUsers={fetchRestaurantUsers}
        />
        <Fragment>
          <DataTable
            data={data}
            columns={tableColumns}
            striped={true}
            center={true}
            progressPending={loading}
            progressComponent={<Loader />}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onRowClicked={(row) =>
              navigate(
                `${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/users/${row.id}`
              )
            }
            highlightOnHover
            pointerOnHover
          />
        </Fragment>
      </Container>
    </Fragment>
  );
};
export default UsersListPage;
