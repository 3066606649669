export const sortOptions = (options) => {
  const hMap = new Map();

  options.forEach((option) => {
    if (!hMap.has(option.option_group.id)) {
      hMap.set(option.option_group.id, [
        { optionGroupTitle: option.option_group.title },
      ]);
    }

    const currentOptions = hMap.get(option.option_group.id);

    hMap.set(option.option_group.id, [
      ...currentOptions,
      { title: option.title, price: option.price },
    ]);
  });

  const resultArray = Array.from(hMap, ([_, value]) => ({
    optionGroupTitle: value[0].optionGroupTitle,
    options: value.slice(1),
  }));

  return resultArray;
};
