import React, { useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  Label,
  Media,
  PopoverBody,
  PopoverHeader,
  Row,
  UncontrolledPopover,
} from "reactstrap";
import HeaderCard from "../../../Common/Component/HeaderCard";
import { useForm } from "react-hook-form";
import { TOrderPaymentMethod } from "../../../../Constant/orders";
import { TTariffOption } from "../../../../Constant/tariffs";
import RestrictedElement from "../../../Common/Card/RestrictedElement";
import { Link } from "react-router-dom";
import { useUserSelector } from "../../../../store/useSelectors";
import ButtonLoading from "../../../Common/ButtonLoading/ButtonLoading";

const PaymentMethods = ({ onSubmit, settings, loading }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onlinePaymentsRef = useRef(null);
  const { selectedRestaurant } = useUserSelector();

  useEffect(() => {
    if (!settings) return;
    setValue("payment_methods", settings.payment_methods);
  }, [settings]);

  return (
    <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <HeaderCard title={"Способы оплаты"} />
        <CardBody>
          <Row>
            <Col xs={12}>
              <Media>
                <Label className="col-form-label m-r-10">
                  Оплата наличными
                </Label>
                <Media body className="text-end switch-lg icon-state">
                  <Label className="switch">
                    <input
                      type="checkbox"
                      value={TOrderPaymentMethod.CASH}
                      {...register("payment_methods", {
                        value: TOrderPaymentMethod.CASH,
                        required: true,
                      })}
                    />
                    <span className="switch-state" />
                  </Label>
                </Media>
              </Media>
            </Col>
            <Col xs={12}>
              <Media>
                <Label className="col-form-label m-r-10">
                  Оплата по карте (курьеру/на кассе)
                </Label>
                <Media body className="text-end switch-lg icon-state">
                  <Label className="switch">
                    <input
                      type="checkbox"
                      value={TOrderPaymentMethod.CARD}
                      {...register("payment_methods", {
                        value: TOrderPaymentMethod.CARD,
                        required: true,
                      })}
                    />
                    <span className="switch-state" />
                  </Label>
                </Media>
              </Media>
            </Col>
            <Col xs={12}>
              <RestrictedElement optionName={TTariffOption.ONLINE_PAYMENTS}>
                <div ref={onlinePaymentsRef}>
                  <Media>
                    <Label className="col-form-label m-r-10">
                      Онлайн-оплата
                    </Label>
                    <Media body className="text-end switch-lg icon-state">
                      <Label className="switch">
                        <input
                          type="checkbox"
                          disabled={true}
                          value={TOrderPaymentMethod.ONLINE}
                          {...register("payment_methods", {
                            value: TOrderPaymentMethod.ONLINE,
                            required: true,
                          })}
                        />
                        <span className="switch-state" />
                      </Label>
                    </Media>
                  </Media>
                </div>
              </RestrictedElement>
            </Col>
            <UncontrolledPopover
              target={onlinePaymentsRef}
              trigger={"click"}
              placement={"bottom"}
            >
              <PopoverHeader>Настройка перенесена</PopoverHeader>
              <PopoverBody>
                Чтобы включить/отключить онлайн-платежи - перейдите на страницу{" "}
                <Link
                  to={`/restaurants/${selectedRestaurant.id}/settings/payments`}
                >
                  «Онлайн-платежи»
                </Link>
              </PopoverBody>
            </UncontrolledPopover>
            <span style={{ color: "red" }}>
              {errors.payment_methods &&
                "Нужно выбрать минимум один способ оплаты"}
            </span>
          </Row>
        </CardBody>
        <CardFooter>
          <div className="text-end">
            <ButtonLoading loading={loading}>Сохранить</ButtonLoading>
          </div>
        </CardFooter>
      </Card>
    </Form>
  );
};

export default PaymentMethods;
