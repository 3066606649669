import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Breadcrumbs from "../../../Common/Breadcrumbs";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Media,
  Row,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import SweetAlert from "sweetalert2";
import { H6, P } from "../../../../AbstractElements";

import Loader from "../../../../Layout/Loader";
import { convertCoreApiDateToInputDate } from "../../../../Services/utils";
import ButtonLoading from "../../../Common/ButtonLoading/ButtonLoading";
import {
  coreRestaurantPromocodeUpdate,
  coreGetRestaurantPromocodeDetail,
} from "../../../../api/core/promocodes";
import Select from "react-select";
import {
  restaurantPromocodeTypeToTextMap,
  TRestaurantPromocodeType,
} from "../../../../Constant/promocodes";
import {
  coreGetDetailProduct,
  coreGetProducts,
} from "../../../../api/core/products";

const RestaurantPromocodeDetailPage = () => {
  const [loading, setLoading] = useState(false);
  const { promocodeId } = useParams();
  const [data, setData] = useState(null);
  const [productTitle, setProductTitle] = useState("");

  const [product, setProduct] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [selectLoader, setSelectLoader] = useState("Загрузка...");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
    control,
  } = useForm();

  useEffect(() => {}, [watch()]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null) {
          if (key === "valid_from") {
            setValue(key, convertCoreApiDateToInputDate(data[key]));
          } else if (key === "valid_to") {
            setValue(key, convertCoreApiDateToInputDate(data[key]));
          } else {
            setValue(key, data[key]);
          }
        }
      });

      if (data.product_id && !productTitle) {
        getFreeProduct(data.product_id);
      }
    }
  }, [data]);

  const fetchProducts = async () => {
    coreGetProducts().then((res) => {
      const currentRes = res.data.map((i) => ({
        value: i.id,
        label: i.title,
      }));

      setProduct(currentRes);
      if (currentRes.length) {
        setSelectLoader("Нет товаров для выбора");
      }
    });
  };

  const getFreeProduct = (id) => {
    coreGetDetailProduct(id).then((res) => {
      setProductTitle(res.data.title);
    });
  };

  const fetchData = () => {
    coreGetRestaurantPromocodeDetail(promocodeId).then((response) => {
      setData(response.data);
    });
  };

  const deactivatePromocode = () => {
    const d = new Date();
    d.setSeconds(0, 0);
    const now = d.toISOString().replace("Z", "").slice(0, 19);

    setLoading(true);
    coreRestaurantPromocodeUpdate(promocodeId, { valid_to: now })
      .then((response) => {
        if (response.status === 200) {
          SweetAlert.fire("Промокод успешно деактивирован!", "", "success");
          setData(response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = (dataForm) => {
    const currentData = {};

    if (dataForm.valid_from === "Invalid Date") delete dataForm.valid_from;
    if (dataForm.valid_to === "Invalid Date") delete dataForm.valid_to;

    if (dataForm.valid_to === "") {
      dataForm.valid_to = null;
    }
    if (dataForm.valid_from === "") {
      dataForm.valid_from = null;
    }

    if (data.valid_from) {
      dataForm.valid_from = new Date(dataForm.valid_from)
        .toISOString()
        .replace("Z", "")
        .slice(0, 19);
    }

    if (data.valid_to) {
      dataForm.valid_to = new Date(dataForm.valid_to)
        .toISOString()
        .replace("Z", "")
        .slice(0, 19);
    }

    Object.keys(dataForm).forEach((key) => {
      if (dataForm[key] !== data[key]) {
        currentData[key] = dataForm[key];
      }
    });

    if (Object.keys(currentData).length === 0) return;
    console.log(currentData);

    setLoading(true);
    coreRestaurantPromocodeUpdate(promocodeId, currentData)
      .then((response) => {
        if (response.status === 200) {
          SweetAlert.fire("Промокод успешно обновлен!", "", "success");
          setData(response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelectClick = () => {
    if (!clicked) {
      fetchProducts();
      setClicked(true);
    }
  };

  if (!data) return <Loader />;

  return (
    <>
      <Breadcrumbs
        mainTitle={`Редактирование промокода`}
        parent="Промокоды"
        title={`Редактирование промокода`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Row>
                    <Col sm="6">
                      <Card>
                        <Media className="p-20 disabled">
                          <div className="radio radio-primary me-3">
                            <input
                              id="radio14"
                              type="radio"
                              name="type"
                              value={TRestaurantPromocodeType.MONEY}
                              {...register("type", { required: true })}
                            />
                            <Label
                              style={{ pointerEvents: "none" }}
                              for="radio14"
                            ></Label>
                          </div>
                          <Media body>
                            <H6 attrH6={{ className: "mt-0 mega-title-badge" }}>
                              {
                                restaurantPromocodeTypeToTextMap[
                                  TRestaurantPromocodeType.MONEY
                                ]
                              }
                            </H6>
                            <P>
                              {
                                "При применении вычитает указанную сумму из стоимости заказа"
                              }
                            </P>
                          </Media>
                        </Media>
                      </Card>
                    </Col>
                    <Col sm="6">
                      <Card>
                        <Media className="p-20 disabled">
                          <div className="radio radio-secondary me-3 ">
                            <input
                              id="radio13"
                              type="radio"
                              name="type"
                              value={TRestaurantPromocodeType.FREE_PRODUCT}
                              {...register("type", { required: true })}
                            />
                            <Label
                              style={{ pointerEvents: "none" }}
                              for="radio13"
                            ></Label>
                          </div>
                          <Media body>
                            <H6 className="mt-0 mega-title-badge">
                              {
                                restaurantPromocodeTypeToTextMap[
                                  TRestaurantPromocodeType.FREE_PRODUCT
                                ]
                              }
                            </H6>
                            <P>
                              {
                                "При применении добавляет указанный товар в корзину с нулевой стоимостью"
                              }
                            </P>
                          </Media>
                        </Media>
                      </Card>
                    </Col>
                  </Row>
                  <Col xs={12}>
                    <FormGroup>
                      <Label>Промокод *</Label>
                      <input
                        disabled
                        className={`form-control ${
                          errors.code ? "is-invalid" : ""
                        }`}
                        name="code"
                        type={"text"}
                        rows={9}
                        {...register("code", { required: true })}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    {getValues("type") ===
                    TRestaurantPromocodeType.FREE_PRODUCT ? (
                      <FormGroup>
                        <Label>Товар, который будет в подарок *</Label>
                        <Controller
                          name="product_id"
                          control={control}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              className="basic-multi-select col-sm-12"
                              options={product}
                              value={product.find(
                                (option) => option.value === value
                              )}
                              placeholder={productTitle}
                              noOptionsMessage={() => selectLoader}
                              onMenuOpen={() => {
                                handleSelectClick();
                              }}
                              onChange={(val) => onChange(val.value)}
                            />
                          )}
                          rules={{ required: true }}
                        />
                        <span style={{ color: "red" }}>
                          {errors.category_id && "Необходимо выбрать товар"}
                        </span>
                      </FormGroup>
                    ) : (
                      <FormGroup>
                        <Label>Размер скидки *</Label>
                        <input
                          className={`form-control ${
                            errors.money_value ? "is-invalid" : ""
                          }`}
                          name="money_value"
                          type={"number"}
                          rows={9}
                          {...register("money_value", { required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.money_value && "Введите размер скидки"}
                        </span>
                      </FormGroup>
                    )}
                  </Col>
                  <Row className="flex-column flex-md-row">
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>Дата начала действия</Label>
                        <input
                          className={`form-control ${
                            errors.valid_from ? "is-invalid" : ""
                          }`}
                          type="datetime-local"
                          name="valid_from"
                          {...register("valid_from")}
                        />
                        <span style={{ color: "red" }}>
                          {errors.valid_from && "Неверная дата"}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>Дата окончания действия</Label>
                        <input
                          className={`form-control ${
                            errors.valid_to ? "is-invalid" : ""
                          }`}
                          type="datetime-local"
                          name="valid_to"
                          {...register("valid_to")}
                        />
                        <span style={{ color: "red" }}>
                          {errors.valid_to && "Неверная дата"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>

                <CardFooter>
                  <div className="text-end">
                    {data.valid_to && new Date(data.valid_to) > new Date() ? (
                      <ButtonLoading
                        onClick={deactivatePromocode}
                        color="danger"
                        loading={loading}
                      >
                        Деактивировать
                      </ButtonLoading>
                    ) : null}

                    <ButtonLoading loading={loading}>Сохранить</ButtonLoading>
                  </div>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RestaurantPromocodeDetailPage;
