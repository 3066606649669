import { H4, Image, LI, P, UL } from "../../../../AbstractElements";
import ProductModal from "./ProductModal";
import React, { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Row } from "reactstrap";
import {
  coreDeleteProduct,
  coreUpdateProduct,
} from "../../../../api/core/products";
import { getPhotoThumbnail } from "../../../../Services/media";
import { toast } from "react-toastify";
import Loader from "../../../../Layout/Loader";
import ProductsContext from "./ProductsContext";
import SweetAlert from "sweetalert2";
import { useUserSelector } from "../../../../store/useSelectors";
import ToolTip from "../../../Common/Tooltip";
import ProductGridCard from "./ProductGridCard";

const ProductGrid = () => {
  const layoutColumns = 3;

  const [dataid, setDataid] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const { products, fetchProducts, categories } = useContext(ProductsContext);
  const { selectedRestaurant } = useUserSelector();

  const deleteProduct = (productId) => {
    SweetAlert.fire({
      title: `Вы уверены?`,
      text: "После удаления товар нельзя будет восстановить",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Уверен",
      cancelButtonText: "Назад",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        coreDeleteProduct(productId).then((response) => {
          if (response.status === 200) {
            SweetAlert.fire("Товар успешно удалён!", "", "success");
            fetchProducts();
          }
        });
      }
    });
  };

  const archiveProduct = (product) => {
    SweetAlert.fire({
      title: `Вы уверены?`,
      text: "Товар можно будет восстановить в любой момент",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Уверен",
      cancelButtonText: "Назад",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        coreUpdateProduct(product.id, { is_active: false }).then((response) => {
          if (response.status === 200) {
            SweetAlert.fire("Товар успешно скрыт!", "", "success");
            fetchProducts();
          }
        });
      }
    });
  };

  const recoverProduct = (product) => {
    coreUpdateProduct(product.id, { is_active: true, is_deleted: false }).then(
      (response) => {
        if (response.status === 200) {
          toast.success("Позиция успешно восстановлена");
          fetchProducts();
        }
      }
    );
  };

  const categoriesMap = {};
  products.forEach((e) => {
    if (categoriesMap[e.category_id]) {
      categoriesMap[e.category_id].items.push(e);
    } else {
      let categoryTitle = "";
      categories.forEach((c) => {
        if (c.id === e.category_id) {
          categoryTitle = c.title;
          if (!c.is_active) {
            categoryTitle += " (скрыто)";
          }
        }
      });
      categoriesMap[e.category_id] = {
        title: categoryTitle,
        items: [e],
      };
    }
  });

  if (Object.keys(categoriesMap).length === 0) <Loader />;

  return (
    <Fragment>
      <div className="product-wrapper-grid" id="product-wrapper-grid">
        <Row className="gridRow" id="gridRow">
          {Object.keys(categoriesMap).length !== 0 &&
            categories.map((category) => {
              const categoryId = category.id;
              return (
                <Fragment key={categoryId}>
                  <h3 className={"mb-4"}>{categoriesMap[categoryId]?.title}</h3>
                  {categoriesMap[categoryId]?.items.map((item) => (
                    <div
                      id="gridId"
                      className={`${
                        layoutColumns === 3
                          ? "col-xl-3 col-lg-6 col-sm-6 xl-4 box-col-4"
                          : "col-xl-" + layoutColumns
                      }`}
                      style={{
                        filter:
                          item.is_deleted || !item.is_active
                            ? "opacity(0.35)"
                            : null,
                      }}
                      key={item.id}
                    >
                      <ProductGridCard
                        item={item}
                        selectedRestaurantId={selectedRestaurant.id}
                        archiveProduct={archiveProduct}
                        deleteProduct={deleteProduct}
                        recoverProduct={recoverProduct}
                      />
                    </div>
                  ))}
                </Fragment>
              );
            })}
          {openModal && (
            <ProductModal
              value={openModal}
              setOpenModal={setOpenModal}
              dataid={dataid}
            />
          )}
        </Row>
      </div>
    </Fragment>
  );
};
export default ProductGrid;
