import { H3, LI, UL } from "../../../AbstractElements";
import React, { useState } from "react";
import { useUserSelector } from "../../../store/useSelectors";
import { TARIFF_NAME, TARIFF_PERIOD } from "../../../Constant/tariffs";
import { coreChooseTariff } from "../../../api/core/tariffs";
import { Badge, Button, ListGroup, ListGroupItem, Spinner } from "reactstrap";
import { useUserActions } from "../../../store/useActions";
import { coreGetDetailRestaurant } from "../../../api/core/restaurants";
import SweetAlert from "sweetalert2";
import { MoneyFormat } from "../../../Services/utils";

import drawLocales from "leaflet-draw-locales";

drawLocales("ru");

const TariffCard = ({ tariffData, tariffPeriod }) => {
  const { selectedRestaurant } = useUserSelector();
  const { setSelectedRestaurant } = useUserActions();
  const [loader, setLoader] = useState(false);

  const isCurrent = selectedRestaurant.tariff.name === tariffData.name;

  const generateOptions = () => {
    return {
      [TARIFF_NAME.FREE]: [
        "60 позиций",
        "1 сотрудник",
        "5 рассылок в месяц",
        "Чёрный список",
        "Ссылки для продвижения",
        "Базовая статистика",
      ],
      [TARIFF_NAME.START]: [
        "Все опции тарифа «FREE»",
        "100 позиций",
        "6 сотрудников",
        "15 рассылок в месяц",
        "Расширенная статистика",
        "Ускоренная загрузка меню",
        "Модификаторы/опции товаров",
        "Бонусная система",
        "Фото/видео/голосовые рассылки",
        "Расширенные лимиты",
        "Скрытие логотипа при загрузке",
      ],
      [TARIFF_NAME.PRO]: [
        "Все опции тарифа «START»",
        "300 позиций",
        "10 сотрудников",
        "30 рассылок в месяц",
        "Расширенная статистика",
        "Ускоренная загрузка меню",
        "Модификаторы/опции товаров",
        "Бонусная система",
        "Фото/видео/голосовые рассылки",
        "Расширенные лимиты",
        "Скрытие логотипа при загрузке",
        "Фирменный цвет",
        "Доставка",
        "Онлайн-платежи",
      ],
    }[tariffData.name] || [];
  };

  const chooseTariff = () => {
    SweetAlert.fire({
      title: `Подтвердите действие`,
      html: `Вы хотите перейти на тариф <b>${tariffData.name}</b>?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Да",
      cancelButtonText: "Отмена",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        setLoader(true);
        coreChooseTariff(tariffData.name, tariffPeriod)
          .then((response) => {
            if (response) {
              SweetAlert.fire("Тариф успешно подключен!", "", "success");
              response &&
                coreGetDetailRestaurant(selectedRestaurant.id).then(
                  (response) => response && setSelectedRestaurant(response.data)
                );
            }
          })
          .finally(() => setLoader(false));
      }
    });
  };

  const getCost = (_tariffPeriod) => {
    return tariffData.costs[_tariffPeriod || tariffPeriod];
  };

  const getPeriodTitle = () => {
    return {
      [TARIFF_PERIOD.MONTH]: "/мес",
      [TARIFF_PERIOD.YEAR]: "/год",
    }[tariffPeriod];
  };

  const getStrokePriceElement = () => {
    if (
      tariffPeriod === TARIFF_PERIOD.YEAR &&
      getCost() !== getCost(TARIFF_PERIOD.MONTH) * 12
    ) {
      return (
        <div className="duration">
          <s>{MoneyFormat.format(getCost(TARIFF_PERIOD.MONTH) * 12)}</s>
        </div>
      );
    }
  };

  return (
    <div
      className="pricingtable"
      style={{
        opacity: isCurrent ? 0.5 : 1,
        border: isCurrent ? "3px dashed var(--theme-deafult)" : null,
      }}
    >
      <div className="pricingtable-header">
        <H3 attrH3={{ className: "title" }}>{tariffData.name}</H3>
      </div>

      <div className="price-value">
        {getStrokePriceElement()}
        <span className="amount">{MoneyFormat.format(getCost())}</span>
        <br />

        <span className="duration">{getPeriodTitle()}</span>
      </div>
      <ListGroup className="pricing-content flex-row">
        {generateOptions().map((e, idx) => (
          <ListGroupItem
            key={idx}
            className="border-0"
            style={{ paddingLeft: "25px" }}
          >
            {e}
          </ListGroupItem>
        ))}
      </ListGroup>
      {isCurrent ? null : (
        <div className="pricingtable-signup">
          <Button color="primary" size="lg" onClick={() => chooseTariff()}>
            {loader ? <Spinner size={"sm"} color="light" /> : "Подключить"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default TariffCard;
