import { coreClient, processResponseError } from "./index";

export const coreRestaurantPromocodeCreate = async (data) => {
  try {
    return await coreClient.post(`/restaurants/promocodes/`, data);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreGetRestaurantPromocodes = async (page, filters, size) => {
  const filterQuery = new URLSearchParams({
    page: page || 1,
    size: size || 10,
    ...filters,
  });
  try {
    return await coreClient.get(`/restaurants/promocodes/?${filterQuery}`);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreGetRestaurantPromocodeDetail = async (id) => {
  try {
    return await coreClient.post(`restaurants/promocodes/${id}`);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreRestaurantPromocodeUpdate = async (id, data) => {
  try {
    return await coreClient.patch(`restaurants/promocodes/${id}`, data);
  } catch (error) {
    processResponseError(error);
  }
};
