import { coreClient, processResponseError } from "./index";

export const coreGetMailings = async (page, filters, size) => {
  const filterQuery = new URLSearchParams({
    page: page || 1,
    size: size || 10,
    ...filters,
  });
  try {
    return await coreClient.get(`mailings/?${filterQuery}`);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreCreateMailing = async (data) => {
  try {
    return await coreClient.post(`/mailings/`, data);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreGetMailing = async (id) => {
  try {
    return await coreClient.get(`mailings/${id}`);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreDeleteMailing = async (id) => {
  try {
    return await coreClient.delete(`mailings/${id}`);
  } catch (error) {
    processResponseError(error);
  }
};

export const corePatchMailing = async (id, data) => {
  try {
    return await coreClient.patch(`mailings/${id}`, data);
  } catch (error) {
    processResponseError(error);
  }
};
