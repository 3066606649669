import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LogIn, PlusCircle } from "react-feather";

import { LI, P, UL } from "../../../AbstractElements";
import { useUserSelector } from "../../../store/useSelectors";

import { useUserActions } from "../../../store/useActions";
import { MoneyFormat } from "../../../Services/utils";
import { showChargeBalanceModal } from "../../../Services/balance";
import { FaRegCreditCard } from "react-icons/fa";
import UserAvatar from "../../../Components/Common/UserAvatar/UserAvatar";

const UserHeader = () => {
  const history = useNavigate();

  const { userData } = useUserSelector();
  const { setToken, setIsAuthenticated, setUserData } = useUserActions();
  const isMobile = window.innerWidth < 811;

  const Logout = () => {
    localStorage.removeItem("token");
    setToken(null);
    setIsAuthenticated(false);
    setUserData(null);
    history(`${process.env.PUBLIC_URL}/login`);
  };

  const ChargeBalance = () => {
    showChargeBalanceModal();
  };

  return (
    <li className="profile-nav onhover-dropdown pe-0 py-0">
      <div className="media profile-media">
        <UserAvatar src={userData.photo} id={userData.id} size={35} />
        <div className="media-body">
          <span>{userData.first_name}</span>
          <P attrPara={{ className: "mb-0 font-roboto" }}>
            Баланс: {MoneyFormat.format(userData.balance)}{" "}
            <i className="middle fa fa-angle-down"></i>
          </P>
        </div>
      </div>
      <UL
        attrUL={{ className: "simple-list profile-dropdown onhover-show-div" }}
      >
        {isMobile ? (
          <li className={"mb-3 mobile-only"}>
            <P attrPara={{ className: "mb-0 font-roboto" }}>
              Баланс: {MoneyFormat.format(userData.balance)}{" "}
            </P>
          </li>
        ) : null}

        <LI attrLI={{ onClick: ChargeBalance }}>
          <PlusCircle />
          <span>{`Пополнить баланс`}</span>
        </LI>

        <LI>
          <Link style={{ textTransform: "none" }} to={`/transactions/`}>
            <FaRegCreditCard strokeWidth="20" />
            <span>Транзакции</span>
          </Link>
        </LI>

        <LI attrLI={{ onClick: Logout }}>
          <LogIn />
          <span>{`Выйти`}</span>
        </LI>
      </UL>
    </li>
  );
};

export default UserHeader;
