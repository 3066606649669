import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import HeaderCard from "../../../Common/Component/HeaderCard";
import { useForm } from "react-hook-form";
import {
  coreGetRestaurantBot,
  coreUpdateRestaurantBot,
} from "../../../../api/core/restaurants";
import SweetAlert from "sweetalert2";
import ButtonLoading from "../../../Common/ButtonLoading/ButtonLoading";

const TelegramBot = ({ loading }) => {
  const [bot, setBot] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    coreGetRestaurantBot().then((response) => setBot(response?.data));
  }, []);

  const onSubmit = (data) => {
    coreUpdateRestaurantBot(data).then((response) => {
      if (response.status === 200) {
        setBot(response?.data);
        SweetAlert.fire("Бот успешно обновлён!", "", "success");
      }
    });
  };

  if (!bot) return null;

  return (
    <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <HeaderCard title={"Телеграм-бот"} />
        <CardBody>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Label>Имя бота</Label>
                <Input
                  type={"text"}
                  className="form-control"
                  disabled
                  value={bot.first_name}
                />
              </FormGroup>
            </Col>
            <Col xs={12}>
              <FormGroup>
                <Label>Юзернейм</Label>
                <input
                  type={"text"}
                  className="form-control"
                  value={"@" + bot.username}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col xs={12}>
              <FormGroup>
                <Label>Изменить токен</Label>
                <input
                  type={"text"}
                  className="form-control"
                  {...register("token", { required: true })}
                />
                <div className={"mt-2"}>
                  <span className={"text-muted"}>
                    Вы можете изменить токен бота, если вы сгенерировали новый в
                    @BotFather. При изменении токена, также изменится информация
                    о боте (Имя, юзернейм), можете указать нынешний токен, чтобы
                    обновить её. Токен должен быть от того же бота.
                  </span>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <div className="text-end">
            <ButtonLoading loading={loading}>Сохранить</ButtonLoading>
          </div>
        </CardFooter>
      </Card>
    </Form>
  );
};
export default TelegramBot;
