import { Media } from "reactstrap";
import {
  convertCoreApiDateToLocalString,
  formatPhoneNumber,
} from "../../../../Services/utils";
import React from "react";
import UserAvatar from "../../../Common/UserAvatar/UserAvatar";

const tableColumns = [
  {
    name: "Клиент",
    selector: (row) => (
      <Media className="d-flex gap-3">
        <UserAvatar src={row.user.photo} id={row.user.id} size={30} />
        <Media body className="align-self-center">
          <div>{row.user.first_name}</div>
        </Media>
      </Media>
    ),
    sortable: false,
    center: false,
  },
  {
    name: "Номер телефона",
    selector: (row) => formatPhoneNumber(row.user.phone_number),
    sortable: false,
    center: true,
  },
  {
    name: "Дата регистрации",
    selector: (row) => `${convertCoreApiDateToLocalString(row.created_at)}`,
    sortable: false,
    center: true,
  },
];

export default tableColumns;
