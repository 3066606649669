import React, { Fragment } from "react";
import Breadcrumbs from "../../Common/Breadcrumbs";
import { Container } from "reactstrap";
import TransactionsDataTable from "./TransactionsDataTable";

const Transactions = () => {
  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Список транзакций"
        parent="Транзакции"
        title="Список транзакций"
      />
      <Container fluid={true}>
        <Fragment>
          <TransactionsDataTable />
        </Fragment>
      </Container>
    </Fragment>
  );
};

export default Transactions;
