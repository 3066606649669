import React, { Fragment, useState } from "react";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import { useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { useNavigate } from "react-router";
import { coreCreateCategory } from "../../../../api/core/categories";
import { useUserSelector } from "../../../../store/useSelectors";
import ButtonLoading from "../../../Common/ButtonLoading/ButtonLoading";

const NewCategoryPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { selectedRestaurant } = useUserSelector();

  const onSubmit = (data) => {
    setLoading(true);
    coreCreateCategory(data)
      .then((response) => {
        navigate(
          `${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/categories/`
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <Breadcrumbs
        parent="Меню"
        title="Добавление категории"
        mainTitle="Добавление категории"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Row>
                    <Col xs={12} xl={6}>
                      <FormGroup>
                        <Label>Название</Label>
                        <input
                          className={`form-control ${
                            errors.title ? "is-invalid" : ""
                          }`}
                          type="text"
                          placeholder="🍔 Бургеры"
                          {...register("title", { required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.title &&
                            "Необходимо заполнить название категории"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <div className="text-end">
                    <ButtonLoading loading={loading}>Добавить</ButtonLoading>
                  </div>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default NewCategoryPage;
