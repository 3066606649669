import React, { useEffect, useState } from "react";
import { coreGetTransactions } from "../../../api/core/payments";
import tableColumns from "./table";
import DataTable from "react-data-table-component";
import { Loader } from "react-feather";

const TransactionsDataTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const fetchTransactions = (page) => {
    setLoading(true);
    coreGetTransactions(page, {}, perPage).then((response) => {
      setData(response?.data.items);
      setTotalRows(response?.data.total);
      setLoading(false);
    });
  };

  const handlePageChange = (page) => {
    fetchTransactions(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    coreGetTransactions(page, {}, newPerPage).then((response) => {
      setData(response.data.items);
      setTotalRows(response.data.total);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchTransactions();
  }, []);
  return (
    <div>
      <DataTable
        data={data}
        columns={tableColumns}
        striped={true}
        center={true}
        progressPending={loading}
        progressComponent={<Loader />}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        highlightOnHover
        pointerOnHover
      />
    </div>
  );
};

export default TransactionsDataTable;
