export const TTariffOption = {
  ADVANCED_STATS: "ADVANCED_STATS",
  PRODUCT_OPTIONS: "PRODUCT_OPTIONS",
  DELIVERY: "DELIVERY",
  BASE_LOADER: "BASE_LOADER",
  PRIORITY_PHOTO_LOADING: "PRIORITY_PHOTO_LOADING",
  MAILINGS_TELEGRAM_TYPE: "MAILINGS_TELEGRAM_TYPE",
  BONUSES: "BONUSES",
  ONLINE_PAYMENTS: "ONLINE_PAYMENTS",
  DESIGN: "DESIGN",
};

export const TARIFF_NAME = {
  FREE: "FREE",
  START: "START",
  PRO: "PRO",
};

export const TARIFF_PERIOD = {
  MONTH: "MONTH",
  YEAR: "YEAR",
};
