import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { H5 } from "../../../AbstractElements";
import ReactApexChart from "react-apexcharts";
import {
  orderDeliveryTypeMap,
  orderPaymentMethodMap,
} from "../../../Constant/orders";
import { useUserSelector } from "../../../store/useSelectors";
import { TTariffOption } from "../../../Constant/tariffs";
import RestrictedCard from "../../Common/Card/RestrictedCard";
import {
  getFakeCountByDeliveryTypeStats,
  getFakeCountByPaymentMethodStats,
} from "../../../Services/fakeStats";

export const getData = (series, labels) => ({
  series: series,
  options: {
    labels: labels,
    chart: {
      type: "donut",
      height: 300,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      fontSize: "14px",
      fontFamily: "Rubik, sans-serif",
      fontWeight: 500,
      labels: {
        colors: ["var(--chart-text-color)"],
      },
      markers: {
        width: 6,
        height: 6,
      },
      itemMargin: {
        horizontal: 7,
        vertical: 0,
      },
    },
    stroke: {
      width: 10,
      colors: ["var(--light2)"],
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: "83%",
          labels: {
            show: true,
            name: {
              offsetY: 4,
            },
            total: {
              show: true,
              fontSize: "14px",
              fontFamily: "Rubik, sans-serif",
              fontWeight: 500,
              label: series.reduce((a, b) => a + b, 0),
              formatter: () => "Всего",
            },
          },
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
        },
      },
      hover: {
        filter: {
          type: "none",
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
        },
      },
    },
    colors: ["#54BA4A", "var(--theme-deafult)", "#FFA941"],
    responsive: [
      {
        breakpoint: 1630,
        options: {
          chart: {
            height: 360,
          },
        },
      },
      {
        breakpoint: 1584,
        options: {
          chart: {
            height: 400,
          },
        },
      },
      {
        breakpoint: 1473,
        options: {
          chart: {
            height: 250,
          },
        },
      },
      {
        breakpoint: 1425,
        options: {
          chart: {
            height: 270,
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            height: 320,
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 250,
          },
        },
      },
    ],
  },
});

const CountByDeliveryType = ({ stats }) => {
  const { selectedRestaurant } = useUserSelector();
  const isRestricted =
    !selectedRestaurant.tariff.options[TTariffOption.ADVANCED_STATS];

  const displayStats = isRestricted
    ? getFakeCountByDeliveryTypeStats()
    : stats.orders.count_by_delivery_type;

  const labels = displayStats.map((e) => orderDeliveryTypeMap[e.delivery_type]);
  const series = displayStats.map((e) => e.count);
  const data = getData(series, labels);

  return (
    <RestrictedCard optionName={TTariffOption.ADVANCED_STATS}>
      <CardHeader className="card-no-border">
        <H5>{"Виды доставки"}</H5>
        <span>За последние 30 дней</span>
      </CardHeader>
      <CardBody className="pt-0">
        <div className="monthly-profit">
          <ReactApexChart
            type="donut"
            height={300}
            series={data.series}
            options={data.options}
          />
        </div>
      </CardBody>
    </RestrictedCard>
  );
};

export default CountByDeliveryType;
