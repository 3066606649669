import {
  Button,
  Card,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from "reactstrap";
import React, { useEffect, useRef } from "react";
import { restrictElements } from "./utils";
import { Link } from "react-router-dom";
import { useUserSelector } from "../../../store/useSelectors";
import { FaPlay } from "react-icons/fa";
import { TTariffOption } from "../../../Constant/tariffs";

const RestrictedCard = ({ optionName, isRestricted, ...props }) => {
  const ref = useRef(null);
  const { selectedRestaurant } = useUserSelector();

  if (isRestricted === undefined) {
    isRestricted = !selectedRestaurant.tariff.options[optionName];
  }

  useEffect(() => {
    if (isRestricted) {
      restrictElements(ref);
    }
  }, []);

  if (isRestricted) {
    if (props.className) {
      props.className = props.className + " unavailable-card";
    } else {
      props.className = "unavailable-card";
    }
  }
  return (
    <>
      <Card {...props} innerRef={ref} />
      {isRestricted ? (
        <UncontrolledPopover target={ref} trigger={"hover"} placement={"top"}>
          <PopoverHeader>Недоступно в вашем тарифе</PopoverHeader>
          <PopoverBody>
            <Link to={`/restaurants/${selectedRestaurant.id}/tariffs/`}>
              <Button color="outline-primary" className="w-100">
                К тарифам
              </Button>
            </Link>
          </PopoverBody>
        </UncontrolledPopover>
      ) : null}
    </>
  );
};

export default RestrictedCard;
