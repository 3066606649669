import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  Label,
  Media,
  Row,
} from "reactstrap";
import { useForm } from "react-hook-form";
import HeaderCard from "../../../Common/Component/HeaderCard";
import { useUserSelector } from "../../../../store/useSelectors";
import { TTariffOption } from "../../../../Constant/tariffs";
import RestrictedElement from "../../../Common/Card/RestrictedElement";
import { TOrderDeliveryType } from "../../../../Constant/orders";
import ButtonLoading from "../../../Common/ButtonLoading/ButtonLoading";

const DeliveryTypes = ({ onSubmit, settings, loading }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { selectedRestaurant } = useUserSelector();

  useEffect(() => {
    if (!settings) return;
    setValue("delivery_types", settings.delivery_types);
  }, [settings]);

  return (
    <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <HeaderCard title={"Способы доставки"} />
        <CardBody>
          <Row>
            <Col xs={12}>
              <Media>
                <Label className="col-form-label m-r-10">Самовывоз</Label>
                <Media body className="text-end switch-lg icon-state">
                  <Label className="switch">
                    <input
                      type="checkbox"
                      value={TOrderDeliveryType.PICKUP}
                      {...register("delivery_types", {
                        value: TOrderDeliveryType.PICKUP,
                        required: true,
                      })}
                    />
                    <span className="switch-state" />
                  </Label>
                </Media>
              </Media>
            </Col>
            <Col xs={12}>
              <Media>
                <Label className="col-form-label m-r-10">
                  Доставка к столику
                </Label>
                <Media body className="text-end switch-lg icon-state">
                  <Label className="switch">
                    <input
                      type="checkbox"
                      value={TOrderDeliveryType.TABLE}
                      {...register("delivery_types", {
                        value: TOrderDeliveryType.TABLE,
                        required: true,
                      })}
                    />
                    <span className="switch-state" />
                  </Label>
                </Media>
              </Media>
            </Col>
            <Col xs={12}>
              <RestrictedElement optionName={TTariffOption.DELIVERY}>
                <Media>
                  <Label className="col-form-label m-r-10">
                    Доставка курьером
                  </Label>
                  <Media body className="text-end switch-lg icon-state">
                    <Label className="switch">
                      <input
                        type="checkbox"
                        value={TOrderDeliveryType.DELIVERY}
                        {...register("delivery_types", {
                          value: TOrderDeliveryType.DELIVERY,
                          required: true,
                        })}
                      />
                      <span className="switch-state" />
                    </Label>
                  </Media>
                </Media>
              </RestrictedElement>
            </Col>
            <span style={{ color: "red" }}>
              {errors.delivery_types &&
                "Нужно выбрать минимум один способ доставки"}
            </span>
          </Row>
        </CardBody>
        <CardFooter>
          <div className="text-end">
            <ButtonLoading loading={loading}>Сохранить</ButtonLoading>
          </div>
        </CardFooter>
      </Card>
    </Form>
  );
};

export default DeliveryTypes;
