import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { Breadcrumbs } from "../../../../AbstractElements";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import {
  coreDeleteCategory,
  coreGetDetailCategory,
  coreUpdateCategory,
} from "../../../../api/core/categories";
import Loader from "../../../../Layout/Loader";
import SweetAlert from "sweetalert2";
import ButtonLoading from "../../../Common/ButtonLoading/ButtonLoading";

const UpdateCategoryPage = () => {
  const [loading, setLoading] = useState(false);
  const { categoryId } = useParams();
  const [category, setCategory] = useState(null);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    fetchCategory();
  }, []);

  useEffect(() => {
    if (!category) return;
    Object.keys(category).forEach((key) => {
      setValue(key, category[key]);
    });
  }, [category]);

  const fetchCategory = () => {
    coreGetDetailCategory(categoryId).then((response) => {
      setCategory(response.data);
    });
  };

  const onSubmit = (data) => {
    const patchData = {};
    Object.keys(data).forEach((key) => {
      if (category[key] !== data[key]) {
        patchData[key] = data[key];
      }
    });
    if (Object.keys(patchData).length === 0) return;
    setLoading(true);
    coreUpdateCategory(categoryId, data)
      .then((response) => {
        if (response.status === 200) {
          SweetAlert.fire("Категория успешно обновлена!", "", "success");
          fetchCategory();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteCategory = () => {
    setLoading(true);
    coreDeleteCategory(categoryId)
      .then((response) => {
        if (response.status === 200) {
          SweetAlert.fire("Категория успешно удалена!", "", "success");
          fetchCategory();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const hideCategory = () => {
    setLoading(true);
    coreUpdateCategory(categoryId, { is_active: false })
      .then((response) => {
        if (response.status === 200) {
          SweetAlert.fire(
            "Категория успешно скрыта! Вы сможете восстановить её в любой момент!",
            "",
            "success"
          );
          fetchCategory();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const recoverCategory = () => {
    setLoading(true);
    coreUpdateCategory(categoryId, { is_active: true, is_deleted: false })
      .then((response) => {
        if (response.status === 200) {
          SweetAlert.fire("Категория успешно восстановлена!", "", "success");
          fetchCategory();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!category) return <Loader />;

  return (
    <>
      <Breadcrumbs
        parent="Меню"
        title="Обновление категории"
        mainTitle="Обновление категории"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Row>
                    <Col xs={12} xl={6}>
                      <FormGroup>
                        <Label>Название</Label>
                        <input
                          className={`form-control ${
                            errors.title ? "is-invalid" : ""
                          }`}
                          type="text"
                          placeholder="🍔 Бургеры"
                          {...register("title", { required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.title &&
                            "Необходимо заполнить название категории"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <div className="text-end">
                    {!(category.is_deleted || !category.is_active) ? (
                      <>
                        <ButtonLoading
                          onClick={hideCategory}
                          color="light"
                          loading={loading}
                        >
                          Скрыть
                        </ButtonLoading>
                        <ButtonLoading
                          onClick={deleteCategory}
                          color="danger"
                          loading={loading}
                        >
                          Удалить
                        </ButtonLoading>
                        <ButtonLoading loading={loading}>
                          Сохранить
                        </ButtonLoading>
                      </>
                    ) : (
                      <>
                        <ButtonLoading
                          onClick={recoverCategory}
                          color="light"
                          loading={loading}
                        >
                          Восстановить
                        </ButtonLoading>
                      </>
                    )}
                  </div>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UpdateCategoryPage;
