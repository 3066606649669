import React, { useState } from "react";
import { Info } from "react-feather";
import { Link } from "react-router-dom";
import ToolTip from "../Tooltip";

const InfoButton = ({ link }) => {
  const [creatorAvatarTooltip, setCreatorAvatarTooltip] = useState(false);
  const linkId = `id-info-button-${link.replace(/[^A-Za-z]+/g, "")}`;

  return (
    <>
      <Link
        id={linkId}
        target={"_blank"}
        to={link}
        style={{ height: "24px", cursor: "pointer" }}
      >
        <Info />
      </Link>
      <ToolTip
        attrToolTip={{
          placement: "bottom",
          isOpen: creatorAvatarTooltip,
          target: linkId,
          toggle: () => setCreatorAvatarTooltip(!creatorAvatarTooltip),
        }}
      >
        Нажмите, чтобы открыть документацию по разделу
      </ToolTip>
    </>
  );
};

export default InfoButton;
