import {
  restaurantPromocodeTypeToTextMap,
  TRestaurantPromocodeType,
} from "../../../../Constant/promocodes";
import { convertCoreApiDateToLocalString } from "../../../../Services/utils";

const formattingDate = (value) => {
  if (value) {
    return convertCoreApiDateToLocalString(value);
  } else {
    return "";
  }
};

const tableColumns = [
  {
    name: "Тип",
    selector: (row) =>
      restaurantPromocodeTypeToTextMap[TRestaurantPromocodeType[row.type]],
    sortable: false,
    center: false,
  },
  {
    name: "Код",
    selector: (row) => row.code,
    sortable: false,
    center: false,
  },
  {
    name: "Дата старта",
    selector: (row) => formattingDate(row.valid_from),
    sortable: false,
    center: true,
  },
  {
    name: "Дата окончания",
    selector: (row) => formattingDate(row.valid_to),
    sortable: false,
    center: true,
  },
];

export default tableColumns;
