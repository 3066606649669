export const TMailingStatus = {
  CREATED: "CREATED",
  PROCESSING: "PROCESSING",
  FINISHED: "FINISHED",
  CANCELLED: "CANCELLED",
};

export const TMailingType = {
  BASE: "BASE",
  TELEGRAM: "TELEGRAM",
};

export const TMailingMaps = {
  textStyle: {
    [TMailingStatus.CREATED]: "",
    [TMailingStatus.PROCESSING]: "text-info",
    [TMailingStatus.FINISHED]: "text-success",
    [TMailingStatus.CANCELLED]: "text-danger",
  },
  textContent: {
    [TMailingStatus.CREATED]: "Создана",
    [TMailingStatus.PROCESSING]: "В процессе",
    [TMailingStatus.FINISHED]: "Завершена",
    [TMailingStatus.CANCELLED]: "Отменена",
  },
  textType: {
    [TMailingType.BASE]: "Обычный",
    [TMailingType.TELEGRAM]: <span className={"text-info"}>Расширенный</span>,
  },
};
