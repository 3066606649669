import React, { useEffect, useState } from "react";
import { Breadcrumbs } from "../../../../AbstractElements";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import {
  coreGetCategories,
  coreUpdateCategory,
} from "../../../../api/core/categories";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { useUserSelector } from "../../../../store/useSelectors";
import Loader from "../../../../Layout/Loader";

const CategoriesListPage = () => {
  const [categories, setCategories] = useState(null);
  const navigate = useNavigate();
  const { selectedRestaurant } = useUserSelector();

  useEffect(() => {
    coreGetCategories().then((response) => {
      setCategories(response.data.sort((a, b) => a.position - b.position));
    });
  }, []);

  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;

    coreUpdateCategory(droppedItem.draggableId, {
      position:
        droppedItem.destination.index !== 0
          ? categories[droppedItem.destination.index].position + 1
          : 1,
    }).then((response) => {
      if (response.status === 200) {
        var updatedList = [...categories];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        setCategories(updatedList);
      }
    });
  };

  if (categories === null) {
    return <Loader />;
  }

  return (
    <>
      <Breadcrumbs parent="Меню" title="Категории" mainTitle="Категории" />
      <Container fluid={true}>
        <Row>
          <Col sm={12}>
            <Link
              to={`${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/categories/new`}
            >
              <Card className={"add-card"}>
                <CardBody>
                  <div className={"category-container"}>
                    <Row className={"text-center"}>
                      <Col xs={12}>
                        <FaPlus size={32} className={"bg-primary b-r-7"} />
                      </Col>
                      <Col xs={12} className={"mt-1"}>
                        Добавить категорию
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Link>
            <DragDropContext onDragEnd={handleDrop}>
              <Droppable droppableId="list-container">
                {(provided) => (
                  <div
                    className="list-container"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {categories.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            onClick={() =>
                              navigate(
                                `${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/categories/${item.id}`
                              )
                            }
                            className="category-container card"
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            <div
                              className={"card-body"}
                              style={{
                                filter:
                                  item.is_deleted || !item.is_active
                                    ? "opacity(0.35)"
                                    : null,
                              }}
                            >
                              {item.title}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CategoriesListPage;
