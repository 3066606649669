import Config from "../Config/Config";

const BASE_MENU_ITEMS = [
  {
    menutitle: "Основные",
    menucontent: "Выбор ресторана",
    items: [
      {
        path: `${process.env.PUBLIC_URL}/restaurants`,
        icon: "social",
        title: "Рестораны",
        type: "link",
        active: false,
      },
    ],
  },
];

const ADDITIONAL_MENU_ITEMS = [
  {
    menutitle: "Дополнительно",
    menucontent: "Дополнительные опции",
    items: [
      {
        path: Config.DOCS_URL,
        icon: "knowledgebase",
        title: "База знаний",
        type: "link",
        active: false,
        external: true,
      },
      {
        path: Config.TELEGRAM_SUPPORT_URL,
        icon: "chat",
        title: "Техподдержка",
        type: "link",
        active: false,
        external: true,
      },
    ],
  },
];

const getRestaurantMenuItems = (restaurantId) => {
  return [
    {
      menutitle: "Ресторан",
      menucontent: "Menu, settings, etc",
      onlyForSelectedRestaurant: true,
      items: [
        {
          title: "Дашборд",
          icon: "home",
          type: "link",
          path: `${process.env.PUBLIC_URL}/restaurants/${restaurantId}/dashboard`,
          active: false,
        },
        {
          title: "Заказы",
          icon: "sample-page",
          type: "sub",
          active: false,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/restaurants/${restaurantId}/orders`,
              title: "Список заказов",
              type: "link",
              active: false,
            },
            {
              path: `${process.env.PUBLIC_URL}/restaurants/${restaurantId}/orders-manager`,
              title: "Менеджер заказов",
              type: "link",
              active: false,
            },
          ],
        },
        {
          title: "Меню",
          icon: "ecommerce",
          type: "sub",
          active: false,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/restaurants/${restaurantId}/products`,
              title: "Товары",
              type: "link",
              active: false,
            },

            {
              path: `${process.env.PUBLIC_URL}/restaurants/${restaurantId}/categories`,
              title: "Категории",
              type: "link",
              active: false,
            },
            {
              path: `${process.env.PUBLIC_URL}/restaurants/${restaurantId}/options`,
              title: "Модификаторы",
              type: "link",
              active: false,
            },
          ],
        },
        {
          title: "Клиенты",
          icon: "user",
          type: "link",
          path: `${process.env.PUBLIC_URL}/restaurants/${restaurantId}/users`,
          active: false,
        },
        {
          title: "Рассылки",
          icon: "button",
          type: "link",
          path: `${process.env.PUBLIC_URL}/restaurants/${restaurantId}/mailings`,
          active: false,
        },
        {
          title: "Настройки",
          icon: "file",
          type: "sub",
          active: false,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/restaurants/${restaurantId}/settings`,
              title: "Общие",
              type: "link",
              active: false,
            },
            {
              path: `${process.env.PUBLIC_URL}/restaurants/${restaurantId}/settings/delivery`,
              title: "Доставка",
              type: "link",
              active: false,
            },
            {
              path: `${process.env.PUBLIC_URL}/restaurants/${restaurantId}/settings/payments`,
              title: "Онлайн-платежи",
              type: "link",
              active: false,
            },
            {
              path: `${process.env.PUBLIC_URL}/restaurants/${restaurantId}/settings/bonuses`,
              title: "Бонусная система",
              type: "link",
              active: false,
            },
            {
              path: `${process.env.PUBLIC_URL}/restaurants/${restaurantId}/settings/staff`,
              title: "Сотрудники",
              type: "link",
              active: false,
            },
            {
              path: `${process.env.PUBLIC_URL}/restaurants/${restaurantId}/settings/design`,
              title: "Оформление",
              type: "link",
              active: false,
            },
            {
              path: `${process.env.PUBLIC_URL}/restaurants/${restaurantId}/settings/promotion`,
              title: "Продвижение",
              type: "link",
              active: false,
            },
            {
              path: `${process.env.PUBLIC_URL}/restaurants/${restaurantId}/settings/promocodes`,
              title: "Промокоды",
              type: "link",
              active: false,
            },
          ],
        },
        {
          title: "Тарифы",
          icon: "widget",
          type: "link",
          path: `${process.env.PUBLIC_URL}/restaurants/${restaurantId}/tariffs`,
          active: false,
        },
      ],
    },
  ];
};

export const getSidebarMenuItems = (restaurantId) => {
  if (!restaurantId) {
    return [...BASE_MENU_ITEMS, ...ADDITIONAL_MENU_ITEMS];
  } else {
    return [
      ...BASE_MENU_ITEMS,
      ...getRestaurantMenuItems(restaurantId),
      ...ADDITIONAL_MENU_ITEMS,
    ];
  }
};
