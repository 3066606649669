import { combineReducers, configureStore } from "@reduxjs/toolkit";
import UserSlice from "./slices/user";
import UISlice from "./slices/ui";

const rootReducer = combineReducers({
  user: UserSlice.reducer,
  ui: UISlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
});
