import React, { useEffect, useState } from "react";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import { Controller, useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Media,
  Row,
} from "reactstrap";
import Select from "react-select";
import { coreGetCategories } from "../../../../api/core/categories";
import { useNavigate, useParams } from "react-router";
import {
  coreGetDetailProduct,
  coreUpdateProduct,
} from "../../../../api/core/products";
import Loader from "../../../../Layout/Loader";
import SweetAlert from "sweetalert2";
import { useUserSelector } from "../../../../store/useSelectors";
import { coreGetOptionGroups } from "../../../../api/core/options";
import { Link } from "react-router-dom";
import { DOCS_URLS } from "../../../../Constant/docs";
import UploadImage from "../../../Common/UploadImage/UploadImage";
import ButtonLoading from "../../../Common/ButtonLoading/ButtonLoading";

const UpdateProductPage = () => {
  const { productId } = useParams();
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [categories, setCategories] = useState([]);
  const [product, setProduct] = useState(null);
  const [photo, setPhoto] = useState(null);
  const navigate = useNavigate();
  const { selectedRestaurant } = useUserSelector();

  const [options, setOptions] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [selectLoader, setSelectLoader] = useState("Загрузка...");
  const [isLoading, setIsLoading] = useState(false);

  const handleSelectClick = () => {
    if (!clicked) {
      fetchOptions();
      setClicked(true);
    }
  };

  const fetchOptions = () => {
    setOptions([]);
    coreGetOptionGroups().then((response) => {
      if (response.status === 200) {
        const arr = [];
        response.data.forEach((item) => {
          const obj = {};
          obj.value = item.id;
          obj.label = `${item.title} ${
            item.title_internal ? `[${item.title_internal}]` : ""
          }`;
          arr.push(obj);
        });
        setOptions(arr);
        setSelectLoader("нет опций для выбора");
      }
    });
  };

  useEffect(() => {
    coreGetDetailProduct(productId).then((response) => {
      if (response.status === 200) {
        setProduct(response.data);
        setPhoto(response.data.photo);
      }
    });
    coreGetCategories().then((response) => {
      if (response.status === 200) {
        setCategories(response.data);
      }
    });
  }, []);

  useEffect(() => {
    if (product) {
      if (product.option_groups) {
        product.option_groups = product.option_groups.map((el) => {
          const currentLabel = `${el.title} ${
            el.title_internal ? `[${el.title_internal}]` : ""
          }`;
          return { value: el.id, label: currentLabel };
        });
      }
      Object.keys(product).forEach((key) => setValue(key, product[key]));
    }
  }, [product]);

  const onSubmit = (data) => {
    const patchData = {};
    if (data.option_groups.length) {
      data.option_groups = data.option_groups.map((el) => {
        return el.value;
      });
    }

    if (photo !== data.photo) {
      data.photo = photo;
    }

    Object.keys(data).forEach((key) => {
      if (product[key] !== data[key]) {
        patchData[key] = data[key];
      }
    });

    function areArraysEqual(arr1, arr2) {
      arr2 = arr2.map((e) => e.value);
      if (arr1.length !== arr2.length) {
        return false;
      }
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          return false;
        }
      }
      return true;
    }

    if (areArraysEqual(patchData.option_groups, product.option_groups)) {
      delete patchData.option_groups;
    }

    if (Object.keys(patchData).length === 0) return;

    Object.keys(patchData).forEach((key) => {
      if (patchData[key] === "") {
        patchData[key] = null;
      }
    });

    setIsLoading(true);

    coreUpdateProduct(productId, patchData)
      .then((response) => {
        if (response.status === 200) {
          SweetAlert.fire("Товар успешно обновлён!", "", "success");
          setProduct(response.data);
          setPhoto(response.data.photo);
          setIsLoading(false);
          navigate(
            `${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/products/${productId}`
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const categoryOptions = categories.map((c) => ({
    value: c.id,
    label: c.title,
  }));

  if (!product) return <Loader />;

  return (
    <>
      <Breadcrumbs
        parent="Меню"
        title="Редактирование товара"
        mainTitle="Редактирование товара"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Row>
                    <Col xs={12} xl={6}>
                      <FormGroup>
                        <Label>Название *</Label>
                        <input
                          className={`form-control ${
                            errors.title ? "is-invalid" : ""
                          }`}
                          type="text"
                          name="title"
                          placeholder="Чизбургер"
                          {...register("title", { required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.title && "Необходимо указать название товара"}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12} xl={6}>
                      <FormGroup>
                        <Label>Категория *</Label>
                        <Controller
                          name="category_id"
                          control={control}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              options={categoryOptions}
                              placeholder={"Выбрать..."}
                              value={categoryOptions.find(
                                (c) => c.value === value
                              )}
                              onChange={(val) => onChange(val.value)}
                            />
                          )}
                          rules={{ required: true }}
                        />
                        <span style={{ color: "red" }}>
                          {errors.category_id && "Необходимо выбрать категорию"}
                        </span>
                      </FormGroup>
                    </Col>

                    <Col xs={12} xl={6}>
                      <FormGroup>
                        <Label>{"Цена *"}</Label>
                        <input
                          step={0.01}
                          type={"number"}
                          name={"price"}
                          className={`form-control digits ${
                            errors.price ? "is-invalid" : ""
                          }`}
                          {...register("price", { required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.price && "Необходимо указать цену товара"}
                        </span>
                        <br />
                      </FormGroup>
                    </Col>
                    <Col xs={12} xl={6}>
                      <FormGroup>
                        <Label>{"Цена по скидке"}</Label>
                        <input
                          step={0.01}
                          type={"number"}
                          name={"sale_price"}
                          className="form-control digits"
                          {...register("sale_price")}
                        />
                        <small className="form-text text-muted">
                          Если хотите добавить скидку на товар, то укажите
                          стоимость по скидке. Если скидки нет — оставьте поле
                          пустым. Если у товара есть скидка, то у него будет
                          выделяющийся бейдж в меню, чтобы клиент обратил
                          внимание
                        </small>
                      </FormGroup>
                    </Col>

                    <Col xs={12}>
                      <FormGroup>
                        <Label>{"Описание"}</Label>
                        <textarea
                          className="form-control"
                          name="description"
                          rows="3"
                          {...register("description")}
                        />
                        <small className="form-text text-muted">
                          В описании можно рассказать про товар или указать
                          состав и граммовки
                        </small>
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup>
                        <Label>{"Позиция"}</Label>
                        <input
                          className="form-control"
                          name="position"
                          type={"number"}
                          {...register("position", { required: true })}
                        />
                        <small className="form-text text-muted">
                          Вы можете изменить позицию товара в категории
                        </small>
                      </FormGroup>
                    </Col>
                    <Col xl={4} className={"mt-3 mb-3"}>
                      <Media>
                        <Label className="col-form-label m-r-10">
                          Популярный товар
                        </Label>
                        <Media body className="text-end switch-lg icon-state">
                          <Label className="switch">
                            <input
                              type="checkbox"
                              {...register("is_featured")}
                            />
                            <span className="switch-state" />
                          </Label>
                        </Media>
                      </Media>
                      <small className="form-text text-muted">
                        У популярных товаров отображается бейдж, чтобы клиент
                        обратил внимание на данный товар
                      </small>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4} className={"mt-3 mb-3 w-100"}>
                      <FormGroup>
                        <Label>Набор модификаторов</Label>
                        <Controller
                          name="option_groups"
                          control={control}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              placeholder="Выберите"
                              options={options}
                              className="basic-multi-select col-sm-12"
                              isMulti
                              name="option_groups"
                              value={value}
                              onChange={(selectedOption) =>
                                onChange(selectedOption)
                              }
                              noOptionsMessage={() => selectLoader}
                              onMenuOpen={() => {
                                handleSelectClick();
                              }}
                            />
                          )}
                        />
                        <small className="form-text text-muted">
                          <Link
                            to={DOCS_URLS.MENU_MODIFICATORS}
                            target={"_blank"}
                          >
                            Что такое модификаторы?
                          </Link>
                        </small>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <div className="text-end">
                    <ButtonLoading loading={isLoading}>Сохранить</ButtonLoading>
                  </div>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Фото</h5>
              </CardHeader>
              <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                <UploadImage
                  isLoading={isLoading}
                  photo={photo}
                  setPhoto={setPhoto}
                />
                <CardFooter>
                  <div className="text-end">
                    <ButtonLoading loading={isLoading}>Сохранить</ButtonLoading>
                  </div>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UpdateProductPage;
