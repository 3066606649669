import { coreClient, processResponseError } from "./index";

export const coreGetTariffs = async () => {
  try {
    return await coreClient.get("/tariffs/");
  } catch (error) {
    processResponseError(error);
  }
};

export const coreChooseTariff = async (tariffName, tariffPeriod) => {
  try {
    return await coreClient.post("/tariffs/choose", {
      tariff_name: tariffName,
      tariff_period: tariffPeriod,
    });
  } catch (error) {
    processResponseError(error);
  }
};
