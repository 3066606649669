import React, { useEffect, useState } from "react";
import { Breadcrumbs } from "../../../../AbstractElements";
import {
  Button,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { useForm } from "react-hook-form";
import {
  coreGetRestaurantDeliveryAreas,
  coreUpdateRestaurantDeliveryAreas,
} from "../../../../api/core/restaurants";
import Loader from "../../../../Layout/Loader";
import { MapContainer, TileLayer } from "react-leaflet";
import EditControlFC from "./EditControlFC";
import { useUserSelector } from "../../../../store/useSelectors";
import { TTariffOption } from "../../../../Constant/tariffs";
import RestrictedCard from "../../../Common/Card/RestrictedCard";
import SweetAlert from "sweetalert2";
import ButtonLoading from "../../../Common/ButtonLoading/ButtonLoading";

const data = {
  type: "FeatureCollection",
  features: [],
};

const DeliverySettings = () => {
  const [loading, setLoading] = useState(false);
  const [map, setMap] = useState(null);
  const [geojson, setGeojson] = useState(null);
  const { register, handleSubmit, setValue } = useForm({});

  const { selectedRestaurant } = useUserSelector();
  const isRestricted =
    !selectedRestaurant?.tariff.options[TTariffOption.DELIVERY];
  let restrictedMapProps = {};
  if (isRestricted) {
    restrictedMapProps = {
      zoomControl: false,
      scrollWheelZoom: false,
      doubleClickZoom: false,
      touchZoom: false,
      boxZoom: false,
      dragging: false,
    };
  }

  useEffect(() => {
    fetchApi();
  }, []);

  useEffect(() => {
    if (!map) return;
    const comp = document.querySelector(".leaflet-attribution-flag");
    if (comp) {
      comp.remove();
    }

    // Костыль, чтобы добавить свои кнопки
    const elementsToHide = [
      "a.leaflet-draw-draw-polygon",
      "a.leaflet-draw-edit-edit",
      "a.leaflet-draw-edit-remove",
    ];
    elementsToHide.forEach((e) => {
      const elem = document.querySelector(e);
      if (elem) {
        elem.style.display = "none";
      }
    });
  }, [map]);

  const fetchApi = () => {
    coreGetRestaurantDeliveryAreas().then((response) => {
      if (response.status === 200) {
        setData(response.data);
      }
    });
  };

  const setData = (apiData) => {
    const data = {
      type: "FeatureCollection",
      features: apiData.map((e) => ({
        type: "Feature",
        geometry: e.area,
        properties: {
          id: e.id,
          name: e.name,
          cost: e.cost,
          minimal_cost: e.minimal_cost,
        },
      })),
    };
    setGeojson(data);
  };

  const onSubmit = (data) => {
    let patchData = Object.keys(data).map((key) => {
      const area = geojson.features.filter((e) => e.properties.name === key)[0]
        ?.geometry;
      if (!area) return null;
      return {
        id: data[key].id,
        name: key,
        cost: data[key].cost,
        minimal_cost: data[key].minimal_cost,
        area: area,
      };
    });
    patchData = patchData.filter((e) => e);

    setLoading(true);
    coreUpdateRestaurantDeliveryAreas(patchData)
      .then((response) => {
        if (response.status === 200) {
          setData(response.data);
          SweetAlert.fire(
            "Информация о зонах доставки успешно обновлена!",
            "",
            "success"
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!geojson) return <Loader />;

  return (
    <>
      <Breadcrumbs
        mainTitle="Настройки доставки"
        title="Доставки"
        parent="Настройки"
      />
      <Container fluid={true}>
        <Row>
          <Col xs={12}>
            <RestrictedCard isRestricted={isRestricted}>
              <CardHeader>
                <Form
                  className={"theme-form"}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {geojson.features.map((f, idx) => (
                    <Row key={idx}>
                      <input
                        style={{ display: "none" }}
                        {...register(`${f.properties.name}.id`, {
                          required: false,
                          valueAsNumber: true,
                          value: f.properties.id,
                        })}
                      />

                      <Col xs={12} className={"mb-2"}>
                        <h5>{f.properties.name}</h5>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Минимальная стоимость заказа</Label>
                          <input
                            type="number"
                            placeholder=""
                            className={"form-control digits"}
                            {...register(`${f.properties.name}.minimal_cost`, {
                              required: true,
                              value: f.properties.minimal_cost,
                            })}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Стоимость доставки</Label>
                          <input
                            type="number"
                            placeholder=""
                            className={"form-control digits"}
                            {...register(`${f.properties.name}.cost`, {
                              required: true,
                              value: f.properties.cost,
                            })}
                          />
                        </FormGroup>
                      </Col>
                      <hr className={"mt-4 mb-4"} />
                    </Row>
                  ))}
                  <div className={"text-end"}>
                    <ButtonLoading loading={loading}>Сохранить</ButtonLoading>
                  </div>
                </Form>
              </CardHeader>
              <CardBody>
                <div className={"mb-3"}>
                  <Button
                    className={"me-3 mb-2"}
                    color={"success"}
                    outline={true}
                    onClick={() =>
                      document
                        .querySelector("a.leaflet-draw-draw-polygon")
                        .click()
                    }
                  >
                    <i className="icon-plus m-r-5"></i>
                    {`Добавить район`}
                  </Button>
                  <Button
                    className={"me-3 mb-2"}
                    color={"info"}
                    outline={true}
                    onClick={() =>
                      document.querySelector("a.leaflet-draw-edit-edit").click()
                    }
                  >
                    <i className="icon-pencil m-r-5"></i>
                    {`Редактировать`}
                  </Button>
                  <Button
                    className={"me-3 mb-2"}
                    color={"danger"}
                    outline={true}
                    onClick={() =>
                      document
                        .querySelector("a.leaflet-draw-edit-remove")
                        .click()
                    }
                  >
                    <i className="icon-trash m-r-5"></i>
                    {`Удалить`}
                  </Button>
                </div>
                <MapContainer
                  center={[59.891115, 30.344109]}
                  style={{
                    width: "100%",
                    height: "70vh",
                    borderRadius: "15px",
                  }}
                  zoom={11}
                  ref={setMap}
                  {...restrictedMapProps}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />

                  {isRestricted ? null : (
                    <EditControlFC geojson={geojson} setGeojson={setGeojson} />
                  )}
                </MapContainer>
              </CardBody>
            </RestrictedCard>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DeliverySettings;
