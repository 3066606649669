import React, { useEffect } from "react";
import L from "leaflet";
import SweetAlert from "sweetalert2";
import { FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { MoneyFormat } from "../../../../Services/utils";

const EditControlFC = ({ geojson, setGeojson }) => {
  const ref = React.useRef(null);

  useEffect(() => {
    rerenderLayers();
  }, [geojson]);

  const rerenderLayers = () => {
    ref.current?.clearLayers();
    if (geojson) {
      L.geoJSON(geojson).eachLayer((layer) => {
        layer.bindTooltip(
          L.tooltip().setContent(
            `
                            <b>${layer.feature.properties.name}</b>
                            <br />
                            Стоимость доставки: ${MoneyFormat.format(
                              layer.feature.properties.cost
                            )}
                            <br />
                            Минимальная стоимость заказа: ${MoneyFormat.format(
                              layer.feature.properties.minimal_cost
                            )}
                    </div>
                    `
          )
        );
        ref.current?.addLayer(layer);
      });
    }
  };

  const handleChange = (e) => {
    const geo = ref.current?.toGeoJSON();
    if (e.type === "draw:created") {
      SweetAlert.fire({
        title: "Введите данные выделенного района",
        html: `<input type="text" id="name" class="swal2-input" placeholder="Название...">
                      <input type="text" id="minimal_cost" class="swal2-input" placeholder="Минимальная стоимость">
                       <input type="text" id="cost" class="swal2-input" placeholder="Стоимость доставки">`,
        confirmButtonText: "Добавить",
        focusConfirm: false,
        preConfirm: () => {
          const name = SweetAlert.getPopup().querySelector("#name").value;
          const minimalCost =
            SweetAlert.getPopup().querySelector("#minimal_cost").value;
          const cost = SweetAlert.getPopup().querySelector("#cost").value;
          if (!name || !minimalCost || !cost) {
            SweetAlert.showValidationMessage(`Нужно заполнить все поля`);
          }
          return { name: name, minimal_cost: minimalCost, cost: cost };
        },
      }).then((result) => {
        if (!result.value) {
          rerenderLayers();
          return;
        }
        geo.features.forEach((f) => {
          if (Object.keys(f.properties).length === 0) {
            f.properties = result.value;
          }
        });
        setGeojson(geo);
        SweetAlert.fire(
          "Для того, чтобы сохранить изменения — нажмите зелёную кнопку «Сохранить»",
          "",
          "info"
        );
      });
    } else {
      setGeojson(geo);
      SweetAlert.fire(
        "Для того, чтобы сохранить изменения — нажмите зелёную кнопку «Сохранить»",
        "",
        "info"
      );
    }
  };

  return (
    <FeatureGroup ref={ref}>
      <EditControl
        position="topright"
        onEdited={handleChange}
        onCreated={handleChange}
        onDeleted={handleChange}
        draw={{
          rectangle: false,
          circle: false,
          polyline: false,
          polygon: true,
          marker: false,
          circlemarker: false,
        }}
      />
    </FeatureGroup>
  );
};

export default EditControlFC;
