import { H6 } from "../../../../AbstractElements";
import FilterContext from "../../../../_helper/Ecommerce/Filter";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import React, { Fragment, useContext, useState } from "react";
import ProductsContext from "./ProductsContext";
import ProductFilterRadio from "./Filters/ProductFilterRadio";

const ProductSidebar = () => {
  const { filterContext } = useContext(FilterContext);
  const [sidebaron, setSidebaron] = useState(true);

  const { filters, setFilters, fetchProducts } = useContext(ProductsContext);

  const onClickFilter = () => {
    if (sidebaron) {
      setSidebaron(false);
      document.getElementById("product-wrapper").classList.add("sidebaron");
    } else {
      setSidebaron(true);
      document.getElementById("product-wrapper").classList.remove("sidebaron");
    }
  };
  const handleSearchKeyword = (keyword) => {
    setFilters((prev) => ({ ...prev, title: keyword }));
  };

  return (
    <Fragment>
      <Row className={"product-wrapper container-fluid"}>
        <Col xl="3" md="6">
          <div className={`product-sidebar ${filterContext ? "open" : ""}`}>
            <div className="filter-section">
              <Card>
                <CardHeader>
                  <H6 attrH6={{ className: "mb-0 f-w-600" }}>
                    Фильтры
                    <span className="pull-right">
                      <i
                        className="fa fa-chevron-down toggle-data"
                        onClick={onClickFilter}
                      ></i>
                    </span>
                  </H6>
                </CardHeader>
                <div className={`left-filter`}>
                  <CardBody className="filter-cards-view animate-chk">
                    <ProductFilterRadio />
                  </CardBody>
                </div>
              </Card>
            </div>
          </div>
        </Col>
        <Col xl="9" md="6">
          <div className="product-search">
            <Form>
              <FormGroup className="m-0 form-group search-product">
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Поиск..."
                  defaultValue={filters.title || ""}
                  onChange={(e) => handleSearchKeyword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      fetchProducts();
                    }
                  }}
                />
                <i className="fa fa-search"></i>
              </FormGroup>
            </Form>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};
export default ProductSidebar;
