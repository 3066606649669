import { coreClient, processResponseError } from "./";

export const coreUserLogin = async ({ phoneNumber, password }) => {
  try {
    return await coreClient.post("users/login", {
      phone_number: phoneNumber,
      password: password,
    });
  } catch (error) {
    processResponseError(error);
  }
};
export const coreGetMe = async () => {
  return await coreClient.get("users/me");
};

export const coreGetRestaurantUsers = async (page, filters, size) => {
  const filterQuery = new URLSearchParams({
    page: page || 1,
    size: size || 1,
    ...filters,
  });
  try {
    return await coreClient.get(`restaurants/users/?${filterQuery}`);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreGetRestaurantDetailUser = async (restaurantUserId) => {
  try {
    return await coreClient.get(`restaurants/users/${restaurantUserId}`);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreCreateUserBlock = async (id, data) => {
  try {
    return await coreClient.post(`restaurants/users/${id}/blocks`, data);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreGetUserBlockList = async (id, page, size) => {
  const filterQuery = new URLSearchParams({
    page: page || 1,
    size: size || 10,
  });
  try {
    return await coreClient.get(
      `restaurants/users/${id}/blocks?${filterQuery}`
    );
  } catch (error) {
    processResponseError(error);
  }
};

export const coreDeleteUserBlocks = async (id) => {
  try {
    return await coreClient.delete(`restaurants/users/${id}/blocks`);
  } catch (error) {
    processResponseError(error);
  }
};
