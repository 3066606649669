import { coreClient, processResponseError } from "./";

export const coreGetOrders = async (page, filters, size) => {
  const filterQuery = new URLSearchParams({
    page: page || 1,
    size: size || 50,
    ...filters,
  });

  try {
    return await coreClient.get(`orders/?${filterQuery}`);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreGetDetailOrder = async (orderId) => {
  try {
    return await coreClient.get(`orders/${orderId}`);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreUpdateOrder = async (orderId, data) => {
  try {
    return await coreClient.patch(`orders/${orderId}`, data);
  } catch (error) {
    processResponseError(error);
  }
};
