import { coreClient, processResponseError } from "./index";

export const coreCreateOptionGroup = async (data) => {
  try {
    return await coreClient.post(`products/option-groups/`, data);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreGetOptionGroups = async () => {
  try {
    return await coreClient.get(`products/option-groups/`);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreUpdateOptionGroup = async (id, data) => {
  try {
    return await coreClient.patch(`products/option-groups/${id}`, data);
  } catch (error) {
    processResponseError(error);
  }
};
export const coreDeleteGroupOptions = async (data, categoryId) => {
  try {
    return await coreClient.delete(`products/option-groups/${categoryId}`);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreDeleteOption = async (id) => {
  try {
    return await coreClient.delete(`products/options/${id}`);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreUpdateOption = async (id, data) => {
  try {
    return await coreClient.patch(`products/options/${id}`, data);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreCreateOption = async (data) => {
  try {
    return await coreClient.post(`products/options/`, data);
  } catch (error) {
    processResponseError(error);
  }
};
