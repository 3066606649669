import React, { useEffect, useRef } from "react";
import { restrictElements } from "./utils";
import {
  Button,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useUserSelector } from "../../../store/useSelectors";

const RestrictedElement = ({
  optionName,
  isRestricted,
  children,
  ...props
}) => {
  const ref = useRef(null);
  const { selectedRestaurant } = useUserSelector();

  if (isRestricted === undefined) {
    isRestricted = !selectedRestaurant.tariff.options[optionName];
  }

  useEffect(() => {
    if (isRestricted) {
      restrictElements(ref);
    }
  }, []);
  if (!isRestricted) {
    return children;
  }

  return (
    <>
      <div className={"unavailable-element"} children={children} ref={ref} />
      {isRestricted ? (
        <UncontrolledPopover target={ref} trigger={"hover"} placement={"top"}>
          <PopoverHeader>Недоступно в вашем тарифе</PopoverHeader>
          <PopoverBody>
            <Link to={`/restaurants/${selectedRestaurant.id}/tariffs/`}>
              <Button color="outline-primary" className="w-100">
                К тарифам
              </Button>
            </Link>
          </PopoverBody>
        </UncontrolledPopover>
      ) : null}
    </>
  );
};

export default RestrictedElement;
