import axios from "axios";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { showChargeBalanceModal } from "../../Services/balance";
import Config from "../../Config/Config";
import { CORE_API_ERROR_CODE } from "../../Constant/coreApi";

export const coreClient = axios.create({
  baseURL: `${Config.CORE_URL}/api/v1/public/`,
});

export const processResponseError = (error) => {
  if (error.__CANCEL__) {
    return;
  }

  let errorText = "Произошла неизвестная ошибка";

  if (error.response && error.response.data) {
    if (
      typeof error.response.data.detail === "string" &&
      error.response.data.code
    ) {
      errorText = error.response.data.detail;
    }

    if (error.response.data.code === CORE_API_ERROR_CODE.NOT_ENOUGH_MONEY) {
      SweetAlert.fire({
        title: `Не хватает средств`,
        text: `Хотите пополнить баланс?`,
        icon: "warning",
        confirmButtonText: "Пополнить баланс",
        reverseButtons: true,
        showCloseButton: true,
      }).then((result) => {
        if (result.value) {
          showChargeBalanceModal(error.response.data?.meta?.amount);
        }
      });
      return;
    }

    if (
      error.response.data.code === CORE_API_ERROR_CODE.TARIFF_LIMIT_EXCEEDED ||
      error.response.data.code === CORE_API_ERROR_CODE.TARIFF_ERROR
    ) {
      SweetAlert.fire({
        title: {
          [CORE_API_ERROR_CODE.TARIFF_LIMIT_EXCEEDED]: "Исчерпан лимит тарифа",
          [CORE_API_ERROR_CODE.TARIFF_ERROR]: "Недоступно в тарифе",
        }[error.response.data.code],
        text: error.response.data.detail || "",
        icon: "warning",
        confirmButtonText: "Сравнение тарифов",
      }).then((result) => {
        if (result.isConfirmed) {
          window.open("https://cafebotum.com/pricing");
        }
      });
      return;
    }
  }

  toast.error(errorText, { toastId: errorText });
};
