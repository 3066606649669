import React from "react";
import ApexChart from "react-apexcharts";

const DashboardChart1 = ({ x, y, yName, color }) => {
  const chartData = {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        height: 200,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 3,
        curve: "smooth",
      },
      xaxis: {
        show: false,
        type: "datetime",
        categories: x,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      grid: {
        show: false,
        padding: {
          left: -10,
          top: -25,
          right: -60,
          bottom: -40,
        },
      },
      fill: {
        opacity: 0.2,
      },
      colors: [color],

      tooltip: {
        fixed: {
          enabled: true,
        },
        x: {
          format: "dd.MM.yyyy",
        },
      },
      responsive: [
        {
          breakpoint: 576,
          options: {
            chart: {
              height: 100,
            },
          },
        },
      ],
    },
    series: [
      {
        name: yName,
        data: y,
      },
    ],
  };

  return (
    <ApexChart
      options={chartData.options}
      series={chartData.series}
      height="170"
      type="area"
    />
  );
};

export default DashboardChart1;
