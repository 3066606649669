import { Breadcrumbs } from "../../../../AbstractElements";
import ProductGrid from "./ProductGrid";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import FilterProvider from "../../../../_helper/Ecommerce/Filter/FilterProvider";
import { coreGetProducts } from "../../../../api/core/products";
import ProductFeatures from "./ProductFeatures";
import ProductsContext from "./ProductsContext";
import { coreGetCategories } from "../../../../api/core/categories";
import Loader from "../../../../Layout/Loader";
import { FaPlus } from "react-icons/fa";
import { useUserSelector } from "../../../../store/useSelectors";

const ProductsPage = () => {
  const [products, setProducts] = useState(null);
  const [categories, setCategories] = useState(null);
  const [filters, setFilters] = useState({});
  const { selectedRestaurant } = useUserSelector();

  useEffect(() => {
    fetchProducts();
    fetchCategories();
  }, []);

  const fetchProducts = () => {
    setProducts(null);
    coreGetProducts(filters).then((response) => {
      if (response.status === 200) {
        setProducts(response.data);
      }
    });
  };

  const onClickFilterButton = () => {
    fetchProducts();
  };

  const fetchCategories = () => {
    coreGetCategories().then((response) => {
      if (response.status === 200) {
        setCategories(response.data.sort((a, b) => a.position - b.position));
      }
    });
  };

  if (!(categories && products)) return <Loader />;

  return (
    <Fragment>
      <Breadcrumbs parent="Меню" title="Товары" mainTitle="Товары" />
      <Container fluid={true} className="product-wrapper" id="product-wrapper">
        <Col xs={12} className={"mb-3"}>
          <Link
            to={`${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/products/new`}
          >
            <Card className="add-card h-100">
              <CardBody className={""}>
                <Row className={"text-center"}>
                  <Col xs={12}>
                    <FaPlus size={32} className={"bg-primary b-r-7"} />
                  </Col>
                  <Col xs={12} className={"mt-1"}>
                    Добавить товар
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Link>
        </Col>
        <Row className={"justify-content-end"}>
          {Object.keys(filters).length === 0 ? null : (
            <Col xs={12} className="mb-4">
              <Button
                className={"w-100"}
                outline
                color={"info"}
                onClick={onClickFilterButton}
              >
                Отфильтровать
              </Button>
            </Col>
          )}
        </Row>
        <div className="product-grid">
          <FilterProvider>
            <ProductsContext.Provider
              value={{
                products,
                filters,
                categories,

                setFilters: setFilters,
                fetchProducts: fetchProducts,
              }}
            >
              <ProductFeatures />
              <ProductGrid />
            </ProductsContext.Provider>
          </FilterProvider>
        </div>
      </Container>
    </Fragment>
  );
};
export default ProductsPage;
