import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import HeaderCard from "../../../Common/Component/HeaderCard";
import { useForm } from "react-hook-form";
import ButtonLoading from "../../../Common/ButtonLoading/ButtonLoading";

// Поддерживаемые поля
const KEYS = ["telegram_notification_chat_id"];

const NotificationSettingsCard = ({ onSubmit, settings, loading }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!settings) return;

    Object.keys(settings)
      .filter((key) => KEYS.includes(key))
      .forEach((key) => {
        setValue(key, settings[key]);
      });
  }, [settings]);

  if (!settings) return null;

  return (
    <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <HeaderCard title={"Уведомления"} />
        <CardBody>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Label>Идентификатор Телеграм-чата для уведомлений</Label>
                <input
                  type={"text"}
                  className="form-control"
                  placeholder={"-862068081"}
                  {...register("telegram_notification_chat_id")}
                />
                <div className={"mt-2"}>
                  <span className={"text-muted"}>
                    Чтобы получать уведомления в Телеграм-чат добавьте бота{" "}
                    <a
                      href={"https://t.me/cafebotum_notification_bot"}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      @cafebotum_notification_bot
                    </a>{" "}
                    в нужный чат, он пришлёт идентификатор чата, вставьте его
                    сюда. Уведомления о новых заказ будут приходить в этот чат.
                  </span>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <div className="text-end">
            <ButtonLoading loading={loading}>Сохранить</ButtonLoading>
          </div>
        </CardFooter>
      </Card>
    </Form>
  );
};
export default NotificationSettingsCard;
