import Config from "../Config/Config";

const DOCS_BASE_URL = Config.DOCS_URL;

export const DOCS_URLS = {
    BOT_CREATE: DOCS_BASE_URL + '/intro/bot-create',

    MENU_MODIFICATORS: DOCS_BASE_URL + '/menu/modificators',

    // Раздел "Сотрудники"
    SETTINGS_STAFF_USERS: DOCS_BASE_URL + "/settings/staff",

    // Раздел "Онлайн-платежи"
    SETTINGS_ONLINE_PAYMENTS: DOCS_BASE_URL + "/settings/payments",

    // Рассылки
    MAILINGS_OVERVIEW: DOCS_BASE_URL + '/mailings/overview',
    MAILINGS_ADVANCED: DOCS_BASE_URL + '/mailings/advanced',
}