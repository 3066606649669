import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Breadcrumbs, H5 } from "../../../../AbstractElements";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import Loader from "../../../../Layout/Loader";
import { coreGetRestaurantDetailUser } from "../../../../api/core/users";
import UserInfoCard from "./UserInfoCard";
import OrderListDataTable from "../../Orders/OrderListDataTable";
import BlockListDataTable from "./UserBlocksTable";

const UserDetailPage = () => {
  const { restaurantUserId } = useParams();
  const [restaurantUser, setRestaurantUser] = useState(null);
  const [isBlocked, setIsBlocked] = useState();

  const getDetailUser = () => {
    coreGetRestaurantDetailUser(restaurantUserId).then((response) => {
      setRestaurantUser(response?.data);
    });
  };

  useEffect(() => {
    getDetailUser();
  }, []);

  if (!restaurantUser) return <Loader />;

  return (
    <>
      <Breadcrumbs
        mainTitle={restaurantUser.user.first_name}
        parent="Клиенты"
        title={restaurantUser.user.first_name}
      />
      <Container fluid>
        <Row>
          <Col xs={12} xl={4}>
            <UserInfoCard
              restaurantUser={restaurantUser}
              restaurantUserId={restaurantUserId}
              isBlocked={isBlocked}
              setIsBlocked={setIsBlocked}
              getDetailUser={getDetailUser}
            />
          </Col>
          <Col xs={12} xl={12}>
            <Card>
              <CardHeader className="card-no-border">
                <div className="header-top">
                  <H5 attrH5={{ className: "m-0" }}>Заказы клиента</H5>
                </div>
              </CardHeader>
              <CardBody>
                <OrderListDataTable
                  baseFilters={{ restaurant_user_id: restaurantUserId }}
                />
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} xl={12}>
            <Card>
              <CardHeader className="card-no-border">
                <div className="header-top">
                  <H5 attrH5={{ className: "m-0" }}>Блокировки клиента</H5>
                </div>
              </CardHeader>
              <CardBody>
                <BlockListDataTable
                  isBlocked={isBlocked}
                  baseFilters={{ restaurant_user_id: restaurantUserId }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserDetailPage;
