import SweetAlert from "sweetalert2";
import { coreGetPaymentLink } from "../api/core/payments";

export const showChargeBalanceModal = (amount) => {
  SweetAlert.fire({
    title: "Введите сумму",
    input: "number",
    showCancelButton: true,
    cancelButtonText: "Назад",
    confirmButtonText: "Пополнить",
    inputValue: amount,
    inputValidator: (value) => {
      return value < 1000
        ? "Минимальная сумма пополнения — 1.000 рублей"
        : null;
    },
  }).then((result) => {
    if (result.value) {
      coreGetPaymentLink(result.value).then((response) => {
        if (response.status === 200) {
          window.location.assign(response.data.url);
        }
      });
    }
  });
};
