import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useUserSelector } from "../../../../store/useSelectors";
import { TTariffOption } from "../../../../Constant/tariffs";
import {
  coreGetDetailRestaurantSettings,
  coreUpdateRestaurantSettings,
} from "../../../../api/core/restaurants";
import Loader from "../../../../Layout/Loader";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import {
  Button,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import RestrictedCard from "../../../Common/Card/RestrictedCard";
import SweetAlert from "sweetalert2";
import ButtonLoading from "../../../Common/ButtonLoading/ButtonLoading";

const KEYS = ["button_color"];

const DesignSettings = () => {
  const [colors, setColors] = useState(null);
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const { selectedRestaurant } = useUserSelector();
  const isRestricted =
    !selectedRestaurant?.tariff.options[TTariffOption.DESIGN];

  useEffect(() => {
    setLoading(true);
    coreGetDetailRestaurantSettings().then((response) => {
      if (response.status === 200) {
        setSettings(response.data);
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    if (!settings) return;

    setColors(settings.colors);
  }, [settings]);

  useEffect(() => {
    if (!colors) return null;
    Object.keys(colors).forEach((key) => {
      setValue(key, colors[key]);
    });
  }, [colors]);

  const onSubmit = (data) => {
    const currentData = {};

    Object.keys(data).forEach((key) => {
      if (data[key] !== colors[key]) {
        currentData[key] = data[key];
      }
    });

    if (Object.keys(currentData).length === 0) return;

    setLoading(true);
    coreUpdateRestaurantSettings({ colors: currentData }).then((response) => {
      if (response.status === 200) {
        setSettings(response.data);
        setLoading(false);
        SweetAlert.fire("Оформление успешно обновлёно!", "", "success");
      }
    });
  };

  const resetColors = () => {
    SweetAlert.fire({
      title: `Вы уверены?`,
      text: "Все цвета сбросятся",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Уверен",
      cancelButtonText: "Назад",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        coreUpdateRestaurantSettings({ colors: {} }).then((response) => {
          if (response.status === 200) {
            setColors(response.data?.colors);
            setLoading(false);
            SweetAlert.fire("Оформление успешно сброшено!", "", "success");
          }
        });
      }
    });
  };

  if (!settings) return <Loader />;

  return (
    <>
      <Breadcrumbs
        mainTitle="Настройки оформления"
        title="Оформление"
        parent="Настройки"
      />
      <Container fluid={true}>
        <Row>
          <Col xs={12}>
            <RestrictedCard isRestricted={isRestricted}>
              <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Row className="justify-content-between gap-2">
                    <Col xs={12} className={"mb-5"}>
                      <FormGroup className="row">
                        <Label className="col-sm-2 col-form-label pt-0">
                          Основной цвет
                        </Label>
                        <Col sm="9">
                          <input
                            className={`form-control-color w-50 ${
                              errors.button_color ? "is-invalid" : ""
                            }`}
                            type="color"
                            name="button_color"
                            defaultValue={colors?.button_color}
                            {...register("button_color")}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <div className="text-end">
                    <ButtonLoading
                      color="danger"
                      onClick={resetColors}
                      loading={loading}
                    >
                      Сбросить
                    </ButtonLoading>
                    <ButtonLoading loading={loading}>Сохранить</ButtonLoading>
                  </div>
                </CardFooter>
              </Form>
            </RestrictedCard>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DesignSettings;
