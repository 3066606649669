import { bindActionCreators } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import UserSlice from "./slices/user";
import UISlice from "./slices/ui";

export const useUserActions = () =>
  bindActionCreators(UserSlice.actions, useDispatch());

export const useUIActions = () =>
  bindActionCreators(UISlice.actions, useDispatch());
