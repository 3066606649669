import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import { useUserSelector } from "../../../store/useSelectors";
import RestaurantCard from "./RestaurantCard";
import { useUserActions } from "../../../store/useActions";
import { useNavigate } from "react-router";
import { coreGetRestaurants } from "../../../api/core/restaurants";
import CreateRestaurantCard from "./CreateRestaurantCard";
import Loader from "../../../Layout/Loader";

const RestaurantsPage = () => {
  const { restaurants } = useUserSelector();
  const { setSelectedRestaurant, setRestaurants } = useUserActions();
  const navigate = useNavigate();

  useEffect(() => {
    fetchRestaurants();
  }, []);

  const fetchRestaurants = () => {
    coreGetRestaurants().then((response) => {
      response && setRestaurants(response.data);
    });
  };

  const selectRestaurant = (restaurant) => {
    setSelectedRestaurant(restaurant);
    navigate(`${process.env.PUBLIC_URL}/${restaurant.id}/dashboard`);
  };

  if (restaurants === null) {
    return <Loader />;
  }

  return (
    <>
      <Breadcrumbs mainTitle="Рестораны" title="Рестораны" />
      <Container fluid={true}>
        <Row>
          {restaurants.map((r, idx) => (
            <Col xs={12} xl={3} key={idx} className={"mb-4"}>
              <RestaurantCard
                restaurant={r}
                fetchRestaurants={fetchRestaurants}
                onClick={() => selectRestaurant(r)}
              />
            </Col>
          ))}
          <Col xs={12} xl={3} className={"mb-5"}>
            <CreateRestaurantCard />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RestaurantsPage;
