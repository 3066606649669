import React from "react";
import Btn from "../Button";
import { FaSpinner } from "react-icons/fa";

const ButtonLoading = ({ loading, children, onClick, color }) => {
  return (
    <Btn
      attrBtn={{
        color: color ? color : "success",
        className: "me-3",
        disabled: loading,
        onClick: onClick,
      }}
    >
      {loading && (
        <div
          style={{ height: "14px", width: "14px" }}
          className="position-absolute top-50 start-50 translate-middle"
        >
          <FaSpinner className={"m-r-5 inherit spinner-animation"} />
        </div>
      )}
      <div style={{ opacity: loading ? "0" : "1" }}>{children}</div>
    </Btn>
  );
};

export default ButtonLoading;
