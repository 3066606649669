import React, { Fragment } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
import { Container } from "reactstrap";
import OrderListDataTable from "./OrderListDataTable";

const OrdersListPage = () => {
  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Список заказов"
        parent="Заказы"
        title="Список заказов"
      />
      <Container fluid={true}>
        <Fragment>
          <OrderListDataTable />
        </Fragment>
      </Container>
    </Fragment>
  );
};
export default OrdersListPage;
