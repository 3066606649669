import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Media,
  Row,
} from "reactstrap";
import { Breadcrumbs, H6 } from "../../../../AbstractElements";
import P from "../../../Common/Paragraph";
import { coreCreateMailing } from "../../../../api/core/mailings";
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router";
import { useUserSelector } from "../../../../store/useSelectors";
import RestrictedCard from "../../../Common/Card/RestrictedCard";
import { TTariffOption } from "../../../../Constant/tariffs";
import { TMailingType } from "../../../../Constant/mailings";
import { isValidJsonObject } from "../../../../Services/utils";
import { Link } from "react-router-dom";
import { DOCS_URLS } from "../../../../Constant/docs";
import ButtonLoading from "../../../Common/ButtonLoading/ButtonLoading";

const CreateMailingPage = () => {
  const {
    register,
    watch,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { selectedRestaurant } = useUserSelector();

  const onSubmit = (data) => {
    if (data.type === TMailingType.BASE) {
      data.data = { text: data.data };
    } else {
      if (isValidJsonObject(data.data)) {
        data.data = JSON.parse(data.data);
      } else {
        setError("data", {
          type: "custom",
        });
        return;
      }
    }

    data.start_at = new Date(data.start_at).toISOString().replace("Z", "");
    setLoading(true);
    coreCreateMailing(data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          SweetAlert.fire("Рассылка создана!", "", "success");
          navigate(
            `${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/mailings`
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {}, [watch()]);

  return (
    <>
      <Breadcrumbs
        parent="Рассылки"
        title="Создание рассылки"
        mainTitle="Создание рассылки"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Row>
                    <Col sm="6">
                      <Card>
                        <Media className="p-20">
                          <div className="radio radio-primary me-3">
                            <input
                              id="radio14"
                              type="radio"
                              name="type"
                              value={TMailingType.BASE}
                              defaultChecked={true}
                              {...register("type", { required: true })}
                            />
                            <Label for="radio14"></Label>
                          </div>
                          <Media body>
                            <H6 attrH6={{ className: "mt-0 mega-title-badge" }}>
                              {"Обычная"}
                            </H6>
                            <P>
                              {
                                "Обычную рассылку очень легко создать, но отправлять можно только текстовые сообщения"
                              }
                            </P>
                          </Media>
                        </Media>
                      </Card>
                    </Col>
                    <Col sm="6">
                      <RestrictedCard
                        optionName={TTariffOption.MAILINGS_TELEGRAM_TYPE}
                      >
                        <Media className="p-20">
                          <div className="radio radio-secondary me-3">
                            <input
                              id="radio13"
                              type="radio"
                              name="type"
                              value={"TELEGRAM"}
                              {...register("type", { required: true })}
                            />
                            <Label for="radio13"></Label>
                          </div>
                          <Media body>
                            <H6 className="mt-0 mega-title-badge">
                              {"Расширенная"}
                            </H6>
                            <P>
                              {
                                "В рассширенной рассылке можно отправлять любые сообщения, поддерживаемые Телеграмом (фото, видео, голосовые, кругляши и тд)"
                              }
                            </P>
                          </Media>
                        </Media>
                      </RestrictedCard>
                    </Col>
                  </Row>
                  <Col xs={12}>
                    <FormGroup>
                      <Label>
                        {getValues("type") === TMailingType.TELEGRAM
                          ? "Данные"
                          : "Текст сообщения"}
                      </Label>
                      <textarea
                        className={`form-control ${
                          errors.data ? "is-invalid" : ""
                        }`}
                        name="data"
                        type={"text"}
                        rows={9}
                        {...register("data", { required: true })}
                      />
                      <span style={{ color: "red" }}>
                        {errors.data && "Введите корректные данные"}
                      </span>
                      {getValues("type") === TMailingType.TELEGRAM && (
                        <small className="form-text text-muted">
                          <Link
                            to={DOCS_URLS.MAILINGS_ADVANCED}
                            target={"_blank"}
                          >
                            Где взять данные?
                          </Link>
                        </small>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <FormGroup>
                      <Label>Дата рассылки</Label>
                      <input
                        className={`form-control ${
                          errors.title ? "is-invalid" : ""
                        }`}
                        type="datetime-local"
                        name="start_at"
                        {...register("start_at", { required: true })}
                      />
                      <span style={{ color: "red" }}>
                        {errors.title && "Необходимо заполнить дату"}
                      </span>
                    </FormGroup>
                  </Col>
                </CardBody>
                <CardFooter>
                  <div className="text-end">
                    <ButtonLoading loading={loading}>Создать</ButtonLoading>
                  </div>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateMailingPage;
