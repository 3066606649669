import { createSlice } from "@reduxjs/toolkit";
import { getSidebarMenuItems } from "../../Route/SidebarMenu";

const UISlice = createSlice({
  name: "ui",
  initialState: {
    sidebarMenu: getSidebarMenuItems(),
  },
  reducers: {
    setSidebarMenu: (state, action) => {
      state.sidebarMenu = action.payload;
    },
  },
});

export default UISlice;
