import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import tableColumns from "./table";
import { coreGetUserBlockList } from "../../../../api/core/users";
import Loader from "../../../../Layout/Loader";
import UserBlocksModal from "./UserBlocksModal";

const UserBlocksTable = ({ baseFilters, isBlocked }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState(null);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const fetchBlocks = (page) => {
    setLoading(true);

    coreGetUserBlockList(baseFilters.restaurant_user_id, page, perPage).then(
      (response) => {
        setData(response.data.items);
        setTotalRows(response.data.total);
        setLoading(false);
      }
    );
  };

  const handlePageChange = (page) => {
    fetchBlocks(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    coreGetUserBlockList(baseFilters.restaurant_user_id, page, newPerPage).then(
      (response) => {
        setData(response.data.items);
        setTotalRows(response.data.total);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchBlocks(1);
  }, [isBlocked]);

  const onRowClicked = (row) => {
    setSelectedRow(row);
    toggle();
  };

  return (
    <>
      <DataTable
        data={data}
        columns={tableColumns}
        striped={true}
        center={true}
        progressPending={loading}
        progressComponent={<Loader />}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onRowClicked={(row) => onRowClicked(row)}
        highlightOnHover
        pointerOnHover
      />
      <UserBlocksModal modal={modal} toggle={toggle} row={selectedRow} />
    </>
  );
};

export default UserBlocksTable;
