import React, { useState } from "react";
import CommonModal from "../../Menu/OptionGroups/Options/OptionsModal";
import {
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import ReactDatePicker from "react-datepicker";
import Btn from "../../../Common/Button";

const UserModal = ({ onBlock, modal, toggle }) => {
  const [selectedDate, setSelectedDate] = useState();

  const handleChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <CommonModal title="Блокировка клиента" isOpen={modal} toggler={toggle}>
      <Form className="theme-form" onSubmit={onBlock}>
        <CardBody>
          <Row>
            <Col xs={12} xl={16}>
              <FormGroup>
                <Label>Причина блокировки</Label>
                <input
                  className="form-control"
                  type="text"
                  name="reason"
                  placeholder=""
                />
                <small className="form-text text-muted">
                  Причина блокировки необязательна, она будет отображаться
                  только вам, чтобы вы в любой момент смогли вспомнить почему
                  заблокировали клиента
                </small>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} xl={16}>
              <FormGroup>
                <Label>Блокировка до</Label>
                <ReactDatePicker
                  name="date"
                  className="form-control digits"
                  dateFormat="Pp"
                  showTimeSelect
                  selected={selectedDate}
                  onChange={handleChange}
                  autocomplete={false}
                />
                <small className="form-text text-muted">
                  Если хотите заблокировать навсегда, то оставьте это поле
                  пустым, тогда клиент не разблокируется автоматически. Вы
                  сможете разблокировать клиента в любой момент вручную
                </small>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <div className="text-end">
            <Btn attrBtn={{ color: "danger", className: "me-3" }}>
              {"Заблокировать"}
            </Btn>
          </div>
        </CardFooter>
      </Form>
    </CommonModal>
  );
};

export default UserModal;
