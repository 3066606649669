import React, { Fragment, useState } from "react";
import { Breadcrumbs } from "../../../../AbstractElements";
import { useForm } from "react-hook-form";
import SweetAlert from "sweetalert2";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { coreCreateOptionGroup } from "../../../../api/core/options";
import { useUserSelector } from "../../../../store/useSelectors";
import { useNavigate } from "react-router";
import ButtonLoading from "../../../Common/ButtonLoading/ButtonLoading";
import RestrictedCard from "../../../Common/Card/RestrictedCard";
import { TTariffOption } from "../../../../Constant/tariffs";

const CreateOptionsGroupPage = () => {
  const [loading, setLoading] = useState(false);
  const { selectedRestaurant } = useUserSelector();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    Object.keys(data).forEach((key) => {
      if (data[key].length < 1) {
        delete data[key];
      }
    });

    setLoading(true);
    coreCreateOptionGroup(data)
      .then((response) => {
        if (response.status === 200) {
          SweetAlert.fire("Добавлен новый набор модификаторов!", "", "success");
          navigate(
            `${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/options`
          );
        } else {
          SweetAlert.fire("Все плохо");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <Breadcrumbs
        parent="Меню"
        title="Добавление модификатора"
        mainTitle="Добавление модификатора"
      />
      <Container fluid={true}>
        <RestrictedCard optionName={TTariffOption.PRODUCT_OPTIONS}>
          <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
            <CardBody>
              <Row>
                <Col xs={12} xl={6}>
                  <FormGroup>
                    <Label>Название *</Label>
                    <input
                      className={`form-control ${
                        errors.title ? "is-invalid" : ""
                      }`}
                      type="text"
                      name="title"
                      placeholder="Соусы"
                      {...register("title", { required: true })}
                    />
                    <span style={{ color: "red" }}>
                      {errors.title &&
                        "Необходимо указать название набора модификаторов"}
                    </span>
                  </FormGroup>
                </Col>
                <Col xs={12} xl={6}>
                  <FormGroup>
                    <Label>Внутреннее название</Label>
                    <input
                      className={`form-control`}
                      type="text"
                      name="title_internal"
                      placeholder="Для бургеров"
                      {...register("title_internal")}
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} xl={6}>
                  <FormGroup>
                    <Label>{"Максимальное количество опций для выбора"}</Label>
                    <input
                      step={1}
                      type={"number"}
                      name={"max_selected"}
                      className={`form-control digits`}
                      {...register("max_selected")}
                    />
                    <br />
                  </FormGroup>
                </Col>
                <Col xs={12} xl={6}>
                  <FormGroup>
                    <Label>{"Минимальное количество опций для выбора"}</Label>
                    <input
                      step={1}
                      type={"number"}
                      name={"min_selected"}
                      className={`form-control digits`}
                      {...register("min_selected")}
                    />
                    <br />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <div className="text-end">
                <ButtonLoading loading={loading}>Добавить</ButtonLoading>
              </div>
            </CardFooter>
          </Form>
        </RestrictedCard>
      </Container>
    </Fragment>
  );
};

export default CreateOptionsGroupPage;
