import { createSlice } from "@reduxjs/toolkit";
import { coreClient } from "../../api/core";

const UserSlice = createSlice({
  name: "user",
  initialState: {
    token: localStorage.getItem("token"),
    isAuthenticated: localStorage.getItem("token") !== undefined,
    userData: null,
    isLoading: false,
    restaurants: null,
    selectedRestaurant: null,
  },
  reducers: {
    setToken: (state, action) => {
      coreClient.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${action.payload}`;
      state.token = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setRestaurants: (state, action) => {
      state.restaurants = action.payload;
    },
    setSelectedRestaurant: (state, action) => {
      if (action.payload) {
          coreClient.defaults.headers.common["X-Restaurant-Id"] = action.payload.id;
          state.selectedRestaurant = action.payload;
      } else {
          delete coreClient.defaults.headers.common["X-Restaurant-Id"];
          state.selectedRestaurant = null;
      }

    },
  },
});

export default UserSlice;
