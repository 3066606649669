import React, { Fragment } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import LoginForm from "../Components/Pages/cuba/Auth/LoginForm";
import { routes } from "./routes";
import AppLayout from "../Layout/Layout";
import Error404 from "../Components/Pages/cuba/ErrorPages/ErrorPage404";
import { useUserSelector } from "../store/useSelectors";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";

const LayoutRoutes = () => {
  const { isAuthenticated } = useUserSelector();
  const location = useLocation();

  registerLocale("ru", ru);
  setDefaultLocale("ru");

  return (
    <Routes>
      {routes.map(({ path, Component }, i) => (
        <Fragment key={i}>
          {isAuthenticated ? (
            <Route element={<AppLayout />} key={i}>
              <Route path={path} element={Component} />
            </Route>
          ) : (
            <Route
              path={path}
              element={
                <Navigate
                  to={`${process.env.PUBLIC_URL}/login?next=${location.pathname}}`}
                />
              }
            />
          )}
        </Fragment>
      ))}
      <Route path={`/*`} element={<Error404 />} />
    </Routes>
  );
};

const Routers = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/`}
          element={<Navigate to={`${process.env.PUBLIC_URL}/restaurants`} />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/login`}
          element={<LoginForm />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/*`}
          element={<LayoutRoutes />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
