import React from "react";
import { Col, Row } from "reactstrap";
import { H4, H6 } from "../../../AbstractElements";
import DashboardChart1 from "./DashboardChart1";
import ConfigDB from "../../../Config/ThemeConfig";
import RestrictedCard from "../../Common/Card/RestrictedCard";
import { TTariffOption } from "../../../Constant/tariffs";
import { useUserSelector } from "../../../store/useSelectors";
import { getFakeUsersCountStats } from "../../../Services/fakeStats";

const UsersCountCard = ({ stats }) => {
  const { selectedRestaurant } = useUserSelector();
  const isRestricted =
    !selectedRestaurant.tariff.options[TTariffOption.ADVANCED_STATS];

  const displayStats = isRestricted ? getFakeUsersCountStats() : stats.users;

  return (
    <RestrictedCard
      optionName={TTariffOption.ADVANCED_STATS}
      className="o-hidden"
    >
      <div className="chart-widget-top">
        <Row className="row card-body pb-0 m-0">
          <Col xl="9" lg="8" className="col-9 p-0">
            <H6 attrH6={{ className: "mb-2" }}>{`Клиенты`}</H6>
            <H4>{displayStats.count_month}</H4>
            <span>За последние 30 дней</span>
          </Col>
        </Row>
        <div>
          <div id="chart-widget1">
            <DashboardChart1
              color={ConfigDB.data.color.primary_color}
              x={displayStats.gross.map((e) => e.date_key + "Z")}
              y={displayStats.gross.map((e) => e.count)}
              yName={"Количество"}
            />
          </div>
        </div>
      </div>
    </RestrictedCard>
  );
};

export default UsersCountCard;
