import React, { Fragment, useContext } from "react";
import ProductSidebar from "./ProductSidebar";
import ProductTotal from "./ProductTotal";
import ProductsContext from "./ProductsContext";

const ProductFeatures = () => {
  return (
    <Fragment>
      <div className="feature-products">
        <ProductSidebar />
      </div>
    </Fragment>
  );
};
export default ProductFeatures;
