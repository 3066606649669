import React from "react";
import { Button } from "reactstrap";
import { coreUpdateOrder } from "../../../../api/core/orders";
import { useNavigate } from "react-router";
import { useUserSelector } from "../../../../store/useSelectors";
import SweetAlert from "sweetalert2";
import { TOrderState } from "../../../../Constant/orders";

const OrderCancelButton = ({ order }) => {
  const navigate = useNavigate();
  const { selectedRestaurant } = useUserSelector();

  const cancelOrder = () => {
    SweetAlert.fire({
      title: `Вы уверены?`,
      text: "После отмены заказ нельзя будет восстановить",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Уверен",
      cancelButtonText: "Назад",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        coreUpdateOrder(order.id, {
          state: TOrderState.CANCELLED,
        }).then((response) => {
          if (response.status === 200) {
            SweetAlert.fire("Заказ успешно отменен!", "", "success");
            navigate(
              `${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/orders-manager/`
            );
          }
        });
      }
    });
  };

  return (
    <Button
      onClick={cancelOrder}
      color="outline-secondary"
      className="w-100"
    >{`Отменить`}</Button>
  );
};

export default OrderCancelButton;
