import { coreClient, processResponseError } from "./index";

export const coreBotStart = async (restaurantId) => {
  try {
    return await coreClient.get(`bots/start`, {
      headers: { "X-Restaurant-Id": restaurantId },
    });
  } catch (error) {
    processResponseError(error);
  }
};

export const coreBotStop = async (restaurantId) => {
  try {
    return await coreClient.get(`bots/stop`, {
      headers: { "X-Restaurant-Id": restaurantId },
    });
  } catch (error) {
    processResponseError(error);
  }
};
