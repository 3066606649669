import { coreClient, processResponseError } from "./index";

export const coreRestaurantUserStaffCreate = async (id) => {
  try {
    return await coreClient.post(`/restaurants/users/staff/`, {
      restaurant_user_id: id,
    });
  } catch (error) {
    processResponseError(error);
  }
};

export const coreRestaurantUserStaffDelete = async (id) => {
  try {
    return await coreClient.delete(`/restaurants/users/staff/${id}`);
  } catch (error) {
    processResponseError(error);
  }
};
