import React, { useEffect, useState } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
import { Col, Container, Row } from "reactstrap";
import { coreGetRestaurantStats } from "../../../api/core/stats";
import Loader from "../../../Layout/Loader";
import TopUsersCard from "./TopUsersCard";
import TopOrdersCard from "./TopOrdersCard";
import OrdersCountCard from "./OrdersCountCard";
import UsersCountCard from "./UsersCountCard";
import OrdersTotalSumCard from "./OrdersTotalSumCard";
import DashboardWidget from "./DashboardWidget";
import { MoneyFormat } from "../../../Services/utils";
import CountByPaymentMethodCard from "./CountByPaymentMethodCard";
import CountByDeliveryType from "./CountByDeliveryType";

const DashboardPage = () => {
  const [stats, setStats] = useState(null);

  useEffect(() => {
    coreGetRestaurantStats().then((response) => {
      if (response.status === 200) {
        setStats(response.data);
      }
    });
  }, []);

  if (!stats) return <Loader />;

  return (
    <>
      <Breadcrumbs title="Дашборд" mainTitle="Дашборд" />
      <Container fluid={true}>
        <Row>
          <Col xl={8}>
            <Row className={"m-0 p-0"}>
              <Row className={"m-0 p-0"}>
                <Col xl={6}>
                  <DashboardWidget
                    data={{
                      title: "Клиенты",
                      color: "primary",
                      total: stats.users.count,
                      icon: "customers",
                    }}
                  />
                </Col>
                <Col xl={6}>
                  <DashboardWidget
                    data={{
                      title: "Заказы",
                      color: "secondary",
                      total: stats.orders.data.count,
                      icon: "orders",
                    }}
                  />
                </Col>
                <Col xl={6}>
                  <DashboardWidget
                    data={{
                      title: "Выручка",
                      color: "success",
                      total: MoneyFormat.format(stats.orders.data.sum),
                      icon: "profit",
                    }}
                  />
                </Col>
                <Col xl={6}>
                  <DashboardWidget
                    data={{
                      title: "Средний чек",
                      color: "warning",
                      total: MoneyFormat.format(stats.orders.data.avg_cost),
                      icon: "sale",
                    }}
                  />
                </Col>
              </Row>
              <Row className={"m-0 p-0"}>
                <Col xl={4}>
                  <UsersCountCard stats={stats} />
                </Col>
                <Col xl={4}>
                  <OrdersCountCard stats={stats} />
                </Col>
                <Col xl={4}>
                  <OrdersTotalSumCard stats={stats} />
                </Col>
              </Row>
              <Row className={"m-0 p-0"}>
                <Col xl={6}>
                  <CountByPaymentMethodCard stats={stats} />
                </Col>
                <Col xl={6}>
                  <CountByDeliveryType stats={stats} />
                </Col>
              </Row>
            </Row>
          </Col>
          <Col xl={4}>
            <Row className={"m-0 p-0"}>
              <Col xl={12}>
                <TopUsersCard stats={stats} />
              </Col>
              <Col xl={12}>
                <TopOrdersCard stats={stats} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DashboardPage;
