import { Breadcrumbs } from "../../../../AbstractElements";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import QRCodeStyling from "qr-code-styling";
import { coreGetRestaurantBot } from "../../../../api/core/restaurants";
import Loader from "../../../../Layout/Loader";
import telegramIcon from "../../../../assets/images/telegram-icon.png";

const telegramBotQrCode = new QRCodeStyling({
  width: 300,
  height: 300,
  image: telegramIcon,
  dotsOptions: {
    color: "#323232",
    type: "rounded",
  },
  cornersSquareOptions: {
    color: "#229ED9",
    type: "extra-rounded",
  },
  cornersDotOptions: {
    color: "#323232",
  },
  backgroundOptions: {
    color: "transparent",
  },
  imageOptions: {
    margin: 10,
  },
});

const Promotion = (props) => {
  const qrCodeContainerRef = useRef(null);
  const [telegramBot, setTelegramBot] = useState(null);

  useEffect(() => {
    if (!telegramBot) {
      coreGetRestaurantBot().then((response) => setTelegramBot(response?.data));
    }
  }, []);

  const getTelegramBotUrl = (telegramBot) =>
    `https://t.me/${telegramBot.username}?start=from_qr`;

  useEffect(() => {
    if (telegramBot) {
      qrCodeContainerRef.current.children[0]?.remove();
      telegramBotQrCode.update({
        data: getTelegramBotUrl(telegramBot),
      });
      telegramBotQrCode.append(qrCodeContainerRef.current);
    }
  }, [telegramBot]);

  if (!telegramBot) return <Loader />;

  return (
    <>
      <Breadcrumbs
        mainTitle="Продвижение"
        title="Продвижение"
        parent="Настройки"
      />
      <Container fluid={true}>
        <Row className={"m-0 p-0"}>
          <Card>
            <CardHeader className={"card-no-border"}>
              <div className={"header-top"}></div>
              <h5>QR-код</h5>
            </CardHeader>
            <CardBody>
              <Row>
                <Col
                  xs={12}
                  md={6}
                  xl={4}
                  className={"align-center mb-3 mb-md-0"}
                >
                  <Row className={"text-center"}>
                    <Col xs={12}>
                      <div ref={qrCodeContainerRef} />
                    </Col>
                    <Col xs={12}>
                      <Button
                        color={"primary"}
                        className={"w-100 mt-3"}
                        outline
                        onClick={() => telegramBotQrCode.download()}
                      >
                        Скачать
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={6} xl={8}>
                  Данный QR-код содержит ссылку на вашего бота{" "}
                  <a href={getTelegramBotUrl(telegramBot)}>
                    @{telegramBot.username}
                  </a>{" "}
                  в приложении Telegram, вы можете разместить его там, где
                  клиенту будет удобно навести на него камеру телефона,
                  например:
                  <hr className="hr" />
                  <ul className={"list-group"} style={{ marginLeft: "16px" }}>
                    <li className={"list-group-item"}>
                      <i className="fa fa-angle-double-right txt-primary me-2"></i>
                      На столике в заведении (+ можно указать номер столика)
                    </li>
                    <li className={"list-group-item"}>
                      <i className="fa fa-angle-double-right txt-primary me-2"></i>
                      У кассы
                    </li>
                    <li className={"list-group-item"}>
                      <i className="fa fa-angle-double-right txt-primary me-2"></i>
                      У входа в заведение
                    </li>
                    <li className={"list-group-item"}>
                      <i className="fa fa-angle-double-right txt-primary me-2"></i>
                      На рекламной брошюре
                    </li>
                  </ul>
                  <hr className="hr" />
                  <div>
                    Мы специально генерируем QR-код с прозрачным фоном, чтобы
                    вам или вашему дизайнеру было удобнее адаптировать его под
                    вашу задачу 🤍
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Row>
      </Container>
    </>
  );
};

export default Promotion;
