import {
  convertCoreApiDateToLocalString,
  MoneyFormat,
} from "../../../Services/utils";
import { TTransactionType } from "../../../Constant/transactions";

const tableColumns = [
  {
    name: "Тип транзакции",
    selector: (row) => `${TTransactionType[row.type]}`,
    sortable: false,
    center: false,
  },
  {
    name: "Дата",
    selector: (row) => `${convertCoreApiDateToLocalString(row.created_at)}`,
    sortable: false,
    center: true,
  },
  {
    name: "Сумма",
    selector: (row) =>
      `${(row.amount > 0 ? "+" : "") + MoneyFormat.format(row.amount)}`,
    sortable: false,
    center: true,
  },
];

export default tableColumns;
