import React, { Fragment, useEffect, useState } from "react";
import { Container } from "reactstrap";
import DataTable from "react-data-table-component";
import tableColumns from "./table";
import { coreGetRestaurantUsers } from "../../../../api/core/users";
import Breadcrumbs from "../../../Common/Breadcrumbs";
import Loader from "../../../../Layout/Loader";
import { useNavigate } from "react-router";
import { useUserSelector } from "../../../../store/useSelectors";
import { DOCS_URLS } from "../../../../Constant/docs";

const RestaurantStaffListPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const navigate = useNavigate();
  const { selectedRestaurant } = useUserSelector();

  const fetchOrders = (page) => {
    setLoading(true);
    coreGetRestaurantUsers(page, { is_staff: true }, perPage).then(
      (response) => {
        setData(response?.data.items);
        setTotalRows(response?.data.total);
        setLoading(false);
      }
    );
  };

  const handlePageChange = (page) => {
    fetchOrders(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    coreGetRestaurantUsers(page, { is_staff: true }, newPerPage).then(
      (response) => {
        setData(response?.data.items);
        setTotalRows(response?.data.total);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchOrders(1);
  }, []);

  return (
    <Fragment>
      <Breadcrumbs
        docsLink={DOCS_URLS.SETTINGS_STAFF_USERS}
        mainTitle="Сотрудники"
        title="Сотрудники"
      />
      <Container fluid={true}>
        <Fragment>
          <DataTable
            data={data}
            columns={tableColumns}
            striped={true}
            center={true}
            progressPending={loading}
            progressComponent={<Loader />}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onRowClicked={(row) =>
              navigate(
                `${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/users/${row.id}`
              )
            }
            highlightOnHover
            pointerOnHover
          />
        </Fragment>
      </Container>
    </Fragment>
  );
};
export default RestaurantStaffListPage;
