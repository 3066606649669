import React from "react";
import { Button, FormGroup, UncontrolledTooltip } from "reactstrap";
import { Btn, Image, LI, UL } from "../../../AbstractElements";
import Files from "react-files";
import { Link } from "react-router-dom";
import SweetAlert from "sweetalert2";

const UploadImage = ({ photo, setPhoto, isLoading }) => {
  const deleteFile = (e) => {
    if (isLoading) return;
    e.preventDefault();
    e.stopPropagation();
    setPhoto(null);
  };

  const onFilesChange = (files) => {
    if (files.length === 0) {
      SweetAlert.fire("Данный формат файла не поддерживается", "", "error");
      return;
    }
    if (isLoading) return;
    setPhoto(files);
  };

  const coreImageMimeTypes = ["image/jpeg", "image/jpg", "image/png"];

  return (
    <FormGroup className="d-flex justify-content-center">
      <Files
        className="files-dropzone fileContainer product-box w-50"
        onChange={onFilesChange}
        accepts={coreImageMimeTypes}
        multiple={false}
        clickable={!isLoading}
      >
        {photo ? (
          <div className="files-gallery mt-3 product-img w-100 ">
            <Image
              attrImage={{
                className: "files-gallery-item rounded w-100",
                alt: "img",
                src: `${photo[0]?.preview ? photo[0]?.preview?.url : photo}`,
                loading: "lazy",
              }}
            />
            <div className="product-hover rounded">
              <>
                {isLoading ? (
                  <div className="loader-box">
                    <div className="loader-4"></div>
                  </div>
                ) : (
                  <UL
                    attrUL={{
                      className: "simple-list d-flex flex-row",
                    }}
                  >
                    <Link>
                      <LI
                        attrLI={{
                          className: "border-0",
                          id: `edit-tooltip`,
                        }}
                      >
                        <Button color="default">
                          <i className="icon-pencil"></i>
                        </Button>
                      </LI>
                      <UncontrolledTooltip
                        placement={"top"}
                        target={`edit-tooltip`}
                      >
                        {"Изменить фото"}
                      </UncontrolledTooltip>
                    </Link>
                    <Link onClick={(e) => deleteFile(e)}>
                      <LI
                        attrLI={{
                          className: "border-0",
                          id: `delete-tooltip`,
                        }}
                      >
                        <Button color="default">
                          <i className="icon-trash"></i>
                        </Button>
                      </LI>
                      <UncontrolledTooltip
                        placement={"top"}
                        target={`delete-tooltip`}
                      >
                        {"Удалить"}
                      </UncontrolledTooltip>
                    </Link>
                  </UL>
                )}
              </>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Btn
              attrBtn={{ className: "mt-3", type: "button", color: "primary" }}
            >
              Загрузить изображение
            </Btn>
          </div>
        )}
      </Files>
    </FormGroup>
  );
};

export default UploadImage;
