import React from "react";
import { TMailingMaps } from "../../../../Constant/mailings";

const tableColumns = [
  {
    name: "Тип",
    selector: (row) => TMailingMaps.textType[row.type],
    sortable: false,
    center: false,
  },
  {
    name: "Дата старта",
    selector: (row) => new Date(row.start_at + "Z").toLocaleString(),
    sortable: false,
    center: true,
  },
  {
    name: "Дата создания",
    selector: (row) => new Date(row.created_at + "Z").toLocaleString(),
    sortable: false,
    center: true,
  },
  {
    name: "Статус",
    selector: (row) => (
      <span className={TMailingMaps.textStyle[row.state]}>
        {TMailingMaps.textContent[row.state]}
      </span>
    ),
    sortable: false,
    center: true,
  },
];

export default tableColumns;
