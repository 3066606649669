import React from "react";
import { Card, CardBody } from "reactstrap";
import HeaderCard from "../../../Common/Component/HeaderCard";
import { Map, Marker } from "pigeon-maps";

const OrderDeliveryInfoCard = ({ order }) => {
  const addressData = order?.delivery_address;
  const location = addressData.point.coordinates.reverse();

  return (
    <Card>
      <HeaderCard
        title={`Информация по доставке`}
        span1={addressData.text}
        span2={
          <>
            {addressData.flat ? (
              <>
                {`Квартира: ${addressData.flat}`}
                <br />
              </>
            ) : null}
            {addressData.floor ? (
              <>
                {`Этаж: ${addressData.floor}`}
                <br />
              </>
            ) : null}
            {addressData.entrance ? (
              <>
                {`Подъезд: ${addressData.entrance}`}
                <br />
              </>
            ) : null}
          </>
        }
      />
      <CardBody>
        <Map height={400} defaultCenter={location} defaultZoom={15}>
          <Marker width={50} anchor={location} />
        </Map>
      </CardBody>
    </Card>
  );
};

export default OrderDeliveryInfoCard;
