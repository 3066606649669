import React, { Fragment, useEffect, useRef, useState } from "react";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import { Controller, useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Media,
  Row,
} from "reactstrap";
import {
  coreCreateCategory,
  coreGetCategories,
} from "../../../../api/core/categories";
import Select from "react-select";
import { useNavigate } from "react-router";
import SweetAlert from "sweetalert2";
import { useUserSelector } from "../../../../store/useSelectors";
import { coreCreateProduct } from "../../../../api/core/products";
import { coreGetOptionGroups } from "../../../../api/core/options";
import { Link } from "react-router-dom";
import { DOCS_URLS } from "../../../../Constant/docs";
import Creatable from "react-select/creatable";
import ButtonLoading from "../../../Common/ButtonLoading/ButtonLoading";

const NewProductPage = () => {
  const [loading, setLoading] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [options, setOptions] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [selectLoader, setSelectLoader] = useState("Загрузка...");
  const [selectLoading, setSelectLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const navigate = useNavigate();
  const { selectedRestaurant } = useUserSelector();

  const selectRef = useRef();

  const handleSelectClick = () => {
    if (!clicked) {
      fetchOptions();
      setClicked(true);
    }
  };

  const fetchOptions = () => {
    setOptions([]);
    coreGetOptionGroups().then((response) => {
      if (response.status === 200) {
        const arr = [];
        response.data.forEach((item) => {
          const obj = {};
          obj.value = item.id;
          obj.label = `${item.title} ${
            item.title_internal ? `[${item.title_internal}]` : ""
          }`;
          arr.push(obj);
        });
        setOptions(arr);
        setSelectLoader("нет опций для выбора");
      }
    });
  };

  const onSubmit = (data) => {
    const payload = {};
    Object.keys(data).forEach((key) => {
      if (!data[key]) {
        return;
      } else if (key === "option_groups") {
        if (data[key]) {
          payload[key] = data[key].map((e) => e.value);
        }
      } else {
        payload[key] = data[key];
      }
    });

    setLoading(true);
    coreCreateProduct(payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          SweetAlert.fire("Новая позиция успешно создана!", "", "success");
          navigate(
            `${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/products`
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchCategories = (inputValue) => {
    coreGetCategories()
      .then((response) => {
        if (response.status === 200) {
          setCategories(response.data);
        }
        if (inputValue) {
          const currentValule = response.data.filter(
            (item) => item.title === inputValue
          );
          selectRef.current.setValue({
            value: currentValule[0].id,
            label: currentValule[0].title,
          });
        }
      })
      .finally(() => {
        setSelectLoading(false);
      });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const onCreateCategory = (inputValue) => {
    setSelectLoading(true);
    coreCreateCategory({ title: inputValue }).then((response) => {
      if (response.status === 200) {
        fetchCategories(inputValue);
      } else {
        setSelectLoading(false);
      }
    });
  };

  const categoryOptions = categories.map((c) => ({
    value: c.id,
    label: c.title,
  }));

  return (
    <Fragment>
      <Breadcrumbs
        parent="Меню"
        title="Добавление товара"
        mainTitle="Добавление товара"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Row>
                    <Col xs={12} xl={6}>
                      <FormGroup>
                        <Label>Название *</Label>
                        <input
                          className={`form-control ${
                            errors.title ? "is-invalid" : ""
                          }`}
                          type="text"
                          name="title"
                          placeholder="Чизбургер"
                          {...register("title", { required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.title && "Необходимо указать название товара"}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12} xl={6}>
                      <FormGroup>
                        <Label>Категория *</Label>
                        <Controller
                          name="category_id"
                          control={control}
                          render={({ field: { onChange, value, ref } }) => (
                            <Creatable
                              ref={selectRef}
                              isDisabled={selectLoading}
                              isClearable
                              options={categoryOptions}
                              placeholder={"Выбрать..."}
                              noOptionsMessage={() =>
                                "Категорий пока нет. Введите название, чтобы добавить или перейдите в раздел «Категории»"
                              }
                              value={categoryOptions.find(
                                (c) => c.value === value
                              )}
                              onChange={(val) => onChange(val.value)}
                              onCreateOption={onCreateCategory}
                              formatCreateLabel={(val) => `Создать: ${val}`}
                            />
                          )}
                          rules={{ required: true }}
                        />
                        <span style={{ color: "red" }}>
                          {errors.category_id && "Необходимо выбрать категорию"}
                        </span>
                      </FormGroup>
                    </Col>

                    <Col xs={12} xl={6}>
                      <FormGroup>
                        <Label>{"Цена *"}</Label>
                        <input
                          step={0.01}
                          type={"number"}
                          name={"price"}
                          className={`form-control digits ${
                            errors.price ? "is-invalid" : ""
                          }`}
                          {...register("price", { required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.price && "Необходимо указать цену товара"}
                        </span>
                        <br />
                      </FormGroup>
                    </Col>

                    <Col xs={12} xl={6}>
                      <FormGroup>
                        <Label>{"Цена по скидке"}</Label>
                        <input
                          step={0.01}
                          type={"number"}
                          name={"sale_price"}
                          className="form-control digits"
                          {...register("sale_price")}
                        />
                        <small className="form-text text-muted">
                          Если хотите добавить скидку на товар, то укажите
                          стоимость по скидке. Если скидки нет — оставьте поле
                          пустым. Если у товара есть скидка, то у него будет
                          выделяющийся бейдж в меню, чтобы клиент обратил
                          внимание
                        </small>
                      </FormGroup>
                    </Col>

                    <Col xs={12}>
                      <FormGroup>
                        <Label>{"Описание"}</Label>
                        <textarea
                          className="form-control"
                          name="description"
                          rows="3"
                          {...register("description")}
                        />
                        <small className="form-text text-muted">
                          В описании можно рассказать про товар или указать
                          состав и граммовки
                        </small>
                      </FormGroup>
                    </Col>
                    <Col xs={4} className={"mt-3 mb-3"}>
                      <Media>
                        <Label className="col-form-label m-r-10">
                          Популярный товар
                        </Label>
                        <Media body className="text-end switch-lg icon-state">
                          <Label className="switch">
                            <input
                              type="checkbox"
                              {...register("is_featured")}
                            />
                            <span className="switch-state" />
                          </Label>
                        </Media>
                      </Media>
                      <small className="form-text text-muted">
                        У популярных товаров отображается бейдж, чтобы клиент
                        обратил внимание на данный товар
                      </small>
                    </Col>
                    <FormGroup className="row">
                      <Label className="col-sm-3 col-form-label">
                        Добавить фото товара *
                      </Label>
                      <Col sm="9">
                        <input
                          className={`form-control ${
                            errors.photo ? "is-invalid" : ""
                          }`}
                          type="file"
                          {...register("photo", { required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.photo && "Необходимо добавить фото товара"}
                        </span>
                      </Col>
                    </FormGroup>
                  </Row>
                  <Row>
                    <Col className={"mt-3 mb-3 w-100"}>
                      <FormGroup>
                        <Label>Набор модификаторов</Label>
                        <Controller
                          name="option_groups"
                          control={control}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              placeholder="Выберите"
                              options={options}
                              className="basic-multi-select col-sm-12"
                              isMulti
                              name="option_groups"
                              value={value}
                              onChange={(selectedOption) =>
                                onChange(selectedOption)
                              }
                              noOptionsMessage={() => selectLoader}
                              onMenuOpen={() => {
                                handleSelectClick();
                              }}
                            />
                          )}
                        />
                        <small className="form-text text-muted">
                          <Link
                            to={DOCS_URLS.MENU_MODIFICATORS}
                            target={"_blank"}
                          >
                            Что такое модификаторы?
                          </Link>
                        </small>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <div className="text-end">
                    <ButtonLoading loading={loading}>Добавить</ButtonLoading>
                  </div>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default NewProductPage;
