import React, { useEffect, useState } from "react";
import Loader from "../../../Layout/Loader";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router";
import { useUserSelector } from "../../../store/useSelectors";
import { coreGetOrders } from "../../../api/core/orders";
import tableColumns from "./table";

const OrderListDataTable = ({ baseFilters }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [filters, setFilters] = useState(baseFilters || {});
  const navigate = useNavigate();
  const { selectedRestaurant } = useUserSelector();

  const fetchOrders = (page) => {
    setLoading(true);

    coreGetOrders(page, filters, perPage).then((response) => {
      setData(response.data.items);
      setTotalRows(response.data.total);
      setLoading(false);
    });
  };

  const handlePageChange = (page) => {
    fetchOrders(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    coreGetOrders(page, filters, newPerPage).then((response) => {
      setData(response.data.items);
      setTotalRows(response.data.total);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchOrders(1);
  }, []);

  return (
    <DataTable
      data={data}
      columns={tableColumns}
      striped={true}
      center={true}
      progressPending={loading}
      progressComponent={<Loader />}
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      onRowClicked={(row) =>
        navigate(
          `${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/orders/${row.id}`
        )
      }
      highlightOnHover
      pointerOnHover
    />
  );
};

export default OrderListDataTable;
