export const getPhotoThumbnail = (photoUrl, resolution) => {
  // Переделываем https://media.cafebotum.com/123.png -> https://media.cafebotum.com/123_300x300.png
  if (photoUrl === null) {
    return null;
  }
  if (resolution === undefined) {
    return photoUrl;
  } else {
    const photoArray = photoUrl.split(".");
    photoArray[photoArray.length - 2] =
      photoArray[photoArray.length - 2] + `_${resolution}`;
    return photoArray.join(".");
  }
};

export const getFileAsBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = (error) => reject(error);
  });
};
