import React, { useEffect, useState } from "react";
import {
  Badge,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import TariffCard from "./TariffCard";
import Loader from "../../../Layout/Loader";
import { coreGetTariffs } from "../../../api/core/tariffs";
import { TARIFF_PERIOD } from "../../../Constant/tariffs";


const TariffPage = () => {
  const [tariffs, setTariffs] = useState(null);
  const [tariffPeriod, setTariffPeriod] = useState(TARIFF_PERIOD.MONTH);

  useEffect(() => {
    fetchTariffs();
  }, []);

  const fetchTariffs = () => {
    coreGetTariffs().then((response) => response && setTariffs(response.data));
  };

  if (tariffs === null) return <Loader />;

  return (
    <>
      <Breadcrumbs mainTitle="Тарифы" title="Тарифы" />
      <Container fluid={true}>
        <Row>
          <Col>
            <Nav tabs className="search-list" id="top-tab" role="tablist">
              <NavItem>
                <NavLink
                  className={
                    tariffPeriod === TARIFF_PERIOD.MONTH ? "active" : ""
                  }
                  onClick={() => {
                    setTariffPeriod(TARIFF_PERIOD.MONTH);
                  }}
                >
                  1 месяц
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    tariffPeriod === TARIFF_PERIOD.YEAR ? "active" : ""
                  }
                  onClick={() => {
                    setTariffPeriod(TARIFF_PERIOD.YEAR);
                  }}
                >
                  12 месяцев
                  <Badge style={{ marginLeft: "10px" }} color={"success"}>
                    14%
                  </Badge>
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>

        <Row>
          <Col>
            <CardBody>
              <Row className="pricing-block">
                {tariffs.map((e, idx) => (
                  <Col key={idx} xs={12} md={6} xl={4}>
                    <TariffCard tariffData={e} tariffPeriod={tariffPeriod} />
                  </Col>
                ))}
              </Row>
            </CardBody>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TariffPage;
