import React, { Fragment, useContext, useEffect } from "react";
import sad from "../../../../assets/images/other-images/sad.png";
import { Link } from "react-router-dom";
import { Container, Button, Media, Col } from "reactstrap";
import { BACK_TO_HOME_PAGE } from "../../../../Constant";
import CustomizerContext from "../../../../_helper/Customizer";
import { H2, P } from "../../../../AbstractElements";

const Error404 = () => {
  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="error-wrapper">
          <Container>
            <Media body className="img-100" src={sad} alt="" />
            <div className="error-heading">
              <H2 attrH2={{ className: "headline font-danger" }}>{"404"}</H2>
            </div>
            <Col md="8 offset-md-2">
              <P attrPara={{ className: "sub-content" }}>
                {"Страницы с указанным адресом не существует"}
              </P>
            </Col>
            <Link to={`${process.env.PUBLIC_URL}/`}>
              <Button color="danger-gradien" size="lg">
                {"На главную"}
              </Button>
            </Link>
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export default Error404;
