import React from "react";
import { Card, Col, Row } from "reactstrap";
import { H4, H6 } from "../../../AbstractElements";
import DashboardChart1 from "./DashboardChart1";
import ConfigDB from "../../../Config/ThemeConfig";
import RestrictedCard from "../../Common/Card/RestrictedCard";
import { useUserSelector } from "../../../store/useSelectors";
import { TTariffOption } from "../../../Constant/tariffs";
import {
  getFakeOrdersStats,
  getFakeUsersCountStats,
} from "../../../Services/fakeStats";

const OrdersCountCard = ({ stats }) => {
  const { selectedRestaurant } = useUserSelector();
  const isRestricted =
    !selectedRestaurant.tariff.options[TTariffOption.ADVANCED_STATS];
  const displayStats = isRestricted ? getFakeOrdersStats() : stats.orders;

  return (
    <RestrictedCard
      optionName={TTariffOption.ADVANCED_STATS}
      className="o-hidden"
    >
      <div className="chart-widget-top">
        <Row className="card-body pb-0 m-0">
          <Col xl="9" lg="8" xs="9" className="p-0">
            <H6 attrH6={{ className: "mb-2" }}>{"Заказы"}</H6>
            <H4>{displayStats.data_month.count}</H4>
            <span>За последние 30 дней</span>
          </Col>
        </Row>
        <div id="chart-widget2">
          <DashboardChart1
            color={ConfigDB.data.color.secondary_color}
            x={displayStats.gross.map((e) => e.date_key + "Z")}
            y={displayStats.gross.map((e) => e.count)}
            yName={"Количество"}
          />
        </div>
      </div>
    </RestrictedCard>
  );
};

export default OrdersCountCard;
