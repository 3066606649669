import React, { useEffect, useState } from "react";
import { H4, Image, LI, P, UL } from "../../../../AbstractElements";
import { getPhotoThumbnail } from "../../../../Services/media";
import { Link } from "react-router-dom";
import { Button, Card, UncontrolledTooltip } from "reactstrap";
import ToolTip from "../../../Common/Tooltip";

const ProductGridCard = ({
  item,
  selectedRestaurantId,
  archiveProduct,
  deleteProduct,
  recoverProduct,
}) => {
  return (
    <Card>
      <div className="product-box">
        <div className="product-img">
          {item.sale_price ? (
            <span className="ribbon ribbon-danger">Sale</span>
          ) : (
            ""
          )}
          {item.is_featured ? (
            <span className="ribbon ribbon-bookmark ribbon-vertical-right ribbon-info">
              <i className="icon-star">{item.status}</i>
            </span>
          ) : (
            ""
          )}

          <Image
            attrImage={{
              className: "img-fluid",
              src: getPhotoThumbnail(item.photo, "512x512"),
              alt: "",
              loading: "lazy",
            }}
          />
          <div className="product-hover">
            <UL
              attrUL={{
                className: "simple-list d-flex flex-row",
              }}
            >
              {!(item.is_deleted || !item.is_active) ? (
                <>
                  <Link
                    to={`${process.env.PUBLIC_URL}/restaurants/${selectedRestaurantId}/products/${item.id}`}
                  >
                    <LI
                      attrLI={{
                        className: "border-0",
                        id: `edit-tooltip-${item.id}`,
                      }}
                    >
                      <Button color="default">
                        <i className="icon-pencil"></i>
                      </Button>
                    </LI>
                    <UncontrolledTooltip
                      placement={"top"}
                      target={`edit-tooltip-${item.id}`}
                    >
                      {"Изменить товар"}
                    </UncontrolledTooltip>
                  </Link>
                  <Link
                    to={""}
                    onClick={(e) => {
                      e.preventDefault();
                      archiveProduct(item);
                    }}
                  >
                    <LI
                      attrLI={{
                        className: "border-0",
                        id: `archive-tooltip-${item.id}`,
                      }}
                    >
                      <Button color="default">
                        <i className="icon-archive"></i>
                      </Button>
                    </LI>
                    <UncontrolledTooltip
                      placement={"top"}
                      target={`archive-tooltip-${item.id}`}
                    >
                      {"Добавить в стоп-лист"}
                    </UncontrolledTooltip>
                  </Link>
                  <Link
                    to={""}
                    onClick={(e) => {
                      e.preventDefault();
                      deleteProduct(item.id);
                    }}
                  >
                    <LI
                      attrLI={{
                        className: "border-0",
                        id: `delete-tooltip-${item.id}`,
                      }}
                    >
                      <Button color="default">
                        <i className="icon-trash"></i>
                      </Button>
                    </LI>
                    <UncontrolledTooltip
                      placement={"top"}
                      target={`delete-tooltip-${item.id}`}
                    >
                      {"Удалить"}
                    </UncontrolledTooltip>
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to={""}
                    onClick={(e) => {
                      e.preventDefault();
                      recoverProduct(item);
                    }}
                  >
                    <Button color="primary">Восстановить</Button>
                  </Link>
                </>
              )}
            </UL>
          </div>
        </div>
        <div className="product-details">
          {/*<div className='rating'>*/}
          {/*    <i className='fa fa-star font-warning'></i>*/}
          {/*    <i className='fa fa-star font-warning'></i>*/}
          {/*    <i className='fa fa-star font-warning'></i>*/}
          {/*    <i className='fa fa-star font-warning'></i>*/}
          {/*    <i className='fa fa-star font-warning'></i>*/}
          {/*</div>*/}
          <Link
            to={`${process.env.PUBLIC_URL}/restaurants/${selectedRestaurantId}/products/${item.id}`}
          >
            <H4>{item.title}</H4>
          </Link>

          <P>{item.description}</P>
          {item.sale_price ? (
            <div className="product-price">
              {item.sale_price} ₽<del>{item.price} ₽</del>
            </div>
          ) : (
            <div className="product-price">{item.price} ₽</div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ProductGridCard;
