import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import tableColumns from "./table";
import { coreGetRestaurantUsers } from "../../../../api/core/users";
import Breadcrumbs from "../../../Common/Breadcrumbs";
import Loader from "../../../../Layout/Loader";
import { useNavigate } from "react-router";
import { useUserSelector } from "../../../../store/useSelectors";
import { coreGetMailings } from "../../../../api/core/mailings";
import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { DOCS_URLS } from "../../../../Constant/docs";

const MailingListPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const navigate = useNavigate();
  const { selectedRestaurant } = useUserSelector();

  const fetchData = (page) => {
    setLoading(true);
    coreGetMailings(page, {}, perPage).then((response) => {
      setData(response?.data.items);
      setTotalRows(response?.data.total);
      setLoading(false);
    });
  };

  const handlePageChange = (page) => {
    fetchData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    coreGetRestaurantUsers(page, {}, newPerPage).then((response) => {
      setData(response?.data.items);
      setTotalRows(response?.data.total);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData(1);
  }, []);

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Рассылки"
        title="Рассылки"
        docsLink={DOCS_URLS.MAILINGS_OVERVIEW}
      />
      <Container fluid={true}>
        <Col xs={12} className={"mb-5"}>
          <Link
            to={`${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/mailings/new`}
          >
            <Card className="add-card h-100">
              <CardBody className={""}>
                <Row className={"text-center"}>
                  <Col xs={12}>
                    <FaPlus size={32} className={"bg-primary b-r-7"} />
                  </Col>
                  <Col xs={12} className={"mt-1"}>
                    Создать рассылку
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Link>
        </Col>
        <DataTable
          data={data}
          columns={tableColumns}
          striped={true}
          center={true}
          progressPending={loading}
          progressComponent={<Loader />}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onRowClicked={(row) =>
            navigate(
              `${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/mailings/${row.id}`
            )
          }
          highlightOnHover
          pointerOnHover
        />
      </Container>
    </Fragment>
  );
};
export default MailingListPage;
