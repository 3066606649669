import React, { Fragment, useContext, useEffect, useState } from "react";
import CustomContext from "../../_helper/Customizer";
import { getSidebarMenuItems } from "../../Route/SidebarMenu";
import SidebarIcon from "./SidebarIcon";
import SidebarLogo from "./SidebarLogo";
import SidebarMenu from "./SidebarMenu";
import { useUISelector, useUserSelector } from "../../store/useSelectors";
import { useUIActions } from "../../store/useActions";

const Sidebar = (props) => {
  const customizer = useContext(CustomContext);
  const { toggleIcon, toggleSidebar } = useContext(CustomContext);
  const [layout] = useState(Object.keys(customizer.layout));
  // eslint-disable-next-line
  const { sidebarMenu } = useUISelector();
  const { setSidebarMenu } = useUIActions();
  const { selectedRestaurant } = useUserSelector();

  const [width, setWidth] = useState(0);

  useEffect(() => {
    setSidebarMenu(getSidebarMenuItems(selectedRestaurant?.id));
  }, [selectedRestaurant]);

  const handleScroll = () => {
    if (window.scrollY > 400) {
      // if (
      //   customizer.settings.sidebar.type.split(' ').pop() ===
      //   'material-type' ||
      //   customizer.settings.sidebar.type.split(' ').pop() ===
      //   'advance-layout'
      // )
      document.querySelector(".sidebar-main").className =
        "sidebar-main hovered";
    } else {
      if (document.getElementById("sidebar-main"))
        document.querySelector(".sidebar-main").className = "sidebar-main";
    }
  };

  useEffect(() => {
    document.querySelector(".left-arrow").classList.add("d-none");
    window.addEventListener("resize", handleResize);
    handleResize();
    const currentUrl = window.location.pathname;
    sidebarMenu.map((items) => {
      items.items.filter((Items) => {
        if (Items.path === currentUrl) setNavActive(Items);
        if (!Items.children) return false;
        Items.children.filter((subItems) => {
          if (subItems.path === currentUrl) setNavActive(subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems);
              return true;
            } else {
              return false;
            }
          });
          return subItems;
        });
        return Items;
      });
      return items;
    });
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [layout]);

  useEffect(() => {
    const outsideSidebarOutsideClick = () => {
      toggleSidebar(true);
    };

    // Если мобилка, то ставим event listener
    if (window.innerWidth <= 991) {
      if (!toggleIcon) {
        document
          .querySelector("div.page-body")
          .addEventListener("click", outsideSidebarOutsideClick);
      } else {
        document
          .querySelector("div.page-body")
          ?.removeEventListener("click", outsideSidebarOutsideClick);
      }
    }

    return () => {
      document
        .querySelector("div.page-body")
        ?.removeEventListener("click", outsideSidebarOutsideClick);
    };
  }, [toggleIcon]);

  const handleResize = () => {
    setWidth(window.innerWidth - 500);
  };

  const activeClass = () => {
    // document.querySelector('.sidebar-link').classList.add('active');
    document.querySelector(".bg-overlay1").classList.add("active");
  };

  const setNavActive = (item) => {
    let tempMenu = [...sidebarMenu];
    tempMenu = tempMenu.map((menuItem) => {
      return {
        ...menuItem,
        items: menuItem.items.map((menuItemItem) => {
          return {
            ...menuItemItem,
            children: menuItemItem.children ? [...menuItemItem.children] : null,
          };
        }),
      };
    });
    tempMenu.map((menuItems) => {
      menuItems.items.filter((Items) => {
        if (Items.title !== item.title) {
          Items.active = false;
          document.querySelector(".bg-overlay1").classList.remove("active");
        } else {
          Items.active = true;
          document.querySelector(".bg-overlay1").classList.add("active");
        }

        return Items;
      });
      return menuItems;
    });

    // item.active = !item.active;
    setSidebarMenu(tempMenu);
  };

  const closeOverlay = () => {
    document.querySelector(".bg-overlay1").classList.remove("active");
    document.querySelector(".sidebar-links").classList.remove("active");
  };

  return (
    <>
      <div
        className="bg-overlay1"
        onClick={() => {
          closeOverlay();
        }}
      ></div>
      <div
        className={`sidebar-wrapper ${toggleIcon ? "close_icon" : ""}`}
        sidebar-layout="stroke-svg"
      >
        <SidebarIcon />
        <SidebarLogo />
        {/* sidebartoogle={sidebartoogle} */}
        <SidebarMenu
          props={props}
          setNavActive={setNavActive}
          activeClass={activeClass}
          width={width}
        />
      </div>
    </>
  );
};

export default Sidebar;
